<nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
    <a class="navbar-brand brand-logo">
      <div class="brand-logo">
        <!-- <i class="fi fi-rr-chart-pie-alt bigIcon"></i>
        <h3>E-Balance</h3> -->
        <img src="../../../assets/images/ebalance-logo.svg">
      </div>
    </a>
    <a class="navbar-brand brand-logo-mini">
      <img src="../../../assets/images/ebalance-logo.svg" height="20px" width="auto">
    </a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-stretch">
    <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize"
      (click)="toggleSidebar()">
      <span class="mdi mdi-menu"></span>
    </button>
    <ul class="navbar-nav navbar-nav-right">
      <p-dropdown [options]="companies" [(ngModel)]="selectedCompany" class="drop" placeholder="Empresas" optionLabel="name"
        appendTo="body" (ngModelChange)="changeCompany($event)"></p-dropdown>
      <li class="nav-item nav-profile dropdown" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="profileDropdown" ngbDropdownToggle>
          <i class="fi fi-rr-id-badge"></i>
          <div class="nav-profile-text">
            <p class="mb-1 text-black">{{userName}}</p>
          </div>
        </a>
        <div class="dropdown-menu navbar-dropdown" ngbDropdownMenu aria-labelledby="profileDropdown">
          <a class="dropdown-item" (click)="openProfile()">
            <!-- <i class="fi fi-rr-user mr-2"></i> -->
            <i class="fi fi-rr-portrait mr-2"></i>
            Meu Perfil
            <!-- meus dados -->
          </a>
          <a class="dropdown-item" (click)="openCompanies()">
            <i class="fi fi-rr-building mr-2"></i>
            Empresas
            <!-- lista de empresas -->
          </a>
          <a class="dropdown-item" (click)="openUsers()">
            <i class="fi fi-rr-users mr-2"></i>
            Usuários
            <!-- usuarios da empresa selecionada -->
          </a>
          <a class="dropdown-item" (click)="openAdminCompanies()" *ngIf="isAdmin">
            <i class="fi fi-rr-users mr-2"></i>
            Empresas (Admin)
            <!-- usuarios da empresa selecionada -->
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logout()">
            <i class="fi fi-rr-exit mr-2"></i>
            Sair
          </a>
        </div>
      </li>
      <li class="nav-item">
        <i *ngIf="!urlProfile" class="fi fi-rr-building mr-4"></i>
        <img *ngIf="urlProfile" [src]="urlProfile" height="40" alt="user" loading="lazy" />
      </li>
    </ul>
    <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
      (click)="toggleOffcanvas()">
      <span class="mdi mdi-menu"></span>
    </button>
  </div>
</nav>
