<div class="grid">
  <div class="col-12 md:col-12 lg:col-12">
    <div class="page-header">
      <h3 class="page-title">
        <i class="fi fi-rr-piggy-bank menu-icon"></i>
        Despesas
      </h3>
    </div>
  </div>
  <div class="col-12 md:col-12 lg:col-12">
    <div class="card">
      <div class="card-body">

        <div class="contentActions grid-margin">
        </div>

        <p-table [value]="expenses" [loading]="loadingExpenses" [showLoader]="false" styleClass="p-datatable-striped"
          responsiveLayout="stack" [responsive]="true">

          <ng-template pTemplate="caption">
            <div class="grid actions">
              <div class="xl:col-8 lg:col-8 md:col-12 sm:col-12 p-fluid">
                <input pInputText [(ngModel)]="searchFilter" type="text" placeholder="Pesquisa"
                  (ngModelChange)="handleSearch($event)" />
              </div>
              <div class="xl:col-3 lg:col-3 md:col-12 sm:col-12 p-fluid">
                <p-dropdown [options]="optionSearch" [(ngModel)]="searchType" placeholder="Filtro de Busca"
                  optionLabel="label" appendTo="body"></p-dropdown>
              </div>
              <div class="xl:col-1 lg:col-1 md:col-12 sm:col-12 p-fluid">
                <button pButton pRipple type="button" icon="pi pi-search" (click)="search(true)"
                  class="btn-action"></button>
              </div>
              <div class="field-checkbox">
                <p-checkbox [(ngModel)]="onlyCancelled" [binary]="true" inputId="binary"
                  (onChange)="search(true)"></p-checkbox>
                <label class="labelCheckbox" for="binary">Exibir apenas cancelados</label>
              </div>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th>Descrição</th>
              <th>Fornecedor</th>
              <th>Data de entrada</th>
              <th>Data de vencimento</th>
              <th>Valor</th>
              <th>Pago</th>
              <th>À pagar</th>
              <th>Data de pagamento</th>
              <th></th>
            </tr>
          </ng-template>

          <ng-template *ngIf="!isMobile()" pTemplate="body" let-expense>
            <tr>
              <td>{{expense.description}}</td>
              <td>{{expense.provider ? expense.provider?.name : "Não informado" }}</td>
              <td>{{expense.entryDate}}</td>
              <td>{{expense.finishDate}}</td>
              <td>{{expense.value | currency : 'BRL' : true}}</td>
              <td>{{expense.cashOut | currency : 'BRL' : true}}</td>
              <td>{{expense.toReceive | currency : 'BRL' : true}}</td>
              <td>{{expense.paymentDate ? expense.paymentDate : "Não Pago"}}</td>
              <td>
                <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [baseZIndex]="9999"
                  [autoZIndex]="true"></p-menu>
                <button type="button" pButton icon="pi pi-ellipsis-h" class="p-button-text" appendTo="body"
                  (click)="menu.toggle($event); getMenuItems(expense)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template *ngIf="!isMobile()" pTemplate="loadingbody">
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
          </ng-template>

          <ng-template *ngIf="isMobile()" pTemplate="body" let-expense>
            <tr>
              <td>
                <p><b>Descrição:</b>
                <p>{{expense.description}}</p>
              </td>
              <td>
                <p><b>Fornecedor:</b>
                <p> {{expense.provider ? expense.provider?.name : "Não informado" }}</p>
              </td>
              <td>
                <p><b>Data de entrada:</b>
                <p>{{expense.entryDate}}</p>
              </td>
              <td>
                <p><b>Data de vencimento:</b>
                <p>{{expense.finishDate}}</p>
              </td>
              <td>
                <p><b>Valor:</b>
                <p>{{expense.value | currency : 'BRL' : true}}</p>
              </td>
              <td>
                <p><b>Pago:</b>
                <p>{{expense.cashOut | currency : 'BRL' : true}}</p>
              </td>
              <td>
                <p><b>À pagar:</b>
                <p>{{expense.toReceive | currency : 'BRL' : true}}</p>
              </td>
              <td>
                <p><b>Data de pagamento:</b>
                <p> {{expense.paymentDate ? expense.paymentDate : "Não Pago"}}</p>
              </td>
              <td>
                <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [baseZIndex]="9999"
                  [autoZIndex]="true"></p-menu>
                <button type="button" pButton icon="pi pi-ellipsis-h" class="p-button-text" appendTo="body"
                  (click)="menu.toggle($event); getMenuItems(expense)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template *ngIf="isMobile()" pTemplate="loadingbody">

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

          </ng-template>

        </p-table>
        <br>
        <div class="container-pagination" [hidden]="loadingExpenses || noMoreItems">
          <button pButton pRipple icon="fi fi-sr-rotate-right" type="button" label="Carregar mais" (click)="search(false)"
            class="button-outlined p-button-primary"></button>
        </div>

      </div>
    </div>
  </div>
</div>

<p-dialog header="Criar despesa" [(visible)]="appComponent.displayExpense" [modal]="true" [style]="{width: '80vw'}"
  [draggable]="false" appendTo="body" [baseZIndex]="9999" [autoZIndex]="true" [resizable]="false">
  <form class="content-form" [formGroup]="expenseForm">
    <div class="grid">

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-autoComplete formControlName="provider" [showEmptyMessage]="true" [suggestions]="providers"
          placeholder="Digite o nome de um fornecedor (Opcional)" (completeMethod)="filterProvider($event)"
          (onSelect)="changeProvider($event)" field="name" [minLength]="1"></p-autoComplete>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputText formControlName="description" type="text" placeholder="Descrição" />
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask placeholder="Data de entrada" type="text" formControlName="entryDate"
          mask="99/99/9999"></p-inputMask>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask placeholder="Data de vencimento" type="text" formControlName="finishDate"
          mask="99/99/9999"></p-inputMask>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <label>Valor</label>
        <p-inputNumber formControlName="value" mode="currency" currency="BRL" locale="pt-BR">
        </p-inputNumber>
      </div>

<!--      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin contentCheckbox">-->
<!--        <p-checkbox formControlName="isPaid" [binary]="true" inputId="binary"></p-checkbox>-->
<!--        <label>Marcar como pago</label>-->
<!--      </div>-->

<!--      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin" *ngIf="expenseForm.get('isPaid')?.value == true">-->
<!--        <p-inputMask placeholder="Data de Pagamento" type="text" formControlName="paymentDate" mask="99/99/9999">-->
<!--        </p-inputMask>-->
<!--      </div>-->

      <div class="col-12 md:col-12 lg:col-12 p-fluid">
        <button class="button" type="button" (click)="create()">Salvar</button>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog header="Editar despesa" [(visible)]="appComponent.displayEditExpense" [modal]="true" [style]="{width: '80vw'}"
  [draggable]="false" appendTo="body" [baseZIndex]="9999" [autoZIndex]="true" [resizable]="false">
  <form class="content-form" [formGroup]="expenseForm">
    <div class="grid">

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-autoComplete formControlName="provider" [showEmptyMessage]="true" [suggestions]="providers"
          placeholder="Digite o nome de um fornecedor (Opcional)" (completeMethod)="filterProvider($event)"
          (onSelect)="changeProvider($event)" field="name" [minLength]="1"></p-autoComplete>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputText formControlName="description" type="text" placeholder="Descrição" />
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask placeholder="Data de entrada" type="text" formControlName="entryDate"
          mask="99/99/9999"></p-inputMask>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask placeholder="Data de vencimento" type="text" formControlName="finishDate"
          mask="99/99/9999"></p-inputMask>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <label>Valor</label>
        <p-inputNumber formControlName="value" mode="currency" currency="BRL" locale="pt-BR">
        </p-inputNumber>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid">
        <button class="button" type="button" (click)="edit(expenseForm)">Salvar</button>
      </div>
    </div>
  </form>
</p-dialog>

<p-confirmDialog header="Confirme a exclusão" appendTo="body" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog header="Incluir pagamento" [(visible)]="displayPayment" [modal]="true" appendTo="body" [baseZIndex]="9999"
          [autoZIndex]="true" [style]="{width: '80vw'}" [draggable]="false" [resizable]="false">
  <form class="content-form" [formGroup]="paymentForm">
    <div class="grid">
      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask placeholder="Data de pagamento" type="text" formControlName="receiptDate" mask="99/99/9999">
        </p-inputMask>
      </div>
      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <label for="currency-us"><b>Valor</b></label>
        <p-inputNumber formControlName="value" mode="decimal" currency="BRL" locale="pt-BR" [minFractionDigits]="2"
                       [maxFractionDigits]="2">
        </p-inputNumber>
      </div>
      <h5>Valor pendente: {{ (this.expense ? this.expense.toReceive : 0.0)  | currency: 'BRL': true }}</h5>
      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <button class="button" type="button" (click)="checkPay()">Salvar</button>
      </div>
    </div>
  </form>
</p-dialog>


<!--<p-dialog header="Marcar como Pago" [(visible)]="displayPayment" [modal]="true" appendTo="body" [baseZIndex]="9999"-->
<!--  [autoZIndex]="true" [style]="{width: '80vw'}" [draggable]="false" [resizable]="false">-->
<!--  <form class="content-form" [formGroup]="paymentForm">-->
<!--    <div class="grid">-->
<!--      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">-->
<!--        <p-inputMask placeholder="Data de Pagamento" type="text" formControlName="paymentDate" mask="99/99/9999">-->
<!--        </p-inputMask>-->
<!--      </div>-->
<!--      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">-->
<!--        <button class="button" type="button" (click)="checkPay()">Salvar</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </form>-->
<!--</p-dialog>-->
