import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractPlanPipe'
})
export class ContractPlanPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case "STANDARD":
        return "Padrão"
        break;
      case "ADVANCED":
        return "Avançado"
        break;
      case "PROFESSIONAL":
        return "Profissional"
        break;
      default:
        break;
    }
    return value;
  }

}
