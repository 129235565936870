<div class="grid" [formGroup]="address">
  <div class="col-12 md:col-12 lg:col-12 p-fluid input">
    <p-inputMask type="phone" formControlName="postalCode" placeholder="CEP (Opcional)"
                 (onComplete)="handleZipCode()" mask="99.999-999" [unmask]="true">
    </p-inputMask>
  </div>
  <div class="col-12 md:col-12 lg:col-12 p-fluid input">
    <input pInputText formControlName="street" placeholder="Rua" [hidden]="!validPostalCode"/>
  </div>
  <div class="col-12 md:col-12 lg:col-12 p-fluid input">
    <input pInputText formControlName="number" placeholder="Número" [hidden]="!validPostalCode"/>
  </div>
  <div class="col-12 md:col-12 lg:col-12 p-fluid input">
    <input pInputText formControlName="neighborhood" placeholder="Bairro" [hidden]="!validPostalCode"/>
  </div>
  <div class="col-12 md:col-12 lg:col-12 p-fluid input">
    <input pInputText formControlName="city" placeholder="Cidade" [hidden]="!validPostalCode"/>
  </div>
  <div class="col-12 md:col-12 lg:col-12 p-fluid input">
    <input pInputText formControlName="state" placeholder="Estado" [hidden]="!validPostalCode"/>
  </div>
  <div class="col-12 md:col-12 lg:col-12 p-fluid input">
    <input pInputText formControlName="complement" placeholder="Complemento" [hidden]="!validPostalCode"/>
  </div>
</div>
