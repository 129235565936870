import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyBrlDirective } from './currency-brl.directive';


@NgModule({
  declarations: [CurrencyBrlDirective],
  imports: [
      CommonModule
  ],
  exports: []
})
export class DirectivesModule { }
