import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, ActivatedRoute } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { LocalStorageService } from 'src/app/context/local-storage.service';
import { SalesComponent } from './../../components/sales/sales.component';
import { AppComponent } from './../../app.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { CompaniesService } from './../../services/companies/companies.service';
import { AlertManagerService } from './../../context/alert-manager.service';
import { SpinnerService } from './../spinner/spinner.service';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  title = 'E-balance';

  showSidebar: boolean = true;
  showNavbar: boolean = true;
  showFooter: boolean = true;
  selectedCompany: any = null;
  showPlusButton: boolean = true;
  displaysupport: boolean = false;

  optionType: SelectItem[] = [
    { label: "Solicitação de funcionalidade", value: "FEATURE" },
    { label: "Preciso de ajuda", value: "SUPPORT" },
  ];

  selectedType: SelectItem = null;
  contentMessage: string = null;


  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private userInfoService: LocalStorageService,
    public dialogService: DialogService,
    private fb: FormBuilder,
    private companiesService: CompaniesService,
    private alertManagerService: AlertManagerService,
    private spinnerService: SpinnerService,
    public appComponent: AppComponent) { }

  ngOnInit() {
    this.selectedCompany = this.userInfoService.getCompany();
    // Scroll to top after route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  openDynamicModal() {
    if (this.router.url.includes("sales")) {
      this.appComponent.displaySale = true;
      return;
    }
    if (this.router.url.includes("expenses")) {
      this.appComponent.displayExpense = true;
      return;
    }
    if (this.router.url.includes("services")) {
      this.appComponent.displayService = true;
      return;
    }
    if (this.router.url.includes("customers")) {
      this.appComponent.displayCustomer = true;
      return;
    }
    if (this.router.url.includes("receivers")) {
      this.appComponent.displayReceiver = true;
      return;
    }
    if (this.router.url.includes("providers")) {
      this.appComponent.displayProvider = true;
      return;
    }
  }

  openSupport() {
    this.displaysupport = true;
  }

  handleType(event: SelectItem) {
    this.selectedType = event;
  }

  async sendSupport() {
    const content = this.contentMessage;
    const type = this.selectedType?.value;
    this.displaysupport = false;
    try {
      this.spinnerService.show();
      await this.companiesService.support(content, type);
      this.spinnerService.hide();
      this.alertManagerService.showSuccess("E-mail enviado com sucesso");
    } catch (error) {
      this.spinnerService.hide();
      this.alertManagerService.showError("Houve um erro! Tente novamente mais tarde.");
    } finally {
      this.selectedType = null;
      this.contentMessage = null;
      this.spinnerService.hide();
    }
  }
}
