import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/context/local-storage.service';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReceiverService {
  api = environment.BASE_URL;

  constructor(
    private http: HttpClient,
    public localStorageService: LocalStorageService
  ) {
  }

  async createReceiver(request: any): Promise<any> {
    return firstValueFrom(this.http.post(`${this.api}/receivers`, request, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async deleteReceiver(receiverId: number): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.delete(`${this.api}/companies/${companyId}/receivers/${receiverId}`, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async editReceiver(receiverId: number, request: any): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.put(`${this.api}/companies/${companyId}/receivers/${receiverId}`, request, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async getReceivers(isActive: boolean): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/receivers`, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
      params: {
        isActive: isActive
      }
    }))
  }

  async searchReceivers(isActive: boolean, searchData: any, searchType: string, offset: number, limit: number = null): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/receivers`, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
      params: {
        searchData: searchData,
        searchType: searchType,
        isActive: isActive,
        offset: offset,
        limit: limit,
      }
    }))
  }

  async updateActiveStatus(providerId: number, isActive: boolean): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.put(`${this.api}/companies/${companyId}/receivers/${providerId}/active-status`, {}, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
      params: {
        isActive: !isActive
      }
    }))
  }
}
