import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {LocalStorageService} from 'src/app/context/local-storage.service';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MovementService {

  api = environment.BASE_URL;

  constructor(private http: HttpClient, public userInfoService: LocalStorageService) {
  }

  getFinancial(filter: string): Promise<any> {
    const companyId: string = this.userInfoService.getCompanyId();
    return firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/financial/v2`, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
      params: {
        filter: filter,
      }
    }))
  }
}
