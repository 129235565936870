<div class="grid">
  <div class="col-12 md:col-12 lg:col-12">
    <div class="page-header">
      <h3 class="page-title">
        <i class="fi fi-rr-building mr-2"></i>
        Empresas
      </h3>
    </div>
  </div>

  <div class="col-12 md:col-12 lg:col-12">
    <div class="card">
      <div class="card-body">

        <p-table [value]="companies" [loading]="loadingCompanies" [showLoader]="false"
                 styleClass="p-datatable-striped" responsiveLayout="stack" [responsive]="true">

          <ng-template pTemplate="header">
            <tr>
              <th>Nome</th>
              <th>Razão social</th>
              <th>CNPJ</th>
              <th>Telefone</th>
            </tr>
          </ng-template>

          <ng-template *ngIf="!isMobile()" pTemplate="body" let-company>
            <tr>
              <td>{{company.name}}</td>
              <td>{{company.data.companyName}}</td>
              <td>{{company.data.cnpj ? company.data.cnpj : "Não informado"}}</td>
              <td>{{company.data.phone ? company.data.phone : "Não informado"}}</td>
            </tr>
          </ng-template>
          <ng-template *ngIf="!isMobile()" pTemplate="loadingbody">
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
          </ng-template>

          <ng-template *ngIf="isMobile()" pTemplate="body" let-company>
            <tr>
              <td>
                <p><b>Nome:</b></p>
                <p>{{company.name}}</p>
              </td>
              <td>
                <p><b>Razão social</b>
                <p>{{company.data.companyName}}</p>
              </td>
              <td>
                <p><b>CNPJ</b>
                <p> {{company.data.cnpj ? company.data.cnpj : "Não informado"}}</p>
              </td>
              <td>
                <p><b>Telefone:</b>
                <p>{{company.data.phone ? company.data.phone : "Não informado"}}</p>
              </td>
            </tr>
          </ng-template>
          <ng-template *ngIf="isMobile()" pTemplate="loadingbody">

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

          </ng-template>

        </p-table>

      </div>
    </div>
  </div>
</div>

<div class="float-action">
  <button pButton pRipple type="button" icon="pi pi-plus" class="buttonRounded btn-lg"
    (click)="openCreateCompany()"></button>
</div>
