import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/context/local-storage.service';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SaleService {
  api = environment.BASE_URL;

  constructor(
    private http: HttpClient,
    public localStorageService: LocalStorageService
  ) {
  }

  async getSales(isCancelled: boolean): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return await firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/sales`, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
      params: {
        isCancelled: isCancelled
      }
    }))
  }

  async getById(saleId: number): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return await firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/sales/${saleId}`, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async createSale(request: any): Promise<any> {
    return await firstValueFrom(this.http.post(`${this.api}/sales`, request, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async updateSale(saleId: number, request: any): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return await firstValueFrom(this.http.put(`${this.api}/companies/${companyId}/sales/${saleId}`, request, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async cancelSale(saleId: number): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return await firstValueFrom(this.http.put(`${this.api}/companies/${companyId}/sales/${saleId}/cancel`, {}, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async addCashIn(request: any): Promise<any> {
    return await firstValueFrom(this.http.post(`${this.api}/cash-in`, request, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async removeSale(saleId: number): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return await firstValueFrom(this.http.delete(`${this.api}/companies/${companyId}/sales/${saleId}`, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async filter(isCancelled: boolean, searchData: string, searchType: string, offset: number): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return await firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/sales`, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
      params: {
        searchData: searchData,
        searchType: searchType,
        isCancelled: isCancelled,
        offset: offset,
      }
    }))
  }
}
