import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expenseStatusPipe'
})
export class ExpenseStatusPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): any {
    switch (value) {
      case "PAID":
        "Pago"
        break;
      case "UNPAID":
        "Não Pago"
        break;
      case "CANCELLED":
        "Cancelado"
        break;
      default:
        break;
    }
  }

}
