import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ExpenseService} from '../../services/expenses/expenses.service';
import {LocalStorageService} from "../../context/local-storage.service";
import {ProviderService} from "../../services/providers/providers.service";
import {ConfirmationService, MenuItem, SelectItem} from 'primeng/api';
import {AppComponent} from '../../app.component';
import {SpinnerService} from 'src/app/navigation/spinner/spinner.service';
import {DeviceDetectorService} from "ngx-device-detector";
import {CompaniesService} from "../../services/companies/companies.service";
import {AlertManagerService} from "../../context/alert-manager.service";
import {SuccessResponse} from "../../models/response/success-response";
import {ErrorResponse, isErrorResponse} from "../../models/response/error-response";

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit {

  onlyCancelled: boolean = false;

  loadingExpenses: boolean = false;
  expenses: any[] = [];
  expenseForm: FormGroup;
  providers: any[] = [];
  selectedRProvider: any = null;
  menuItems: MenuItem[] = [];
  searchFilter: string = "";
  optionSearch: SelectItem[] = [];
  searchType: SelectItem = null;
  displayPayment: boolean = false;
  paymentForm: FormGroup;
  expense: any = null;
  providerName: string = null;
  noMoreItems: boolean = false;

  constructor(
    private companiesService: CompaniesService,
    private expenseService: ExpenseService,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private providerService: ProviderService,
    private confirmationService: ConfirmationService,
    public appComponent: AppComponent,
    private spinnerService: SpinnerService,
    private deviceService: DeviceDetectorService,
    private alertManagerService: AlertManagerService
  ) {
  }

  async ngOnInit() {
    this.showAddButton();
    this.setForms();
    this.setSearchOptions();
    await this.search(true);
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  showAddButton() {
    this.appComponent.showPlusButton = true;
  }

  setForms() {
    this.expenseForm = this.formBuilder.group({
      id: this.formBuilder.control(null),
      provider: this.formBuilder.control(null),
      description: this.formBuilder.control('', [Validators.required]),
      value: this.formBuilder.control(null, [Validators.required]),
      entryDate: this.formBuilder.control(null, [Validators.required]),
      finishDate: this.formBuilder.control(null, [Validators.required]),
      // isPaid: this.formBuilder.control(false, [Validators.required]),
      // paymentDate: this.formBuilder.control(null),
    });

    this.paymentForm = this.formBuilder.group({
      receiptDate: this.formBuilder.control(null),
      value: this.formBuilder.control(0),
    })
  }

  setSearchOptions() {
    this.optionSearch = [
      {label: 'Sem Filtro', value: 'NONE'},
      {label: 'Fornecedor', value: 'PROVIDER'},
      {label: 'Descrição', value: 'DESCRIPTION'},
      // {label: 'Data de entrada', value: 'ENTRY_DATE'},
      // {label: 'Data vencimento', value: 'DUE_DATE'},
      // {label: 'Data Data de pagamento', value: 'PAY_DATE'},
      // {label: 'Valor', value: 'TOTAL'},
      // {label: 'Pago', value: 'PAY'},
    ];
    this.searchType = this.optionSearch[0];
  }

  resetForm() {
    this.expenseForm.reset();
    this.selectedRProvider = null;
  }

  async search(refresh: boolean) {

    if (this.searchType == null || !this.searchType) {
      this.alertManagerService.showAlert('Selecione o tipo de busca');
      return;
    }

    if (refresh) {
      this.expenses = [];
      this.noMoreItems = false;
    }

    try {

      this.loadingExpenses = true;

      let response = await this.expenseService.searchExpenses(this.onlyCancelled, this.searchFilter, this.searchType.value, this.expenses.length);

      if (response.length == 0) {
        this.alertManagerService.showAlert('Nada encontrado');
        this.noMoreItems = true;
      }

      if (this.expenses.length == 0) {
        this.expenses = response;
      } else {
        this.expenses = this.expenses.concat(response);
      }
    } catch (e: any) {
      this.alertManagerService.showError(e.error?.message);
    } finally {
      this.loadingExpenses = false;
    }
  }

  async create() {
    const form = this.expenseForm.value;

    let splitEntry = form.entryDate.split('/');
    let splitFinish = form.finishDate.split('/');

    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    const seconds = new Date().getSeconds();
    const milliseconds = new Date().getMilliseconds();

    let entryDate = new Date(splitEntry[2], splitEntry[1] - 1, splitEntry[0], hours, minutes, seconds, milliseconds);
    let finishDate = new Date(splitFinish[2], splitFinish[1] - 1, splitFinish[0], hours, minutes, seconds, milliseconds);

    let millisecondsPaymentDate = null;
    if (form.isPaid == true) {
      let splitPayment = form.paymentDate?.split('/');
      const hours = new Date().getHours();
      const minutes = new Date().getMinutes();
      const seconds = new Date().getSeconds();
      const milliseconds = new Date().getMilliseconds();
      millisecondsPaymentDate = new Date(splitPayment[2], splitPayment[1] - 1, splitPayment[0], hours, minutes, seconds, milliseconds);
    }

    const request = {
      companyId: this.localStorageService.getCompany().id,
      providerId: this.selectedRProvider ? this.selectedRProvider.id : null,
      description: form.description,
      value: form.value,
      entryDate: entryDate.getTime(),
      finishDate: finishDate.getTime(),
      // isPaid: form.isPaid,
      // paymentDate: (form.isPaid == true) ? millisecondsPaymentDate.getTime() : null,
    }

    try {
      this.appComponent.displayExpense = false;
      this.spinnerService.show();
      await this.expenseService.createExpense(request);
      this.spinnerService.hide();
      this.alertManagerService.showSuccess('Dados atualizados com sucesso');
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertManagerService.showError(e.error?.message);
    } finally {
      this.resetForm();
      await this.search(true);
    }
  }

  changeProvider(event: any) {
    this.selectedRProvider = event;
    console.log(this.selectedRProvider)
  }

  async filterProvider(event: any) {
    this.selectedRProvider = null;
    if (event.query.length == 0) {
      return;
    }
    this.providers = await this.providerService.searchProvider(true, event.query, "NAME", 0);
    console.log(this.providers)
  }

  async cancelExpense(expense: any) {
    try {
      this.spinnerService.show();
      await this.expenseService.cancelExpense(expense.id)
      this.spinnerService.hide();
      this.alertManagerService.showSuccess('Informação atualizada com sucesso.');
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertManagerService.showError(e.error?.message);
    } finally {
      await this.search(true);
    }
  }

  async removeExpense(expense: any) {
    try {
      this.spinnerService.show();
      await this.expenseService.deleteExpense(expense.id)
      this.spinnerService.hide();
      this.alertManagerService.showSuccess('Informação atualizada com sucesso.');
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertManagerService.showError(e.error?.message);
    } finally {
      await this.search(true);
    }
  }

  async edit(form: FormGroup) {
    const expenseId: number = form.value.id;
    let partEntry = form.value.entryDate.split('/');
    let partFinish = form.value.finishDate.split('/');
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    const seconds = new Date().getSeconds();
    const miliSeconds = new Date().getMilliseconds();
    let entryDate = new Date(partEntry[2], partEntry[1] - 1, partEntry[0], hours, minutes, seconds, miliSeconds);
    let finishDate = new Date(partFinish[2], partFinish[1] - 1, partFinish[0], hours, minutes, seconds, miliSeconds);
    const request = {
      providerId: this.selectedRProvider ? this.selectedRProvider.id : null,
      description: form.value.description,
      value: form.value.value,
      entryDate: entryDate.getTime(),
      finishDate: finishDate.getTime(),
    };
    console.log(request);

    try {
      this.appComponent.displayEditExpense = false;
      this.spinnerService.show();
      await this.expenseService.editExpense(expenseId, request);
      this.spinnerService.hide();
      this.alertManagerService.showSuccess('Dados atualizados com sucesso');
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertManagerService.showError(e.error?.message);
    } finally {
      this.resetForm();
      await this.search(true);
    }
  }

  openEdit(row: any) {
    this.alertManagerService.showInfo('Em desenvolvimento');
    // this.selectedRProvider = row.provider;
    // this.providerName = row.provider.name;

    // this.expenseForm.patchValue({
    //   id: row.id,
    //   provider: row.provider ? row.provider.name : null,
    //   description: row.description,
    //   value: row.value,
    //   entryDate: row.entryDate,
    //   finishDate: row.finishDate,
    // });
    // this.appComponent.displayEditExpense = true;
  }

  getMenuItems(expense: any): MenuItem[] {
    this.menuItems = [];
    this.menuItems.push({label: 'Editar', command: (e) => this.openEdit(expense)})
    if (!expense.isPaid) {
      this.menuItems.push({label: 'Incluir pagamento', command: (e) => this.openAddCashOut(expense),});
    }

    if (!expense.isCancelled) {
      this.menuItems.push(
        {
          label: 'Cancelar despesa', command: (e) => this.confirmCancel(expense),
        }
      );
    }

    if (this.localStorageService.isAdminUser()) {
      this.menuItems.push(
        {
          label: 'Excluir despesa', command: (e) => this.confirmDelete(expense),
        }
      );
    }

    return this.menuItems;
  }

  async confirmDelete(expense: any) {
    this.confirmationService.confirm({
      header: `Excluir despesa`,
      message: `Tem certeza que deseja excluir?`,
      acceptIcon: '',
      rejectIcon: '',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: async () => {
        await this.removeExpense(expense);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  async confirmCancel(expense: any) {
    this.confirmationService.confirm({
      header: `Cancelar despesa`,
      message: `Tem certeza que deseja cancelar?`,
      acceptIcon: '',
      rejectIcon: '',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: async () => {
        await this.cancelExpense(expense);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  async handleSearch(search: any) {
    if (!search) {
      await this.search(true);
    }
  }

  async checkPay() {

    const companyId = this.localStorageService.getCompanyId()
    const receiptDate = this.paymentForm.get(`receiptDate`)?.value;
    const value = this.paymentForm.get(`value`)?.value;
    if (!receiptDate) {
      this.alertManagerService.showAlert(`Informe a data do Pagamento`);
      return;
    }

    let millisecondsPaymentDate = null;
    let splitPaymentDate = receiptDate.split('/');
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    const seconds = new Date().getSeconds();
    const milliseconds = new Date().getMilliseconds();
    millisecondsPaymentDate = new Date(splitPaymentDate[2], splitPaymentDate[1] - 1, splitPaymentDate[0], hours, minutes, seconds, milliseconds).getTime();

    let request: any = {
      receiptDate: millisecondsPaymentDate,
      value: value,
      expenseId: this.expense.id,
      companyId: companyId,
      receiptImage: null,
    }

    try {
      this.displayPayment = false;
      this.spinnerService.show();
      let response: SuccessResponse = await this.expenseService.addCashOut(request);
      this.spinnerService.hide();
      this.alertManagerService.showSuccess(response.message);
    }  catch (e: any) {
      this.spinnerService.hide();
      this.alertManagerService.showError(e.error?.message);
    } finally {
      await this.search(true);
    }
  }

  async openAddCashOut(expense: any) {
    this.paymentForm.reset();
    this.paymentForm.patchValue({
      value: 0,
    })
    this.expense = expense;
    this.displayPayment = true;
  }

  // async importFile(file: File) {
  //   try {
  //     this.spinnerService.show();
  //     await this.companiesService.importExpenses(file);
  //     this.spinnerService.hide();
  //     this.alertManagerService.showSuccess('Dados importados com sucesso.');
  //   } catch (e: any) {
  //     this.spinnerService.hide();
  //     this.alertManagerService.showError(e.error?.message);
  //   } finally {
  //     this.getExpenses();
  //   }
  // }
}


