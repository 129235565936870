import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressComponent } from './address/address.component';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputMaskModule } from "primeng/inputmask";
import { FormMessageComponent } from './form-message/form-message.component';

const Components = [
  AddressComponent,
  FormMessageComponent,
]

@NgModule({
  declarations: [Components],
  exports: [Components],
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    FormsModule,
    InputMaskModule,
  ]
})
export class SharedModule { }
