import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'relationTypePipe'
})
export class RelationTypePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): any {
    switch (value) {
      case "OWNER":
        "Proprietário"
        break;
      case "ADMIN":
        "Administrador"
        break;
      case "EMPLOYEE":
        "Funcionário"
        break;
      default:
        break;
    }
  }
}
