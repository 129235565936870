import {Component, OnInit} from '@angular/core';
import {CustomerService} from 'src/app/services/customer/customer.service';
import {LocalStorageService} from 'src/app/context/local-storage.service';
import {UserService} from './../../services/user/user.service';
import {DeviceDetectorService} from "ngx-device-detector";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  loadingCompanies: boolean = false;
  companies: any[] = [];

  constructor(
    private customerService: CustomerService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private messageService: MessageService,
    private deviceService: DeviceDetectorService
  ) {
  }

  async ngOnInit() {
    await this.getCompanies();
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  isAdmin(): boolean {
    return this.localStorageService.isAdminUser();
  }

  openCreateCompany() {
    alert('Em breve..')
  }

  async getCompanies() {
    try {
      this.loadingCompanies = true;
      this.companies = await this.userService.getUserCompanies();
    } catch (e: any) {
      this.messageService.add({severity: 'error', summary: 'Erro!', detail: `${e.error?.message}`, life: 5000});
    } finally {
      this.loadingCompanies = false;
    }
  }
}
