<div class="grid">
    <div class="col-12 md:col-12 lg:col-12">
        <div class="page-header">
            <h3 class="page-title">
              Produtos
            </h3>
        </div>
    </div>
    <div class="col-12 md:col-12 lg:col-12">
        <div class="card">
            <div class="card-body">
                <p-table [value]="products" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Nome</th>
                            <th>Preço</th>
                            <th>Data Entrada</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-product>
                        <tr>
                            <td>{{product.name}}</td>
                            <td>{{product.price}}</td>
                            <td>{{product.date}}</td>
                        </tr>
                    </ng-template>
                </p-table>
                
            </div>
        </div>
    </div>
</div>

<!-- <div class="float-action">
    <button pButton pRipple type="button" icon="pi pi-plus" class="buttonRounded btn-lg"
        (click)="openCreateExpense()"></button>
  </div> -->