export const environment = {
  production: false,
  BASE_URL: 'https://ebalance-api-dev.lobolabs.com.br/api',
  VIACEP: 'https://viacep.com.br/ws',
  firebase: {
    apiKey: "AIzaSyBzXcxVwQSGPpgc9BCAgLx81ujhfLkmV58",
    authDomain: "lobolabs-ebalance-dev.firebaseapp.com",
    projectId: "lobolabs-ebalance-dev",
    storageBucket: "lobolabs-ebalance-dev.appspot.com",
    messagingSenderId: "1065526124933",
    appId: "1:1065526124933:web:1ed74da0fb19db305164ec",
    measurementId: "G-9DEQ3BRC8Q"
  }
};
