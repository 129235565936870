import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'E-balance';
  displaySale: boolean = false;
  displayEditSale: boolean = false;
  displayExpense: boolean = false;
  displayEditExpense: boolean = false;
  displayService: boolean = false;
  displayEditService: boolean = false;
  displayCustomer: boolean = false;
  displayEditCustomer: boolean = false;
  displayReceiver: boolean = false;
  displayEditReceiver: boolean = false;
  displayProvider: boolean = false;
  displayEditProvider: boolean = false;
  showPlusButton: boolean = false;
}
