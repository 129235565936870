import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/context/local-storage.service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  api = environment.BASE_URL;

  constructor(private http: HttpClient, public userInfoService: LocalStorageService) {
  }

  async activateCompany(companyId: number): Promise<any> {
    return lastValueFrom(this.http.put(`${this.api}/companies/${companyId}/change-status`,
      {
        status: "ACTIVE",
        statusMessage: ""
      },
      {
        headers: {
          Authorization: `Bearer ${this.userInfoService.getToken()}`,
        },
      }))
  }

  async finishContract(companyId: number, contractId: number): Promise<any> {
    return lastValueFrom(
      this.http.put(`${this.api}/companies/${companyId}/contracts/${contractId}/finish`, {}, {
        headers: {
          Authorization: `Bearer ${this.userInfoService.getToken()}`,
        },
      }))
  }

  async getAllCompanies(): Promise<any> {
    return lastValueFrom(this.http.get(`${this.api}/companies`, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
    }))
  }

  async getCompanyContracts(companyId: number): Promise<any> {
    return lastValueFrom(
      this.http.get(`${this.api}/companies/${companyId}/contracts`, {
        headers: {
          Authorization: `Bearer ${this.userInfoService.getToken()}`,
        },
      })
    );
  }

  async getPicture(companyId: number): Promise<any> {
    return lastValueFrom(
      this.http.get(`${this.api}/companies/${companyId}/picture`, {
        headers: {
          Authorization: `Bearer ${this.userInfoService.getToken()}`,
        },
      })
    );
  }

  async importData(file: any): Promise<any> {
    const data: FormData = new FormData();
    data.append('File', file, file.name);
    const companyId: string = this.userInfoService.getCompany()?.id;
    return lastValueFrom(this.http.post(`${this.api}/companies/${companyId}/import-data`, data, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
    }))
  }

  async support(content: string, contactType: string): Promise<any> {
    const companyId: string = this.userInfoService.getCompany()?.id;
    return lastValueFrom(this.http.post(`${this.api}/companies/${companyId}/support`, { content }, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
      params: {
        messageType: "EMAIL",
        contactType: contactType
      }
    }))
  }

  // async importSales(file: any): Promise<any> {
  //   const data: FormData = new FormData();
  //   data.append('File', file, file.name);
  //   const companyId: string = this.userInfoService.getCompany()?.id;
  //   return lastValueFrom(this.http.post(`${this.api}/companies/${companyId}/sales/import`, data, {
  //     headers: {
  //       Authorization: `Bearer ${this.userInfoService.getToken()}`,
  //     },
  //   }))
  // }

  // async importExpenses(file: any): Promise<any> {
  //   const data: FormData = new FormData();
  //   data.append('File', file, file.name);
  //   const companyId: string = this.userInfoService.getCompany()?.id;
  //   return lastValueFrom(this.http.post(`${this.api}/companies/${companyId}/expenses/import`, data, {
  //     headers: {
  //       Authorization: `Bearer ${this.userInfoService.getToken()}`,
  //     },
  //   }))
  // }

  // async importCustomers(file: any): Promise<any> {
  //   const data: FormData = new FormData();
  //   data.append('File', file, file.name);
  //   const companyId: string = this.userInfoService.getCompany()?.id;
  //   return lastValueFrom(this.http.post(`${this.api}/companies/${companyId}/customers/import`, data, {
  //     headers: {
  //       Authorization: `Bearer ${this.userInfoService.getToken()}`,
  //     },
  //   }))
  // }

  async markContractAsPaid(companyId: number, contractId: number): Promise<any> {
    return lastValueFrom(
      this.http.put(`${this.api}/companies/${companyId}/contracts/${contractId}/mark-as-paid`, {}, {
        headers: {
          Authorization: `Bearer ${this.userInfoService.getToken()}`,
        },
      }))
  }

  async updateContractTaxes(companyId: number, contractId: number): Promise<any> {
    return lastValueFrom(
      this.http.put(`${this.api}/companies/${companyId}/contracts/${contractId}/update-taxes`, {}, {
        headers: {
          Authorization: `Bearer ${this.userInfoService.getToken()}`,
        },
      }))
  }

  async uploadPicture(file: any): Promise<any> {
    const data: FormData = new FormData();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data; boundary=ebalance');
    // data.append(file.name, file);
    data.append('File', file, file.name);
    const companyId: string = this.userInfoService.getCompany()?.id;
    return lastValueFrom(this.http.post(`${this.api}/companies/${companyId}/picture`, data, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
    }))
  }
}
