import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { Router } from '@angular/router';
import { UserViewModel } from 'src/app/models/user/user-model/user-view-model';
import { MessageService } from 'primeng/api';
import { SpinnerService } from 'src/app/navigation/spinner/spinner.service';
import { LocalStorageService } from "../../context/local-storage.service";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  loginForm: FormGroup
  isLoading: boolean = false;
  displayIOS: boolean = false;
  showWarning: boolean = false;

  constructor(
    private spinnerService: SpinnerService,
    private FormBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private messageService: MessageService,
    private userInfoService: LocalStorageService,
    private deviceService: DeviceDetectorService,
    private localStorageService: LocalStorageService) {
    this.verifyIsLogged();
  }

  ngOnInit() {
    if (this.deviceService.getDeviceInfo().os == "iOS") {
      if (!this.localStorageService.getIOSWarning() ||
        this.localStorageService.getIOSWarning() == null ||
        this.localStorageService.getIOSWarning() == false) {
        this.displayIOS = true;
      }
    }
    this.setFormLogin();
  }

  verifyIsLogged() {
    const isAuthenticated = this.userService.isAuthenticated();
    if (isAuthenticated) {
      this.router.navigateByUrl('app/financial');
    }
  }

  verifyAuthUser() {
    if (this.userService.isUnauthorized()) {
      this.router.navigateByUrl('/login');
    } else {
      this.router.navigateByUrl('app/financial');
    }
  }

  setFormLogin() {
    this.loginForm = this.FormBuilder.group({
      email: this.FormBuilder.control(null, [Validators.required]),
      password: this.FormBuilder.control(null, [Validators.required]),
    })
  }

  async login() {

    const form = this.loginForm.value;

    try {
      this.spinnerService.show();
      const response = await this.userService.login(form);
      if (response) {
        this.spinnerService.hide();
        this.userInfoService.setUser(response);
        await this.router.navigateByUrl('app/financial');
      } else {
        this.spinnerService.hide();
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: "Erro ao realizar login.", life: 5000 });
      }
    } catch (e: any) {
      this.spinnerService.hide();
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: `${e?.error?.message}`, life: 5000 });
    } finally {
      this.setFormLogin();
    }
  }

  async forgotPassword() {
    await this.router.navigateByUrl('send-recovery-password')
  }

  async register() {
    await this.router.navigateByUrl('register');
  }

  verifyWarningMessage() {
    this.localStorageService.setIOSWarning(this.showWarning);
    this.displayIOS = false;
  }
}
