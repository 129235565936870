import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {UtilService} from '../../services/utils/util.service';
import jwt_decode from "jwt-decode";
import {SpinnerService} from "../../navigation/spinner/spinner.service";

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent implements OnInit {

  passwordForm = new FormGroup({
    password: new FormControl('', Validators.required),
    passwordConfirm: new FormControl('', Validators.required),
  });
  isLoading: boolean = false;
  token: string = null;


  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token') ?? null;
  }

  async resetPassword() {
    try {
      this.spinnerService.show();
      const decode: any = jwt_decode(this.token);
      let userId = decode?.id
      await this.utilService.resetPassword(userId, this.passwordForm.value.password, this.token);
      this.spinnerService.hide();
      this.messageService.add({severity: 'success', summary: 'Sucesso!', detail: `Dados atualizados com sucesso`});
      this.router.navigateByUrl('login');
    } catch (error: any) {
      this.spinnerService.hide();
      this.messageService.add({severity: 'error', summary: 'Houve um erro!', detail: `${error.message}`});
    } finally {
      this.passwordForm.reset();
    }
  }
}
