import { NgModule } from '@angular/core';
import { ContractPlanPipe } from './contract-plan-pipe/contract-plan.pipe';
import { ExpenseStatusPipe } from './expense-status-pipe/expense-status.pipe';
import { FinancialTypePipe } from './financial-type-pipe/financial-type.pipe';
import {RelationTypePipe} from "./relation-type-pipe/relation-type.pipe";



@NgModule({
  declarations: [FinancialTypePipe, ExpenseStatusPipe, ContractPlanPipe, RelationTypePipe],
  exports: [FinancialTypePipe, ExpenseStatusPipe, ContractPlanPipe, RelationTypePipe]
})
export class PipesModule { }
