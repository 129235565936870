import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { LocalStorageService } from 'src/app/context/local-storage.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { SpinnerService } from '../../navigation/spinner/spinner.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import jwt_decode from "jwt-decode";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilService } from 'src/app/services/utils/util.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

  user: any = null;
  selectedCompany: any = null;
  loadingContracts: boolean = false;
  contracts: any[] = [];
  menuItems: MenuItem[] = [];
  displayPassword: boolean = false;
  // passwordForm: FormGroup;
  passwordForm = new FormGroup({
    password: new FormControl('', Validators.required),
    passwordConfirm: new FormControl('', Validators.required),
  });

  constructor(
    private userInfoService: LocalStorageService,
    private companiesService: CompaniesService,
    private spinnerServicve: SpinnerService,
    private messageService: MessageService,
    public appComponent: AppComponent,
    private deviceService: DeviceDetectorService,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private spinnerService: SpinnerService,
    private utilService: UtilService,
  ) {}

  async ngOnInit() {
    this.hideAddButton();
    this.getUser();
    this.getCompanySelected();
    await this.getContracts();
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  hideAddButton() {
    this.appComponent.showPlusButton = false;
  }

  async getContracts() {
    try {
      this.loadingContracts = true;
      this.contracts = await this.companiesService.getCompanyContracts(this.selectedCompany.id);
    } catch (e: any) {
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: `${e?.error?.message}`, life: 5000 });
    } finally {
      this.loadingContracts = false;
    }
  }

  getUser() {
    this.user = this.userInfoService.getUser();
  }

  getCompanySelected() {
    this.selectedCompany = this.userInfoService.getCompany();
  }

  async payContract(contract: any) {
    try {
      this.spinnerServicve.show();
      const response = await this.companiesService.markContractAsPaid(contract.companyId, contract.id);
      this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: `${response.message}`, life: 5000 });
    } catch (error: any) {
      this.messageService.add({
        severity: 'error',
        summary: 'Houve um erro!',
        detail: `${error.error.message}`,
        life: 5000
      });
    } finally {
      await this.getContracts();
      this.spinnerServicve.hide();
    }
  }

  async updateTaxes(contract: any) {
    try {
      this.spinnerServicve.show();
      const response = await this.companiesService.updateContractTaxes(contract.companyId, contract.id);
      this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: `${response.message}`, life: 5000 });
    } catch (error: any) {
      this.messageService.add({
        severity: 'error',
        summary: 'Houve um erro!',
        detail: `${error.error.message}`,
        life: 5000
      });
    } finally {
      await this.getContracts();
      this.spinnerServicve.hide();
    }
  }

  async finishContract(contract: any) {
    try {
      this.spinnerServicve.show();
      const response = await this.companiesService.finishContract(contract.companyId, contract.id);
      this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: `${response.message}`, life: 5000 });
    } catch (error: any) {
      this.messageService.add({
        severity: 'error',
        summary: 'Houve um erro!',
        detail: `${error.error.message}`,
        life: 5000
      });
    } finally {
      await this.getContracts();
      this.spinnerServicve.hide();
    }
  }


  getMenuItems(contract: any): MenuItem[] {
    this.menuItems = [];
    const token = this.userInfoService.getToken();
    const decode: any = jwt_decode(token);
    if (decode != null && decode?.type == "SYSTEM_ADMIN") {
      if (contract.needUpdateTaxes) {
        this.menuItems.push({ label: 'Atualizar taxas', command: () => this.updateTaxes(contract) });
      }
      if (!contract.isPaid) {
        this.menuItems.push({ label: 'Marcar como Pago', command: () => this.payContract(contract) });
      }
      if (contract.isActive) {
        this.menuItems.push({ label: 'Finalizar', command: () => this.finishContract(contract) });
      }
    }
    return this.menuItems
  }

  async onUploadProfileImage(file: File) {
    console.log(file);
    try {
      const response = await this.companiesService.uploadPicture(file);
      this.userInfoService.setCompanyPicture(response.url)
    } catch (error) {
      console.log(error)
    }
  }

  openChangePassword() {
    this.displayPassword = true;
  }

  async onSave() {
    try {
      this.displayPassword = false;
      this.spinnerService.show();
      const token = this.localStorageService.getToken();
      const decode: any = jwt_decode(token);
      let userId = decode?.id
      await this.utilService.resetPassword(userId, this.passwordForm.value.password, token);
      this.spinnerService.hide();
      this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: `Senha alterada com sucesso!`, life: 10000 });
    } catch (error: any) {
      this.spinnerService.hide();
      this.messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: `${error.message}` });
    } finally {
      this.displayPassword = false;
      this.passwordForm.reset();
    }
  }

}
