<!--<div class="content">-->
<!--  <div class="box">-->
<!--    <div class="brand-logo">-->
<!--      <img src="../../../assets/images/ebalance.svg" height="100px" width="auto" alt="E-balance">-->
<!--    </div>-->
<!--    <div class="grid">-->
<!--      <form class="content-form" [formGroup]="loginForm" (keyup.enter)="login()">-->
<!--        <div class="col-12 md:col-12 lg:col-12 p-fluid">-->
<!--          <input pInputText formControlName="email" type="email" placeholder="E-mail" autofocus/>-->
<!--        </div>-->
<!--        <div class="col-12 md:col-12 lg:col-12 p-fluid">-->
<!--          <input pInputText formControlName="password" type="password" placeholder="Senha"/>-->
<!--        </div>-->
<!--        <div class="col-12 md:col-12 lg:col-12 p-fluid">-->
<!--          <button class="buttonLogin" type="button" (click)="login()">Login</button>-->
<!--        </div>-->
<!--        <div class="col-12 md:col-12 lg:col-12 p-fluid">-->
<!--          <button class="button-outlined-login" type="button" (click)="register()">Cadastro</button>-->
<!--        </div>-->
<!--        <div class="col-12 md:col-12 lg:col-12 p-fluid">-->
<!--          <a class="link-button" (click)="forgotPassword()">Esqueci minha senha 😰</a>-->
<!--        </div>-->

<!--      </form>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<div class="d-flex align-items-center auth" style="overflow-y: hidden; height: 100vh">
  <div class="row w-100 mx-0">
    <div class="col-lg-4 mx-auto">
      <div class="auth-form-light text-left py-5 px-4 px-sm-5" style="border-radius: 14px 14px 14px 14px;">
        <div class="brand-logo">
          <img src="assets/images/ebalance-logo.svg" alt="logo" height="100px" width="auto">
        </div>
        <form class="content-form" [formGroup]="loginForm" (keyup.enter)="login()">
          <div class="col-12 md:col-12 lg:col-12 p-fluid">
            <input pInputText formControlName="email" type="email" placeholder="E-mail" autofocus/>
          </div>
          <div class="col-12 md:col-12 lg:col-12 p-fluid">
            <input pInputText formControlName="password" type="password" placeholder="Senha"/>
          </div>
          <div class="col-12 md:col-12 lg:col-12 p-fluid">
            <button class="buttonLogin" type="button" (click)="login()">Login</button>
          </div>
          <div class="col-12 md:col-12 lg:col-12 p-fluid">
            <button class="button-outlined-login" type="button" (click)="register()">Cadastro</button>
          </div>
          <div class="col-12 md:col-12 lg:col-12 p-fluid">
            <a class="link-button" (click)="forgotPassword()">Esqueci minha senha 😰</a>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>


<p-dialog header="Instale o App" [(visible)]="displayIOS" [position]="'bottom'" [modal]="true" [style]="{width: '90vw'}"
          [draggable]="false" [resizable]="false">
  <div class="grid">
    <div class="col-12 md:col-12 sm:col-12 lg:col-12 xl:col-12">
      <br>
      <h5>Instale o App na sua tela inicial para acessar facil e rapidamente quando quiser.</h5>
    </div>
    <div class="col-12 md:col-12 sm:col-12 lg:col-12 xl:col-12">
      <p><b>Passo 1.</b> Acesse o sistema pelo navegador "Safari"</p>
    </div>
    <div class="col-12 md:col-12 sm:col-12 lg:col-12 xl:col-12">
      <p><b>Passo 2.</b> Clique no icone <img src="assets/images/share-ios.svg"> e depois "Adicionar à tela de início"
      </p>
    </div>

    <div class="col-12 md:col-12 lg:col-12 xl:col-12">
      <div class="content-msg-ios">
        <p-checkbox [(ngModel)]="showWarning" [binary]="true" inputId="binary"></p-checkbox>
        <small>Não exibir esta mensagem novamente</small>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <br>
    <button pButton pRipple type="button" label="Ok" class="p-button-outlined"
            (click)="verifyWarningMessage()"></button>
  </ng-template>
</p-dialog>
