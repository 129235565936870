import {Component, OnInit} from '@angular/core';
import {MovementService} from './../../services/movements/movement.service';
import {CustomerService} from './../../services/customer/customer.service';
import {LocalStorageService} from '../../context/local-storage.service';
import {FinancialViewModel} from "../../models/financial/financial-view-model";
import {ExportData} from "../../models/financial/movements-view-model";
import {MessageService, SelectItem} from 'primeng/api';
import {UserService} from 'src/app/services/user/user.service';
import {AppComponent} from 'src/app/app.component';
import {utils, writeFileXLSX} from 'xlsx';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  financial: FinancialViewModel = null;

  compareNetValues: any = null;
  comparePendingValues: any = null;
  compareGrossValues: any = null;

  companies: any[] = [];
  selectedCompany: any = null;

  displayCompanies: boolean = false;

  exportData: ExportData[] = [];

  timeOptions: SelectItem[] = [];
  selectedTime: SelectItem = null;

  constructor(
    private movementService: MovementService,
    private customerService: CustomerService,
    private userInfoService: LocalStorageService,
    private userService: UserService,
    public appComponent: AppComponent,
    private messageService: MessageService,) {
  }

  async ngOnInit() {
    console.log("chegou no financeiro");
    this.setOptions();
    this.appComponent.showPlusButton = false;
    if (this.userInfoService.getCompany() !== null) {
      await this.getFinancial();
    } else if (this.userInfoService.getCompany() == null) {
      await this.getCompanies();
      // this.displayCompanies = true;
    }
  }

  setOptions() {
    this.timeOptions = [
      {label: `Dia`, value: "DAY"},
      {label: `Semana`, value: "WEEK"},
      {label: `Mês`, value: "MONTH"},
      {label: `Ano`, value: "YEAR"},
      {label: `Tudo`, value: "ALL"},
    ];
    this.selectedTime = this.timeOptions[1];
  }

  async getCompanies() {
    this.companies = await this.userService.getUserCompanies();
    if (this.companies.length == 1) {
      this.changeCompany(this.companies[0]);
      return;
    }
    this.displayCompanies = true;
  }

  async changeCompany(event: any) {
    console.log(event)
    this.userInfoService.setCompany(event);
    this.userInfoService.changeCompany(event);
    this.selectedCompany = event;
    this.displayCompanies = false;
    await this.getFinancial();
  }

  async getFinancial() {
    try {

      this.financial = await this.movementService.getFinancial(this.selectedTime?.value);
      this.exportData = this.financial.exportData

      let grossBalanceString = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(this.financial.grossBalance)

      let grossBalanceLabel: string;
      if (this.financial.grossBalance > 0) {
        grossBalanceLabel = `Lucro de ${grossBalanceString}`
      } else {
        grossBalanceLabel = `Deficit de ${grossBalanceString}`
      }

      this.compareGrossValues = {
        labels: ['Entrada (Bruto)', 'Saída (Bruto)', grossBalanceLabel],
        datasets: [
          {
            data: [this.financial.grossCashIn, this.financial.grossCashOut, 0],
            backgroundColor: [
              "#0A6375",
              "#e63946",
              "#111828"
            ],
            hoverBackgroundColor: [
              "#0A6375",
              "#e63946",
              "#111828"
            ]
          }
        ]
      };

      let balanceString = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(this.financial.balance)

      let balanceLabel: string;
      if (this.financial.balance > 0) {
        balanceLabel = `Lucro de ${balanceString}`
      } else {
        balanceLabel = `Deficit de ${balanceString}`
      }
      this.compareNetValues = {
        labels: ['Entrada', 'Saída', balanceLabel],
        datasets: [
          {
            data: [this.financial.cashIn, this.financial.cashOut, 0],
            backgroundColor: [
              "#0A6375",
              "#e63946",
              "#111828"
            ],
            hoverBackgroundColor: [
              "#0A6375",
              "#e63946",
              "#111828"
            ]
          }
        ]
      };


      let pendingBalance = this.financial.toReceive - this.financial.toPay;

      let pendingBalanceString = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(pendingBalance)

      let pendingBalanceLabel: string;
      if (pendingBalance > 0) {
        pendingBalanceLabel = `Lucro de ${pendingBalanceString}`
      } else {
        pendingBalanceLabel = `Deficit de ${pendingBalanceString}`
      }
      this.comparePendingValues = {
        labels: ['À receber', 'À Pagar', pendingBalanceLabel],
        datasets: [
          {
            data: [this.financial.toReceive, this.financial.toPay, 0],
            backgroundColor: [
              "#0A6375",
              "#e63946",
              "#111828"
            ],
            hoverBackgroundColor: [
              "#0A6375",
              "#e63946",
              "#111828"
            ]
          }
        ]
      };
    } catch (error: any) {
      this.messageService.add({severity: 'error', summary: 'Erro!', detail: `${error.error?.message}`});
    }
  }

  exportAllMovements() {

    const fullExport = this.exportData.map(item => {
      return {
        'Empresa': item.companyName,
        'CNPJ': item.companyCnpj,
        'Área': item.type,
        'Id': item.id,
        'Nome': item.customerName,
        'Descrição': item.description,
        'Vencimento': item.finishDate,
        'Data da Movimentação': item.paymentDate,
        'Valor': item.value,
        'Juros': item.fee,
        'Multa': item.penalty,
        'Desconto': item.discount,
        'Valor de Entrada': item.receiptValue,
        'Valor de Saída': item.paidValue,
        'Conta Financeira': item.financialAccount,
        'Categoria': item.category
      }
    });
    this.exportExcel(fullExport);
  }


  exportExcel(movements: any) {
    const ws = utils.json_to_sheet(movements);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, "Movimentações.xlsx");
  }


  async handleFilter(event: any) {
    this.selectedTime = event;
    await this.getFinancial()
  }
}
