<div class="row">

  <div class="col-lg-12">
    <div class="page-header">
      <h3 class="page-title">
        <i class="fi fi-rr-chart-pie-alt menu-icon"></i>
        Financeiro
      </h3>
    </div>
  </div>

  <div class="col-lg-12 grid-margin" style="display: flex; flex-direction: row-reverse">
    <p-dropdown [options]="timeOptions"
                [(ngModel)]="selectedTime"
                (onChange)="handleFilter($event.value)"
                dataKey="value"
                placeholder="Filtro"
                optionLabel="label"></p-dropdown>
    <br>
  </div>

  <div class="col-lg-6 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="text-center">Saldo</h4>
        <h1 class="text-center">{{financial?.balance !== null ? (financial.balance | currency) : "--" }}</h1>
      </div>
    </div>
  </div>

  <div class="col-lg-6 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="text-center">Saldo Bruto</h4>
        <h1 class="text-center">{{financial?.grossBalance !== null ? (financial.grossBalance | currency) : "--"}}</h1>
      </div>
    </div>
  </div>

  <div class="col-lg-4 grid-margin">
    <div class="card">
      <div class="card-body">
        <p-chart type="doughnut" [data]="comparePendingValues" [style]="{'width': '40%'}"></p-chart>

      </div>
    </div>
  </div>

  <div class="col-lg-4 grid-margin">
    <div class="card">
      <div class="card-body">
        <p-chart type="doughnut" [data]="compareNetValues" [style]="{'width': '40%'}"></p-chart>
      </div>
    </div>
  </div>

  <div class="col-lg-4 grid-margin">
    <div class="card">
      <div class="card-body">
        <p-chart type="doughnut" [data]="compareGrossValues" [style]="{'width': '40%'}"></p-chart>

      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="page-header">
      <h3 class="page-title">
        <i class="fi fi-rr-chart-line-up"></i>
        Movimentações
      </h3>
    </div>
  </div>

  <div class="col-lg-12 grid-margin">
    <button class="button" (click)="exportAllMovements()">Exportar</button>
  </div>
  <div class="col-lg-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <p-table class="table" [value]="exportData" responsiveLayout="stack">
          <ng-template pTemplate="header">
            <tr>
              <th>Data</th>
              <th>Valor</th>
              <th>Tipo</th>
            </tr>
          </ng-template>
          <ng-template let-data pTemplate="body" >
            <tr>

              <td>{{data.paymentDate}}</td>

              <td class="value"
                  *ngIf="data.category == 'Receita com vendas'">{{data.receiptValue | currency : "BRL" : true}}</td>
              <td class="value" *ngIf="data.category == 'Despesa'">{{data.paidValue | currency : "BRL" : true}}</td>

              <td class="up" *ngIf="data.category == 'Receita com vendas'">Receita com vendas <i class="fi fi-rr-arrow-small-up"></i></td>
              <td class="down" *ngIf="data.category == 'Despesa'">Despesa <i class="fi fi-rr-arrow-small-down"></i></td>

            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

  </div>

</div>

<p-dialog header="Selecione a empresa" [(visible)]="displayCompanies" appendTo="body" [modal]="true"
          [style]="{width: '80vw'}" [draggable]="false" [resizable]="false" [closable]="false">
  <div class="grid">
    <div class="col-12 md:col-12 lg:col-12 p-fluid">
      <p-dropdown [options]="companies" [(ngModel)]="selectedCompany" placeholder="Empresas"
                  optionLabel="data.companyName" appendTo="body" (ngModelChange)="changeCompany($event)"></p-dropdown>
    </div>
  </div>
</p-dialog>
<p-toast></p-toast>
