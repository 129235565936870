import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/context/local-storage.service';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  api = environment.BASE_URL;

  constructor(
    private http: HttpClient,
    public localStorageService: LocalStorageService
  ) {
  }

  async createCustomer(request: any): Promise<any> {
    return firstValueFrom(this.http.post(`${this.api}/customers`, request, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async deleteCustomer(customerId: number): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.delete(`${this.api}/companies/${companyId}/customers/${customerId}`, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async editCustomer(customerId: number, request: any): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.put(`${this.api}/companies/${companyId}/customers/${customerId}`, request, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  // async getCustomers(isActive: boolean, searchData: string, searchType?: string): Promise<any> {
  //   const companyId: string = this.localStorageService.getCompanyId();
  //   return firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/customers`, {
  //     headers: {
  //       Authorization: `Bearer ${this.localStorageService.getToken()}`,
  //     },
  //     params: {
  //       searchData: searchData,
  //       searchType: searchType,
  //       isActive: isActive
  //     }
  //   }))
  // }

  async searchCustomers(isActive: boolean, searchData: string, searchType: string, offset: number, limit: number = null): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/customers`, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
      params: {
        searchData: searchData,
        searchType: searchType,
        isActive: isActive,
        offset: offset,
        limit: limit,
      }
    }))
  }

  async updateActiveStatus(providerId: number, isActive: boolean): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.put(`${this.api}/companies/${companyId}/customers/${providerId}/active-status`, {}, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
      params: {
        isActive: !isActive
      }
    }))
  }
}
