import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'financialTypePipe'
})
export class FinancialTypePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): any {
    switch (value) {
      case "ENTRY":
        "Entrada"
        break;
      case "EXIT":
          "Saída"
          break;
      default:
        break;
    }
  }

}
