<div class="grid">
  <div class="col-12 md:col-12 lg:col-12">
    <div class="page-header">
      <h3 class="page-title">
        <i class="fi fi-rr-store-alt menu-icon"></i>
        Serviços
      </h3>
    </div>
  </div>
  <div class="col-12 md:col-12 lg:col-12">
    <div class="card">
      <div class="card-body">
        <p-table [value]="services" [loading]="loadingServices" [showLoader]="false" styleClass="p-datatable-striped"
          responsiveLayout="stack" [responsive]="true">
          <ng-template pTemplate="caption">
            <div class="grid actions">
              <div class="xl:col-8 lg:col-8 md:col-12 sm:col-12 p-fluid">
                <input pInputText [(ngModel)]="searchFilter" type="text" placeholder="Pesquisa"
                  (ngModelChange)="handleSearch($event)" />
              </div>
              <div class="xl:col-3 lg:col-3 md:col-12 sm:col-12 p-fluid">
                <p-dropdown [options]="optionSearch" [(ngModel)]="searchType" placeholder="Filtro de Busca"
                  optionLabel="label" appendTo="body"></p-dropdown>
              </div>
              <div class="xl:col-1 lg:col-1 md:col-12 sm:col-12 p-fluid">
                <button pButton pRipple type="button" icon="pi pi-search" (click)="search(true)"
                  class="btn-action"></button>
              </div>
              <div class="field-checkbox">
                <p-checkbox [(ngModel)]="onlyInactives" [binary]="true" inputId="binary"
                  (onChange)="search(true)"></p-checkbox>
                <label class="labelCheckbox" for="binary">Exibir apenas inativos</label>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>Nome</th>
              <th>Preço</th>
              <th></th>
            </tr>
          </ng-template>

          <ng-template *ngIf="!isMobile()" pTemplate="body" let-service>
            <tr>
              <td>{{service.name}}</td>
              <td>{{service.price | currency : 'BRL' : true}}</td>
              <td>
                <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [baseZIndex]="9999"
                  [autoZIndex]="true"></p-menu>
                <button type="button" pButton icon="pi pi-ellipsis-h" class="p-button-text" appendTo="body"
                  (click)="menu.toggle($event); getMenuItems(service)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template *ngIf="!isMobile()" pTemplate="loadingbody">
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
          </ng-template>

          <ng-template *ngIf="isMobile()" pTemplate="body" let-service>
            <tr>
              <td>
                <p><b>Nome:</b>
                <p>{{service.name}}</p>
              </td>
              <td>
                <p><b>Preço:</b>
                <p>{{service.price | currency : 'BRL' : true}}</p>
              </td>
              <td>
                <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [baseZIndex]="9999"
                  [autoZIndex]="true"></p-menu>
                <button type="button" pButton icon="pi pi-ellipsis-h" class="p-button-text" appendTo="body"
                  (click)="menu.toggle($event); getMenuItems(service)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template *ngIf="isMobile()" pTemplate="loadingbody">

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

          </ng-template>
        </p-table>
        <br>
        <div class="container-pagination" [hidden]="loadingServices || noMoreItems">
          <button pButton pRipple icon="fi fi-sr-rotate-right" type="button" label="Carregar mais" (click)="search(false)"
            class="button-outlined p-button-primary"></button>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Novo serviço" [(visible)]="appComponent.displayService" [modal]="true" [style]="{width: '80vw'}"
  [draggable]="false" appendTo="body" [baseZIndex]="9999" [autoZIndex]="true" [resizable]="false">
  <form class="content-form" [formGroup]="serviceForm">
    <div class="grid p-fluid">

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputText formControlName="name" type="text" placeholder="Nome do Serviço" />
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputNumber formControlName="price" mode="currency" currency="BRL" locale="pt-BR" placeholder="Preço">
        </p-inputNumber>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <button class="button" type="button" (click)="create()">Salvar</button>
      </div>
    </div>
  </form>
</p-dialog>


<p-dialog header="Editar serviço" [(visible)]="appComponent.displayEditService" [modal]="true" [style]="{width: '80vw'}"
  [draggable]="false" appendTo="body" [baseZIndex]="9999" [autoZIndex]="true" [resizable]="false">
  <form class="content-form" [formGroup]="serviceForm">
    <div class="grid p-fluid">

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputText formControlName="name" type="text" placeholder="Nome do Serviço" />
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputNumber formControlName="price" mode="currency" currency="BRL" locale="pt-BR" placeholder="Preço">
        </p-inputNumber>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <button class="button" type="button" (click)="edit(serviceForm)">Salvar</button>
      </div>
    </div>
  </form>
</p-dialog>

<p-confirmDialog header="Confirme a exclusão" appendTo="body" icon="pi pi-exclamation-triangle"></p-confirmDialog>
