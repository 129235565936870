import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {UtilService} from 'src/app/services/utils/util.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input() address = new FormGroup({
    postalCode: new FormControl(),
    state: new FormControl(null),
    city: new FormControl(null),
    street: new FormControl(null),
    neighborhood: new FormControl(null),
    complement: new FormControl(null),
    number: new FormControl(null),
  });

  validPostalCode: boolean = false;

  constructor(private utilService: UtilService) {
  }

  ngOnInit(): void {
  }

  async handleZipCode() {
    const zipCode = this.address.value.postalCode;
    try {
      const response = await this.utilService.getZipCode(zipCode);
      this.address.patchValue({
        postalCode: response.cep,
        state: response.uf,
        city: response.localidade,
        street: response.logradouro,
        neighborhood: response.bairro,
        complement: response.complemento ?? null,
        number: null,
      });
      this.validPostalCode = true;
    } catch (error) {
      this.validPostalCode = false;
      this.address.reset();
    }
  }
}
