<!-- <div class="spinner-wrapper" *ngIf="spinnerService.showLoading$ | async">
  <div class="donut"></div>
  <span>Aguarde..</span>
</div> -->


<div class="splash-screen" *ngIf="spinnerService.showLoading$ | async">
  <div class="splash-loader-container">
    <img src="../../../assets/images/ebalance-loading.svg">
  </div>
</div>