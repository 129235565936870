import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/context/local-storage.service';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  api = environment.BASE_URL;

  constructor(private http: HttpClient, public userInfoService: LocalStorageService) {
  }

  async createProvider(request: any): Promise<any> {
    return firstValueFrom(this.http.post(`${this.api}/providers`, request, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
    }))
  }

  async deleteProvider(providerId: number): Promise<any> {
    const companyId: string = this.userInfoService.getCompanyId();
    return firstValueFrom(this.http.delete(`${this.api}/companies/${companyId}/providers/${providerId}`, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
    }))
  }

  async editProvider(providerId: number, request: any): Promise<any> {
    const companyId: string = this.userInfoService.getCompanyId();
    return firstValueFrom(this.http.put(`${this.api}/companies/${companyId}/providers/${providerId}`, request, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
    }))
  }

  async getProviders(isActive: boolean): Promise<any> {
    const companyId: string = this.userInfoService.getCompanyId();
    return firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/providers`, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
      params: {
        isActive: isActive
      }
    }))
  }

  async searchProvider(isActive: boolean, search: any, searchType: any, offset: number): Promise<any> {
    const companyId: string = this.userInfoService.getCompanyId();
    return firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/providers`, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
      params: {
        searchData: search,
        searchType: searchType,
        isActive: isActive,
        offset: offset
      }
    }))
  }

  async updateActiveStatus(providerId: number, isActive: boolean): Promise<any> {
    const companyId: string = this.userInfoService.getCompanyId();
    return firstValueFrom(this.http.put(`${this.api}/companies/${companyId}/providers/${providerId}/active-status`, {}, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
      params: {
        isActive: !isActive
      }
    }))
  }
}
