<div class="grid">
  <div class="col-12 md:col-12 lg:col-12">
    <div class="page-header">
      <h3 class="page-title">
        <i class="fi fi-rr-users mr-2"></i>
        Usuários da empresa: {{selectedCompany}}
      </h3>
    </div>
  </div>
  <div class="col-12 md:col-12 lg:col-12">
    <div class="card">
      <div class="card-body">
        <p-table [value]="users" [loading]="loadingUsers" [showLoader]="false" styleClass="p-datatable-striped"
          responsiveLayout="stack" [responsive]="true">
          <ng-template pTemplate="header">
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>Tipo de acesso</th>
            </tr>
          </ng-template>

          <ng-template *ngIf="!isMobile()" pTemplate="body" let-user>
            <tr>
              <td>{{user.name}}</td>
              <td>{{user.email}}</td>
              <td>{{user.phone}}</td>
              <td>{{user.relation}}</td>
            </tr>
          </ng-template>
          <ng-template *ngIf="!isMobile()" pTemplate="loadingbody">
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
          </ng-template>

          <ng-template *ngIf="isMobile()" pTemplate="body" let-user>
            <tr>
              <td>
                <p><b>Nome:</b>
                <p> {{user.name}}</p>
              </td>
              <td>
                <p><b>Email:</b>
                <p> {{user.email}}</p>
              </td>
              <td>
                <p><b>Telefone:</b>
                <p> {{user.phone}}</p>
              </td>
              <td>
                <p><b>Tipo de acesso:</b>
                <p>{{user.relation}}</p>
              </td>

            </tr>
          </ng-template>
          <ng-template *ngIf="isMobile()" pTemplate="loadingbody">

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

          </ng-template>

        </p-table>
      </div>
    </div>
  </div>
</div>

<div class="float-action">
  <button pButton pRipple type="button" icon="pi pi-plus" class="buttonRounded btn-lg"
    (click)="openCreateUser()"></button>
</div>


<p-dialog header="Criar usuário" [(visible)]="displayUsers" [modal]="true" [style]="{width: '80vw'}" [draggable]="false"
  appendTo="body" [baseZIndex]="9999" [autoZIndex]="true" [resizable]="false">
  <div class="grid">
    <form class="content-form" [formGroup]="usersForm">

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputText formControlName="name" type="text" placeholder="Nome" />
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask placeholder="CPF" formControlName="cpf" mask="999.999.999-99"></p-inputMask>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputText formControlName="email" type="email" placeholder="E-mail" />
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask placeholder="Celular" formControlName="phone" mask="(99) 99999-9999"></p-inputMask>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-dropdown [options]="optionTypeCompanies" formControlName="relationType"
          placeholder="Selecione o tipo de usuário" optionLabel="label" optionValue="value"
          appendTo="body"></p-dropdown>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid">
        <button class="button" type="button" (click)="create()">Criar Usuário</button>
      </div>
    </form>
  </div>
</p-dialog>
