<div class="row">

  <div class="col-lg-12">
    <div class="page-header">
      <h3 class="page-title">
        <i class="fi fi-rr-portrait mr-2"></i>
        Perfil
      </h3>
    </div>
  </div>

  <div class="col-lg-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-2">
            <h5>Nome:</h5>
            <span>{{user?.name}}</span>
          </div>
          <div class="col-lg-2">
            <h5>Email:</h5>
            <span>{{user?.email}}</span>
          </div>
          <div class="col-lg-2">
            <h5>Contato:</h5>
            <span>{{user?.phone}}</span>
          </div>
          <div class="col-lg-2">
            <h5>Alterar foto:</h5>
            <p-fileUpload mode="basic" accept="image/jpg, image/jpeg, image/png" [maxFileSize]="1000000" [auto]="true"
                          chooseIcon="fi fi-rr-camera"
                          (onSelect)="onUploadProfileImage($event.currentFiles[0])"></p-fileUpload>
          </div>
          <div class="col-lg-2">
            <h5>Alterar senha:</h5>
            <p-button icon="pi pi-lock" (click)="openChangePassword()"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="page-header">
      <h3 class="page-title">
        <i class="fi fi-rr-building mr-2"></i>
        Empresa selecionada
      </h3>
    </div>
  </div>

  <div class="col-lg-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-2">
            <h5>Nome:</h5>
            <span>{{selectedCompany?.data?.companyName}}</span>
          </div>
          <div class="col-lg-2">
            <h5>CNPJ:</h5>
            <span>{{selectedCompany?.data?.cnpj}}</span>
          </div>
          <div class="col-lg-2">
            <h5>Atividade:</h5>
            <span>{{selectedCompany?.cnae?.description}}</span>
          </div>
          <div class="col-lg-2">
            <h5>Plano:</h5>
            <span>{{selectedCompany?.plan}}</span>
          </div>
          <div class="col-lg-2">
            <h5>Status:</h5>
            <span>{{selectedCompany?.status}}</span>
          </div>
          <div class="col-lg-2">
            <h5>Regime:</h5>
            <span>{{selectedCompany?.taxInfo?.regime}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="page-header">
      <h3 class="page-title">
        <i class="fi fi-rr-file-invoice-dollar"></i>
        Contratos
      </h3>
    </div>
  </div>

  <div class="col-12 md:col-12 lg:col-12">
    <div class="card">
      <div class="card-body">

        <p-table [value]="contracts" [loading]="loadingContracts" [showLoader]="false"
                 styleClass="p-datatable-striped" responsiveLayout="stack" [responsive]="true">

          <ng-template pTemplate="header">
            <tr>
              <th>Início</th>
              <th>Término</th>
              <th>Subtotal</th>
              <th>Desconto</th>
              <th>Total</th>
              <th>Pago</th>
              <th>Ativo</th>
              <th></th>
            </tr>
          </ng-template>

          <ng-template *ngIf="!isMobile()" pTemplate="body" let-index>
            <tr>
              <td>{{index.startDate}}</td>
              <td>{{index.endDate}}</td>
              <td>{{index.subtotal | currency : 'BRL':true}}</td>
              <td>{{index.discount | currency : 'BRL':true}}</td>
              <td>{{index.total | currency : 'BRL':true}}</td>
              <td>{{index.isPaid ? 'Sim' : 'Não'}}</td>
              <td>{{index.isActive ? 'Sim' : 'Não'}}</td>
              <td>
                <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [baseZIndex]="9999"
                        [autoZIndex]="true"></p-menu>
                <button type="button" pButton icon="pi pi-ellipsis-h" class="p-button-text" appendTo="body"
                        (click)="menu.toggle($event); getMenuItems(index)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template *ngIf="!isMobile()" pTemplate="loadingbody">
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
          </ng-template>

          <ng-template *ngIf="isMobile()" pTemplate="body" let-index>
            <tr>
              <td>
                <p><b>Data de entrada:</b>
                <p> {{index.startDate}}</p>
              </td>
              <td>
                <p><b>Data de vencimento:</b>
                <p>{{index.endDate}}</p>
              </td>
              <td>
                <p><b>Subtotal:</b>
                <p> {{index.subtotal | currency : 'BRL':true}}</p>
              </td>
              <td>
                <p><b>Desconto:</b>
                <p>{{index.discount | currency : 'BRL':true}}</p>
              </td>
              <td>
                <p><b>Total:</b>
                <p> {{index.total | currency : 'BRL':true}}</p>
              </td>
              <td>
                <p><b>Pago:</b>
                <p>{{index.isPaid ? 'Sim' : 'Não'}}</p>
              </td>
              <td>
                <p><b>Ativo:</b>
                <p> {{index.isActive ? 'Sim' : 'Não'}}</p>
              </td>
              <td>
                <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [baseZIndex]="9999"
                        [autoZIndex]="true"></p-menu>
                <button type="button" pButton icon="pi pi-ellipsis-h" class="p-button-text" appendTo="body"
                        (click)="menu.toggle($event); getMenuItems(index)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template *ngIf="isMobile()" pTemplate="loadingbody">

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

          </ng-template>

        </p-table>

      </div>
    </div>
  </div>
</div>

<p-dialog header="Alterar senha" [(visible)]="displayPassword" [modal]="true" [style]="{width: '50vw'}"
          [draggable]="false" appendTo="body" [baseZIndex]="9999" [autoZIndex]="true" [resizable]="false">
  <form class="content-form" [formGroup]="passwordForm">
    <div class="grid">

      <div class="col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12 p-fluid input-margin">
        <input pInputText formControlName="password" type="password" placeholder="Nova senha"/>
      </div>

      <div class="col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12 p-fluid input-margin">
        <input pInputText formControlName="passwordConfirm" type="password" placeholder="Confirme a senha"/>
      </div>

      <div class="col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12 p-fluid input-margin">
        <button class="button" type="button" (click)="onSave()">Salvar</button>
      </div>
    </div>
  </form>
</p-dialog>
