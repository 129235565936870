import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProviderService } from './../../services/providers/providers.service';
import { LocalStorageService } from "../../context/local-storage.service";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { SpinnerService } from 'src/app/navigation/spinner/spinner.service';
import { DeviceDetectorService } from "ngx-device-detector";
import { AlertManagerService } from 'src/app/context/alert-manager.service';
import { PersonTypeEnum } from 'src/app/enums/person-type.enum';
import { PhoneTypeEnum } from 'src/app/enums/phone-type.enum';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit {

  onlyInactives: boolean = false;

  loadingProviders: boolean = false;
  providers: any[];

  menuItems: MenuItem[] = [];

  typePersonOptions: SelectItem[] = [];
  typePhoneOptions: SelectItem[] = [];
  selectedTypePerson: string = PersonTypeEnum.COMPANY;
  selectedTypePhone: string = PhoneTypeEnum.CELLPHONE;
  selectedEditDocument: string = null;
  regexDocumentPJ: RegExp = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;
  regexDocumentPF: RegExp = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
  regexCellPhone: RegExp = /\(\d{2}\)\s\d{5}\-\d{4}/;
  regexPhone: RegExp = /\(\d{2}\)\s\d{4}\-\d{4}/;

  public personTypeEnum = PersonTypeEnum;


  providerForm = new FormGroup({
    id: this.formbuilder.control(null),
    name: this.formbuilder.control('', [Validators.required]),
    cnpj: this.formbuilder.control(null),
    email: this.formbuilder.control(null),
    phone: this.formbuilder.control(null),
    address: this.formbuilder.control(null)
  });

  addressForm = new FormGroup({
    postalCode: new FormControl(),
    state: new FormControl(),
    city: new FormControl(),
    street: new FormControl(),
    neighborhood: new FormControl(),
    complement: new FormControl(),
    number: new FormControl(),
  });
  searchFilter: string = null;
  searchType: SelectItem = null;
  optionSearch: SelectItem[] = [];
  noMoreItems: boolean = false;

  constructor(
    private providerService: ProviderService,
    private formbuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private confirmationService: ConfirmationService,
    public appComponent: AppComponent,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private deviceService: DeviceDetectorService,
    private alertService: AlertManagerService
  ) {
  }

  async ngOnInit() {
    this.showAddButton();
    this.resetForm();
    this.setSearchOptions();
    this.setCustomerTypeOptions();
    await this.search(true);
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  showAddButton() {
    this.appComponent.showPlusButton = true;
  }

  resetForm() {
    this.providerForm.reset();
    this.addressForm.reset();
  }

  setSearchOptions() {
    this.optionSearch = [
      { label: 'Sem Filtro', value: 'NONE' },
      { label: 'Nome', value: 'NAME' },
      { label: 'Telefone', value: 'PHONE' },
      { label: 'CNPJ', value: 'CNPJ' },
    ];
    this.searchType = this.optionSearch[0];
  }

  setCustomerTypeOptions() {
    this.typePersonOptions = [
      { label: PersonTypeEnum.COMPANY, value: PersonTypeEnum.COMPANY },
      { label: PersonTypeEnum.PERSON, value: PersonTypeEnum.PERSON },
    ];

    this.typePhoneOptions = [
      { label: PhoneTypeEnum.CELLPHONE, value: PhoneTypeEnum.CELLPHONE },
      { label: PhoneTypeEnum.PHONE, value: PhoneTypeEnum.PHONE },
    ];
  }

  maskTypePerson(): string {
    if (this.selectedTypePerson == PersonTypeEnum.COMPANY) {
      return "99.999.999/9999-99"
    } else {
      return "999.999.999-99"
    }
  }

  maskTypePhone() {
    if (this.selectedTypePhone == PhoneTypeEnum.CELLPHONE) {
      return "(99) 99999-9999"
    } else {
      return "(99) 9999-9999"
    }
  }

  placeholderTypePhone() {
    if (this.selectedTypePhone == PhoneTypeEnum.CELLPHONE) {
      return "Celular (Opcional)"
    } else {
      return "Telefone (Opcional)"
    }
  }

  placeholderTypePerson() {
    if (this.selectedTypePerson == PersonTypeEnum.COMPANY) {
      return "CNPJ (Opcional)"
    } else {
      return "CPF (Opcional)"
    }
  }

  async handleSearch(event: any) {
    console.log(event)
    if (!event) {
      // await this.getProviders();
    }
  }

  async search(refresh: boolean) {
    if (this.searchType == null || !this.searchType) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção!', detail: `Selecione o tipo de busca`, life: 5000 });
      return;
    }
    try {

      if (refresh) {
        this.providers = [];
        this.noMoreItems = false;
      }

      this.loadingProviders = true;
      let response = await this.providerService.searchProvider(!this.onlyInactives, this.searchFilter, this.searchType.value, this.providers.length);

      if (response.length == 0) {
        this.alertService.showAlert('Nada encontrado');
        this.noMoreItems = true;
      }

      if (this.providers.length == 0) {
        this.providers = response;
      } else {
        this.providers = this.providers.concat(response);
      }

    } catch (e: any) {
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: `${e.error?.message}`, life: 5000 });
    } finally {
      this.loadingProviders = false;
    }
  }

  // async getProviders() {
  //   try {
  //     this.loadingProviders = true;
  //     this.providers = await this.providerService.getProviders(!this.onlyInactives);
  //   } catch (e: any) {
  //     this.messageService.add({ severity: 'error', summary: 'Erro!', detail: `${e.error?.message}`, life: 5000 });
  //   } finally {
  //     this.loadingProviders = false;
  //   }
  // }

  async updateActiveStatus(provider: any) {
    try {
      this.spinnerService.show();
      this.providers = await this.providerService.updateActiveStatus(provider.id, provider.isActive);
      this.spinnerService.hide();
      this.alertService.showSuccess('Status de atividade atualizado com sucesso!');
      await this.search(true);
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    }
  }

  async create() {
    const form = this.providerForm.value;
    const address = this.addressForm.value;

    if (form.cnpj == "") {
      form.cnpj = null;
    }

    if (form.name == "") {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: `Nome do fornecedor é obrigatório!`,
        life: 5000
      });
      return
    }

    var addressValue = null
    if (address.street != null && address.number != null && address.neighborhood != null && address.city != null && address.state != null && address.postalCode != null) {
      addressValue = `${address.street}, ${address.number}, ${address.neighborhood} - ${address.city}/${address.state} - ${address.postalCode}`
    }

    const request = {
      companyId: this.localStorageService.getCompanyId(),
      name: form.name,
      cnpj: form.cnpj,
      email: form.email,
      phone: form.phone,
      address: addressValue
    }

    try {
      this.appComponent.displayProvider = false;
      this.spinnerService.show();
      await this.providerService.createProvider(request);
      this.spinnerService.hide();
      this.alertService.showSuccess('Dados atualizados com sucesso');
      await this.search(true);
    } catch (error: any) {
      this.spinnerService.hide();
      this.messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: `${error.message}`, life: 5000 });
    } finally {
      this.resetForm();
    }
  }

  async edit(providerForm: FormGroup) {
    const request = {
      name: providerForm.value.name,
      cnpj: providerForm.value.cnpj ? providerForm.value.cnpj.replace(/\D/g, '') : null,
      email: providerForm.value.email ? providerForm.value.email : null,
      phone: providerForm.value.phone ? providerForm.value.phone.replace(/\D/g, '') : null,
    }
    console.log(request);
    try {
      this.appComponent.displayEditProvider = false;
      this.spinnerService.show();
      await this.providerService.editProvider(providerForm.value.id, request);
      this.spinnerService.hide();
      this.alertService.showSuccess("Fornecedor atualizado com sucesso!");
      await this.search(true);
    } catch (e: any) {
      this.spinnerService.hide();
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: `${e.error?.message}`, life: 5000 });
    } finally {
      this.resetForm();
    }
  }

  openEdit(row: any) {
    const regexDocument = new RegExp(this.regexDocumentPF);
    const regexPhone = new RegExp(this.regexCellPhone);
    if (regexDocument.test(row.cnpj)) {
      this.selectedTypePerson = PersonTypeEnum.PERSON;
    } else {
      this.selectedTypePerson = PersonTypeEnum.COMPANY;
    }
    if (regexPhone.test(row.phone)) {
      this.selectedTypePhone = PhoneTypeEnum.CELLPHONE;
    } else {
      this.selectedTypePhone = PhoneTypeEnum.PHONE;
    }
    setTimeout(() => {
      this.providerForm.patchValue({
        id: row.id,
        name: row.name,
        cnpj: row.cnpj,
        email: row.email,
        phone: row.phone,
      });

    }, 0);
    this.appComponent.displayEditProvider = true;
  }

  async delete(provider: any) {
    try {
      this.spinnerService.show();
      await this.providerService.deleteProvider(provider.id);
      this.spinnerService.hide();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso!',
        detail: 'Informação atualizada com sucesso.',
        life: 5000
      });
    } catch (e: any) {
      this.spinnerService.hide();
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: `${e.message}`, life: 5000 });
    } finally {
      this.resetForm();
      await this.search(true);
    }
  }

  getMenuItems(provider: any): MenuItem[] {

    if (provider.isActive) {
      this.menuItems = [
        {
          label: 'Editar', command: () => this.openEdit(provider),
        },
        {
          label: 'Inativar', command: () => this.updateActiveStatus(provider),
        },
        // {
        //   label: 'Excluir', command: () => this.confirm(provider),
        // },
      ];
    } else {
      this.menuItems = [
        {
          label: 'Editar', command: () => this.edit(provider),
        },
        {
          label: 'Ativar', command: () => this.updateActiveStatus(provider),
        },
        // {
        //   label: 'Excluir', command: () => this.confirm(provider),
        // },
      ];
    }

    return this.menuItems;
  }

  confirm(provider: any) {
    this.confirmationService.confirm({
      header: `Fornecedor: ${provider.name}`,
      message: `Tem certeza que deseja excluir?`,
      acceptIcon: '',
      rejectIcon: '',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.delete(provider);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
}
