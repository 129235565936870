<div class="content" *ngIf="isLoading">
    <app-spinner></app-spinner>
  </div>

  <div class="content" *ngIf="!isLoading">
    <div class="box">
      <div class="brand-logo">
        <img src="../../../assets/images/ebalance-logo.svg" height="80px" width="auto" alt="E-balance">
      </div>
      <div class="grid">
        <form class="content-form" [formGroup]="recoveryForm">
          <div class="col-12 md:col-12 lg:col-12 p-fluid">
            <input pInputText formControlName="userEmail" type="email" placeholder="Digite seu e-mail"/>
          </div>
          <div class="col-12 md:col-12 lg:col-12 p-fluid">
            <button class="buttonLogin" type="button" (click)="recovery()">Recuperar senha</button>
          </div>
          <div class="col-12 md:col-12 lg:col-12 p-fluid">
            <button class="button-outlined-login" type="button" (click)=" backToLogin()">Voltar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
