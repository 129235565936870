import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { UtilService } from "../../services/utils/util.service";
import { UserService } from "../../services/user/user.service";
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SpinnerService } from 'src/app/navigation/spinner/spinner.service';
import { AlertManagerService } from 'src/app/context/alert-manager.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  value: number = 25;
  stepOne: boolean = null;
  stepTwo: boolean = null;
  stepThree: boolean = null;
  stepFour: boolean = null;

  constructor(
    private utilService: UtilService,
    private userService: UserService,
    private router: Router,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private alertService: AlertManagerService,
  ) { }

  ngOnInit() {
    this.setForms();
  }

  setForms() {
    this.registerForm = new FormGroup({
      user: new FormGroup({
        name: new FormControl(null, Validators.required),
        cpf: new FormControl(null, Validators.required),
        phone: new FormControl(null, Validators.required),
        email: new FormControl(null, Validators.required),
      }),
      password: new FormControl('', Validators.required),
      company: new FormGroup({
        picture: new FormControl(null),
        name: new FormControl(null, [Validators.required]),
        plan: new FormControl("STANDARD"),
        data: new FormGroup({
          companyName: new FormControl('', Validators.required),
          cnpj: new FormControl('', Validators.required),
          email: new FormControl('', Validators.required),
          phone: new FormControl('', Validators.required),
          municipalRegistration: new FormControl(null),
          stateRegistration: new FormControl(null),
        }),
        address: new FormGroup({
          postalCode: new FormControl(null, [Validators.required]),
          state: new FormControl(null, [Validators.required]),
          city: new FormControl(null, [Validators.required]),
          street: new FormControl(null, [Validators.required]),
          number: new FormControl(null, [Validators.required]),
          complement: new FormControl(null),
          neighborhood: new FormControl(null, [Validators.required]),
          lat: new FormControl(null),
          lng: new FormControl(null)
        }),
        cnae: new FormGroup({
          code: new FormControl(null, [Validators.required]),
          description: new FormControl(null, [Validators.required]),
        }),
        taxInfo: new FormGroup({
          regime: new FormControl("MEI",),
          specialRegime: new FormControl("MEI"),
          internalPrefectureCode: new FormControl(null),
          inssRetention: new FormControl(0.0),
          icmsRates: new FormControl([]),
        })
      })
    });
  }

  backStep() {
    switch (this.value) {
      case 100:
        this.value = 75
        break;
      case 75:
        this.value = 50
        break;
      case 50:
        this.value = 25
        break;
      default:
        this.value = 25
        this.router.navigateByUrl(`login`);
        break;
    }
  }

  async register() {
    switch (this.value) {
      case 25:
        this.validStepOne();
        return;
      case 50:
        this.validStepTwo();
        return;
      case 75:
        this.validStepThree();
        return;
      case 100:
        this.validStepFour();
        break;
      default:
        break;
    }
    // this.alertService.showError("Preencha todos os campos do formulário.");
  }

  validStepOne() {
    if (this.registerForm.get('company')?.get('name')?.valid &&
      this.registerForm.get('company')?.get('data')?.get('email').valid &&
      this.registerForm.get('company')?.get('data')?.get('phone').valid) {
      this.stepOne = true;
      this.value = 50;
    } else {
      this.stepOne = false;
    }
  }

  validStepTwo() {
    if (this.registerForm.get('company')?.get('data')?.get('cnpj')?.valid &&
      this.registerForm.get('company')?.get('data')?.get('companyName').valid &&
      this.registerForm.get('company')?.get('cnae')?.get('description').valid &&
      this.registerForm.get('company')?.get('cnae')?.get('code').valid) {
      this.stepTwo = true;
      this.value = 75;
    } else {
      this.stepTwo = false;
    }
  }

  validStepThree() {
    if (this.registerForm.get('company')?.get('address')?.get('postalCode').valid &&
      this.registerForm.get('company')?.get('address')?.get('state').valid &&
      this.registerForm.get('company')?.get('address')?.get('city').valid &&
      this.registerForm.get('company')?.get('address')?.get('street').valid &&
      this.registerForm.get('company')?.get('address')?.get('neighborhood').valid &&
      this.registerForm.get('company')?.get('address')?.get('number').valid) {
      this.stepThree = true;
      this.value = 100;
    } else {
      this.stepThree = false;
    }
  }

  async validStepFour() {
    if (this.registerForm.get('user')?.get('name')?.valid &&
      this.registerForm.get('user')?.get('cpf')?.valid &&
      this.registerForm.get('user')?.get('phone')?.valid &&
      this.registerForm.get('user')?.get('email')?.valid &&
      this.registerForm.get('password')?.valid) {
      this.stepFour = true;
      await this.onSave();
    } else {
      this.stepFour = false;
    }
  }

  async onSave() {
    const request = this.registerForm.value;
    try {
      this.spinnerService.show();
      await this.userService.createAccount(request);
      this.spinnerService.hide();
      this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: `Conta criada com sucesso!`, life: 5000 });
    } catch (error: any) {
      this.spinnerService.hide();
      this.messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: `${error.error.message}`, life: 5000 });
    } finally {
      this.registerForm.reset();
      this.router.navigateByUrl('login');
    }
  }

  async getInfoCompany() {

    try {

      this.spinnerService.show();

      const cnpj = this.registerForm.value.company.data.cnpj;
      const response = await this.utilService.getCompanyCnpj(cnpj);

      this.registerForm.patchValue({
        company: {
          data: {
            companyName: response.name,
            cnpj: this.registerForm.value.company.data.cnpj !== "" ? this.registerForm.value.company.data.cnpj : response.cnpj,
            email: this.registerForm.value.company.data.email !== "" ? this.registerForm.value.company.data.email : response.email,
            phone: this.registerForm.value.company.data.phone !== "" ? this.registerForm.value.company.data.phone : response.phone,
            municipalRegistration: this.registerForm.value.company.data.municipalRegistration !== "" ? this.registerForm.value.company.data.municipalRegistration : null,
            stateRegistration: this.registerForm.value.company.data.stateRegistration !== "" ? this.registerForm.value.company.data.stateRegistration : null,
          },
          address: {
            postalCode: response.address.postalCode,
            state: response.address.state,
            city: response.address.city,
            street: response.address.street,
            neighborhood: response.address.neighborhood,
            number: response.address.number,
          },
          cnae: {
            code: response.cnaeCode,
            description: response.cnaeDescription,
          },
        },
      })
    } catch (error: any) {
      this.spinnerService.hide();
      this.messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: `${error.error.message}`, life: 5000 });
    } finally {
      this.spinnerService.hide();
    }
  }

  backLogin() {
    this.router.navigateByUrl('login');
  }

  async handleCEP() {
    const zipCode = this.registerForm.get('company')?.get('address')?.get('postalCode')?.value;
    const response = await this.utilService.getZipCode(zipCode);
    console.log(response);
    this.registerForm.get('company')?.get('address')?.patchValue({
      postalCode: response.cep,
      state: response.uf,
      city: response.localidade,
      street: response.logradouro,
      number: null,
      complement: null,
      neighborhood: response.bairro,
    })
  }
}
