<div class="content" *ngIf="isLoading">
  <app-spinner></app-spinner>
</div>

<div class="content" *ngIf="!isLoading">
  <div class="box">
    <div class="brand-logo">
      <img src="../../../assets/images/ebalance-logo.svg" height="80px" width="auto" alt="E-balance">
    </div>
    <div class="grid">
      <form class="content-form" [formGroup]="passwordForm">
        <div class="col-12 md:col-12 lg:col-12 p-fluid">
          <input pInputText formControlName="password" type="password" placeholder="Nova senha"/>
        </div>
        <div class="col-12 md:col-12 lg:col-12 p-fluid">
          <input pInputText formControlName="passwordConfirm" type="password" placeholder="Confirmar nova senha"/>
        </div>
        <div class="col-12 md:col-12 lg:col-12 p-fluid">
          <button class="buttonLogin" type="button" label="Login" (click)="resetPassword()">Salvar</button>
        </div>
      </form>
    </div>
  </div>
</div>
