<div class="grid">
  <div class="col-12 md:col-12 lg:col-12">
    <div class="page-header">
      <h3 class="page-title">
        <i class="fi fi-rr-money-check-edit menu-icon"></i>
        Empresas
      </h3>
    </div>
  </div>
  <div class="col-12 md:col-12 lg:col-12">
    <div class="card">
      <div class="card-body">
        <p-table [value]="companies" [loading]="loadingCompanies" [showLoader]="false"
                 styleClass="p-datatable-striped" responsiveLayout="stack" [responsive]="true">

          <ng-template pTemplate="header">
            <tr>
              <th>Nome</th>
              <th>Razão Social</th>
              <th>CNPJ</th>
              <th>Status</th>
              <th></th>
            </tr>
          </ng-template>

          <ng-template *ngIf="!isMobile()" pTemplate="body" let-index>
            <tr>
              <td>{{index.name}}</td>
              <td>{{index.data.companyName}}</td>
              <td>{{index.data.cnpj}}</td>
              <td>{{index.status}}</td>
              <td>
                <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [baseZIndex]="9999"
                        [autoZIndex]="true"></p-menu>
                <button type="button" pButton icon="pi pi-ellipsis-h" class="p-button-text" appendTo="body"
                        (click)="menu.toggle($event); getMenuItems(index)"></button>
              </td>
            </tr>
          </ng-template>

          <ng-template *ngIf="!isMobile()" pTemplate="loadingbody">
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
          </ng-template>

          <ng-template *ngIf="isMobile()" pTemplate="body" let-company>
            <tr>
              <td>
                <p><b>Nome:</b></p>
                <p>{{company.name}}</p>
              </td>
              <td>
                <p><b>Razão social:</b></p>
                <p>{{company.data.companyName}}</p>
              </td>
              <td>
                <p><b>CNPJ:</b></p>
                <p>{{company.data.cnpj ? company.data.cnpj : "Não informado"}}</p>
              </td>
              <td>
                <p><b>status:</b></p>
                <p>{{company.status}}</p>
              </td>
              <td>
                <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [baseZIndex]="9999"
                        [autoZIndex]="true"></p-menu>
                <button type="button" pButton icon="pi pi-ellipsis-h" class="p-button-text" appendTo="body"
                        (click)="menu.toggle($event); getMenuItems(company)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template *ngIf="isMobile()" pTemplate="loadingbody">

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

          </ng-template>

        </p-table>

      </div>
    </div>
  </div>
</div>


<p-confirmDialog header="Confirme a exclusão" appendTo="body" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog header="Novo serviço" [(visible)]="displayService" [modal]="true" [style]="{width: '80vw'}"
          [draggable]="false" appendTo="body" [baseZIndex]="9999" [autoZIndex]="true" [resizable]="false">
  <div class="grid">
    <form class="content-form" [formGroup]="serviceForm">

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputText formControlName="name" type="text" placeholder="Nome do Serviço"/>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputNumber formControlName="price" mode="currency" currency="BRL" locale="pt-BR" placeholder="Preço">
        </p-inputNumber>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <button class="button" type="button" (click)="create()">Salvar</button>
      </div>
    </form>
  </div>
</p-dialog>
