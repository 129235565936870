import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ServiceService} from '../../services/services/services.service';
import {LocalStorageService} from "../../context/local-storage.service";
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {CompaniesService} from '../../services/companies/companies.service';
import {SpinnerService} from 'src/app/navigation/spinner/spinner.service';
import {AppComponent} from 'src/app/app.component';
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  templateUrl: './admin-companies.component.html',
  styleUrls: ['./admin-companies.component.scss']
})
export class AdminCompaniesComponent implements OnInit {

  loadingCompanies: boolean = false;
  companies: any[];
  displayService: boolean = false;
  serviceForm: FormGroup;

  menuItems: MenuItem[] = [];

  searchFilter: string = null;

  constructor(
    private serviceService: ServiceService,
    private formbuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private companiesService: CompaniesService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService,
    public appComponent: AppComponent,
    private deviceService: DeviceDetectorService
  ) {

  }

  async ngOnInit() {
    this.appComponent.showPlusButton = true;
    this.setForms();
    await this.getAllCompanies();
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  isAdmin(): boolean {
    return this.localStorageService.isAdminUser();
  }

  setForms() {
    this.serviceForm = this.formbuilder.group({
      name: this.formbuilder.control(null, [Validators.required]),
      price: this.formbuilder.control(null, [Validators.required]),
    })
  }

  async getAllCompanies() {
    try {
      this.loadingCompanies = true;
      this.companies = await this.companiesService.getAllCompanies();
    } catch (e: any) {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro!',
        detail: `${e.error?.message}`,
        life: 5000
      });
    } finally {
      this.loadingCompanies = false;
    }
  }

  openCreateService() {
    this.displayService = true;
  }

  async delete(company: any) {
    try {
      this.spinnerService.show();
      await this.serviceService.delete(company.id);
      this.spinnerService.hide();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso!',
        detail: `Serviço excluído com sucesso.`,
        life: 5000
      });
    } catch (e: any) {
      this.spinnerService.hide();
      this.messageService.add({severity: 'error', summary: 'Erro!', detail: `${e.message}`, life: 5000});
    } finally {
      await this.getAllCompanies();
    }
  }

  async edit(service: any) {
    try {
      this.messageService.add({
        severity: 'info',
        summary: 'Atenção!',
        detail: `Função em desenvolvimento.`,
        life: 5000
      });
    } catch (e: any) {
      this.messageService.add({severity: 'error', summary: 'Erro!', detail: `${e.message}`, life: 5000});
    }
  }

  async create() {
    try {
      this.messageService.add({
        severity: 'info',
        summary: 'Atenção!',
        detail: `Função em desenvolvimento.`,
        life: 5000
      });
    } catch (e: any) {
      this.messageService.add({severity: 'error', summary: 'Erro!', detail: `${e.message}`, life: 5000});
    }
  }

  getMenuItems(company: any): MenuItem[] {
    if (company?.status !== "ACTIVE") {
      this.menuItems = [
        {
          label: 'Ativar', command: () => this.activeCompany(company),
        }
      ];
    } else {
      this.menuItems = []
    }
    return this.menuItems;
  }

  confirm(company: any) {
    this.confirmationService.confirm({
      header: `Empresa: ${company.name}`,
      message: `Tem certeza que deseja excluir?`,
      acceptIcon: '',
      rejectIcon: '',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.delete(company);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  async activeCompany(company: any) {
    try {
      this.spinnerService.show();
      await this.companiesService.activateCompany(company.id);
      this.spinnerService.hide();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso!',
        detail: 'Informação atualizada com sucesso.',
        life: 5000
      });
    } catch (e: any) {
      this.spinnerService.hide();
      this.messageService.add({
        severity: 'error',
        summary: 'Houve um erro!',
        detail: `${e.error?.message}`,
        life: 5000
      });
    } finally {
      await this.getAllCompanies();
    }
  }
}
