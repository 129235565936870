import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class AlertManagerService {

  constructor(
    private messageService: MessageService
  ) {
  }

  public showInfo(message: string) {
    this.messageService.add({severity: 'info', summary: 'Atenção!', detail: `${message}`, life: 5000});
  }

  public showAlert(message: string) {
    this.messageService.add({severity: 'warn', summary: 'Atenção!', detail: `${message}`, life: 5000});
  }

  public showError(message: string) {
    this.messageService.add({severity: 'error', summary: 'Erro!', detail: `${message}`, life: 5000});
  }


  public showSuccess(message: string) {
    this.messageService.add({severity: 'success', summary: 'Sucesso!', detail: `${message}`, life: 5000});
  }

}
