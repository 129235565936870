<div class="grid">
  <div class="col-12 md:col-12 lg:col-12">
    <div class="page-header">
      <h3 class="page-title">
        <i class="fi fi-rr-users menu-icon"></i>
        Clientes
      </h3>
    </div>
  </div>
  <div class="col-12 md:col-12 lg:col-12">
    <div class="card">
      <div class="card-body">
        <p-table [value]="customers" [loading]="loadingCustomers" [showLoader]="false" styleClass="p-datatable-striped"
          responsiveLayout="stack" >
          <ng-template pTemplate="caption">
            <div class="grid actions">
              <div class="xl:col-8 lg:col-8 md:col-12 sm:col-12 p-fluid">
                <input pInputText [(ngModel)]="searchFilter" type="text" placeholder="Pesquisa"
                  (ngModelChange)="handleSearch($event)" />
              </div>
              <div class="xl:col-3 lg:col-3 md:col-12 sm:col-12 p-fluid">
                <p-dropdown [options]="optionSearch" [(ngModel)]="searchType" placeholder="Filtro de Busca" (onChange)="clearSearchData($event)"
                  optionLabel="label" appendTo="body"></p-dropdown>
              </div>
              <div class="xl:col-1 lg:col-1 md:col-12 sm:col-12 p-fluid">
                <button pButton pRipple type="button" icon="pi pi-search" (click)="search(true)"
                  class="btn-action"></button>
              </div>
              <div class="field-checkbox">
                <p-checkbox [(ngModel)]="onlyInactives" [binary]="true" inputId="binary"
                  (onChange)="search(true)"></p-checkbox>
                <label class="labelCheckbox" for="binary">Exibir apenas inativos</label>
              </div>
              <!-- <div class="xl:col-12 lg:col-1 md:col-12 sm:col-12 p-fluid" [hidden]="!isAdmin()">
                <p-fileUpload mode="basic" chooseLabel="Importar" chooseIcon="fi fi-sr-upload" accept=".csv"
                              [maxFileSize]="1000000" [auto]="true"
                              (onSelect)="importFile($event.currentFiles[0])"></p-fileUpload>
              </div> -->
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th>Nome</th>
              <th>CPF/CNPJ</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>Endereço</th>
              <th></th>
            </tr>
          </ng-template>

          <ng-template *ngIf="!isMobile()" pTemplate="body" let-customer>
            <tr>
              <td>{{customer.name}}</td>
              <td>{{customer.cnpj ? customer.cnpj : "Não informado"}}</td>
              <td>{{customer.email ? customer.email : "Não informado"}}</td>
              <td>{{customer.phone ? customer.phone : "Não informado"}}</td>
              <td>{{customer.address ? customer.address : "Não informado"}}</td>
              <td>
                <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [baseZIndex]="9999"
                  [autoZIndex]="true"></p-menu>
                <button type="button" pButton icon="pi pi-ellipsis-h" class="p-button-text" appendTo="body"
                  (click)="menu.toggle($event); getMenuItems(customer)"></button>
                <!-- <button *ngIf="expense.isPaid" type="button" pButton pRipple icon="fi fi-sr-usd-circle" class="button-outlined" (click)="checkPay(expense)"></button>
            <button *ngIf="!expense.isPaid" type="button" pButton pRipple icon="fi fi-rr-usd-circle" class="button-outlined" (click)="checkPay(expense)"></button>
            <button type="button" pButton pRipple icon="fi fi-rr-trash" class="button-outlined" (click)="removeExpense(expense)"></button> -->
              </td>
            </tr>
          </ng-template>

          <ng-template *ngIf="!isMobile()" pTemplate="loadingbody">
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
          </ng-template>

          <ng-template *ngIf="isMobile()" pTemplate="body" let-customer>
            <tr>
              <td>
                <p><b>Nome:</b>
                <p>{{customer.name}}</p>
              </td>
              <!-- <td>
                <p><b>CPF/CNPJ:</b>
                <p>{{customer.cnpj ? customer.cnpj : "Não informado"}}</p>
              </td>
              <td>
                <p><b>Email:</b>
                <p>{{customer.email ? customer.email : "Não informado"}}</p>
              </td>
              <td>
                <p><b>Telefone:</b>
                <p>{{customer.phone ? customer.phone : "Não informado"}}</p>
              </td>
              <td>
                <p><b>Endereço:</b>
                <p> {{customer.address ? customer.address : "Não informado"}}</p>
              </td> -->
              <td>
                <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [baseZIndex]="9999"
                  [autoZIndex]="true"></p-menu>
                <button type="button" pButton icon="pi pi-ellipsis-h" class="p-button-text" appendTo="body"
                  (click)="menu.toggle($event); getMenuItems(customer)"></button>
                <!-- <button *ngIf="expense.isPaid" type="button" pButton pRipple icon="fi fi-sr-usd-circle" class="button-outlined" (click)="checkPay(expense)"></button>
            <button *ngIf="!expense.isPaid" type="button" pButton pRipple icon="fi fi-rr-usd-circle" class="button-outlined" (click)="checkPay(expense)"></button>
            <button type="button" pButton pRipple icon="fi fi-rr-trash" class="button-outlined" (click)="removeExpense(expense)"></button> -->
              </td>
            </tr>
          </ng-template>

          <ng-template *ngIf="isMobile()" pTemplate="loadingbody">

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

          </ng-template>

          <!-- <ng-template pTemplate="footer">
            <tr>
              <td>
                <div class="container-pagination">
                  <button pButton pRipple type="button" label="Carregar mais" icon="pi pi-search" (click)="search()"
                    class="button-outlined p-button-primary"></button>
                </div>
              </td>
            </tr>
          </ng-template> -->

        </p-table>
        <br>
        <div class="container-pagination" [hidden]="loadingCustomers || noMoreItems">
          <button pButton pRipple icon="fi fi-sr-rotate-right" type="button" label="Carregar mais" (click)="search(false)"
            class="button-outlined p-button-primary"></button>
        </div>

      </div>
    </div>
  </div>
</div>

<p-dialog header="Novo cliente" [(visible)]="appComponent.displayCustomer" [modal]="true" [style]="{width: '80vw'}"
  (onHide)="resetForm()" [draggable]="false" appendTo="body" [baseZIndex]="9999" [autoZIndex]="true"
  [resizable]="false">
  <form class="content-form" [formGroup]="customerForm">
    <div class="grid">

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-selectButton [options]="typePersonOptions" [(ngModel)]="selectedTypePerson"
          [ngModelOptions]="{standalone: true}" optionLabel="label" optionValue="value"></p-selectButton>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputText formControlName="name" type="text" placeholder="Nome" />
      </div>
      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask formControlName="cnpj" type="phone" [placeholder]="placeholderTypePerson()" [unmask]="true"
          [mask]="maskTypePerson()">
        </p-inputMask>
      </div>
      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputText formControlName="email" type="text" formControlName="email" placeholder="E-mail (Opcional)" />
      </div>

      <div class="col-12 lg:col-4 md:col-4 sm:col-12 p-fluid">
        <p-selectButton [options]="typePhoneOptions" [(ngModel)]="selectedTypePhone"
          [ngModelOptions]="{standalone: true}" optionLabel="label" optionValue="value"></p-selectButton>
      </div>
      <div class="col-12 lg:col-8 md:col-8 sm:col-12 p-fluid">
        <p-inputMask formControlName="phone" type="phone" [placeholder]="placeholderTypePhone()"
          [mask]="maskTypePhone()" [unmask]="true">
        </p-inputMask>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputTextarea type="text" formControlName="notes" placeholder="Observações (Opcional)" />
      </div>
    </div>
    <div class="input-margin" style="margin-left:-8px;margin-right:8px;">
      <app-address [address]="addressForm"></app-address>
    </div>
    <div class="grid">
      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <button class="button" type="button" (click)="create()">Criar</button>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog header="Editar cliente" [(visible)]="appComponent.displayEditCustomer" [modal]="true" (onHide)="resetForm()"
  [style]="{width: '80vw'}" [draggable]="false" appendTo="body" [baseZIndex]="9999" [autoZIndex]="true"
  [resizable]="false">
  <form class="content-form" [formGroup]="customerForm">
    <div class="grid">

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-selectButton [options]="typePersonOptions" [(ngModel)]="selectedTypePerson"
          [ngModelOptions]="{standalone: true}" optionLabel="label" optionValue="value"></p-selectButton>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputText formControlName="name" type="text" placeholder="Nome" />
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask [hidden]="selectedTypePerson == personTypeEnum.PERSON" formControlName="cnpj" type="phone"
          [unmask]="true" placeholder="CNPJ (Opcional)" mask="99.999.999/9999-99">
        </p-inputMask>
        <p-inputMask [hidden]="selectedTypePerson == personTypeEnum.COMPANY" formControlName="cnpj" type="phone"
          [unmask]="true" placeholder="CPF (Opcional)" mask="999.999.999-99">
        </p-inputMask>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputText formControlName="email" type="text" formControlName="email" placeholder="E-mail (Opcional)" />
      </div>
      <div class="col-12 lg:col-4 md:col-4 sm:col-12 p-fluid">
        <p-selectButton [options]="typePhoneOptions" [(ngModel)]="selectedTypePhone"
          [ngModelOptions]="{standalone: true}" optionLabel="label" optionValue="value"></p-selectButton>
      </div>
      <div class="col-12 lg:col-8 md:col-8 sm:col-12 p-fluid">
        <p-inputMask formControlName="phone" type="phone" [placeholder]="placeholderTypePhone()"
          [mask]="maskTypePhone()" [unmask]="true">
        </p-inputMask>
      </div>
      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputTextarea type="text" formControlName="notes" placeholder="Observações (Opcional)" />
      </div>
    </div>
    <div class="grid">
      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <button class="button" type="button" (click)="edit(customerForm)">Salvar</button>
      </div>
    </div>
  </form>
</p-dialog>

<p-confirmDialog header="Confirme a exclusão" appendTo="body" icon="pi pi-exclamation-triangle"></p-confirmDialog>
