<div class="grid">
  <div class="col-12 md:col-12 lg:col-12">
    <div class="page-header">
      <h3 class="page-title">
        <i class="fi fi-rr-coins menu-icon"></i>
        Vendas
      </h3>
    </div>
  </div>
  <div class="col-12 md:col-12 lg:col-12">
    <div class="card">
      <div class="card-body">

        <div class="contentActions grid-margin">
        </div>

        <p-table [value]="sales" [loading]="loadingSales" [showLoader]="false" styleClass="p-datatable-striped"
          responsiveLayout="stack" [responsive]="true">
          <ng-template pTemplate="caption">
            <div class="grid actions">
              <div class="xl:col-8 lg:col-8 md:col-12 sm:col-12 p-fluid">
                <input pInputText [(ngModel)]="searchData" type="text" placeholder="Pesquisa"
                  (ngModelChange)="handleSearch($event)" />
              </div>
              <div class="xl:col-3 lg:col-3 md:col-12 sm:col-12 p-fluid">
                <p-dropdown [options]="searchTypes" [(ngModel)]="selectedSearchType" placeholder="Filtro de vendas"
                  optionLabel="label" [showClear]="true" appendTo="body"></p-dropdown>
              </div>
              <div class="xl:col-1 lg:col-1 md:col-12 sm:col-12 p-fluid">
                <button pButton pRipple type="button" icon="pi pi-search" (click)="search(true)"
                  class="btn-action"></button>
              </div>
              <div class="field-checkbox">
                <p-checkbox [(ngModel)]="onlyCancelled" [binary]="true" inputId="binary"
                  (onChange)="search(true)"></p-checkbox>
                <label class="labelCheckbox" for="binary">Exibir apenas cancelados</label>
              </div>
            </div>

          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>Cliente</th>
              <th>Destinatário</th>
              <th>Entrada</th>
              <th>Vencimento</th>
              <th>Valor</th>
              <th>Recebido</th>
              <th>A Receber</th>
              <th>Data do pagamento</th>
              <th>Atrasado</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template *ngIf="!isMobile()" pTemplate="body" let-sale>
            <tr>
              <td>{{sale?.customer ? sale?.customer?.name : "Não informado"}}</td>
              <td>{{sale?.receiver ? sale?.receiver?.name : "Não informado"}}</td>
              <td>{{sale?.entryDate}}</td>
              <td>{{sale?.finishDate}}</td>
              <td>{{sale?.total | currency : "BRL" : true}}</td>
              <td>{{sale?.cashIn | currency : "BRL" : true}}</td>
              <td>{{sale?.toReceive | currency : "BRL" : true}}</td>
              <td>{{sale?.paymentDate ? sale.paymentDate : "Não pago"}}</td>
              <td>{{sale?.lateDays }} Dias</td>

              <td>
                <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [baseZIndex]="9999"
                  [autoZIndex]="true"></p-menu>
                <button type="button" pButton icon="pi pi-ellipsis-h" class="p-button-text" appendTo="body"
                  (click)="menu.toggle($event); getMenuItems(sale)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template *ngIf="!isMobile()" pTemplate="loadingbody">
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
          </ng-template>
          <ng-template *ngIf="isMobile()" pTemplate="body" let-sale>
            <tr>
              <td>
                <p><b>Cliente:</b>
                <p>{{sale?.customer ? sale?.customer?.name : "Não informado"}}</p>
              </td>
              <td>
                <p><b>Destinatário:</b>
                <p>{{sale?.receiver ? sale?.receiver?.name : "Não informado"}}</p>
              </td>
              <td>
                <p><b>Data de entrada:</b>
                <p>{{sale?.entryDate}}</p>
              </td>
              <td>
                <p><b>Data de vencimento:</b>
                <p> {{sale?.finishDate}}</p>
              </td>
              <td>
                <p><b>Total:</b>
                <p>{{sale?.total | currency : "BRL" : true}}</p>
              </td>
              <td>
                <p><b>Recebido:</b>
                <p>{{sale?.cashIn | currency : "BRL" : true}}</p>
              </td>
              <td>
                <p><b>A Receber:</b>
                <p>{{sale?.toReceive | currency : "BRL" : true}}</p>
              </td>
              <td>
                <p><b>Data do pagamento:</b>
                <p>{{sale?.paymentDate ? sale.paymentDate : "Não pago"}}</p>
              </td>
              <td>
                <p><b>Atraso:</b>
                <p>{{sale?.lateDays }} Dias</p>
              </td>
              <td>
                <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [baseZIndex]="9999"
                  [autoZIndex]="true"></p-menu>
                <button type="button" pButton icon="pi pi-ellipsis-h" class="p-button-text" appendTo="body"
                  (click)="menu.toggle($event); getMenuItems(sale)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template *ngIf="isMobile()" pTemplate="loadingbody">

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <br>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
            <hr>

          </ng-template>
        </p-table>
        <br>
        <div class="container-pagination" [hidden]="loadingSales || noMoreItems">
          <button pButton pRipple icon="fi fi-sr-rotate-right" type="button" label="Carregar mais"
            (click)="search(false)" class="button-outlined p-button-primary"></button>
        </div>

      </div>
    </div>
  </div>
</div>

<p-dialog header="Adicionar venda" [(visible)]="appComponent.displaySale" [modal]="true" appendTo="body"
  [baseZIndex]="9999" [autoZIndex]="true" [style]="{width: '80vw'}" [draggable]="false" [resizable]="false">
  <form class="content-form" [formGroup]="salesForm">
    <div class="grid">

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask placeholder="Data de entrada" type="text" formControlName="entryDate" mask="99/99/9999">
        </p-inputMask>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask placeholder="Data de saída" type="text" formControlName="finishDate" mask="99/99/9999">
        </p-inputMask>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <!-- <input pInputText formControlName="notes" type="text" placeholder="Observações" /> -->
        <textarea rows="8" style="width: 100%; max-width: 100%;" pInputTextarea formControlName="notes" type="text"
          placeholder="Observações"></textarea>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-autoComplete formControlName="customer" [showEmptyMessage]="true" [suggestions]="customers"
          placeholder="Selecionar Cliente (Opcional)" (completeMethod)="filterCustomer($event)"
          (onSelect)="changeCustomer($event)" field="name" [minLength]="1"></p-autoComplete>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-autoComplete formControlName="receiver" [showEmptyMessage]="true" [suggestions]="receivers"
          placeholder="Selecionar Destinatario (Opcional)" (completeMethod)="filterReceiver($event)"
          (onSelect)="changeReceiver($event)" field="name" [minLength]="1"></p-autoComplete>
      </div>

      <!-- <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <h3>Selecione o tipo da venda</h3>
        <div class="field-radiobutton">
          <p-radioButton name="type" [value]="'PRODUCT'" formControlName="type" inputId="type1"></p-radioButton>
          <label>Produto</label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton name="type" [value]="'SERVICE'" formControlName="type" inputId="type2"></p-radioButton>
          <label>Serviço</label>
        </div>
      </div> -->

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <h3>Adicionar Item referente ao tipo da venda</h3>
      </div>


      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-autoComplete formControlName="services" [showEmptyMessage]="true" [suggestions]="services"
          placeholder="Selecionar o Item (Opcional)" (completeMethod)="filterService($event)"
          (onSelect)="changeService($event)" field="name" [minLength]="1"></p-autoComplete>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <input pInputText formControlName="serviceQuantity" type="number" placeholder="Quantidade" />
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <button class="button-outlined" type="button" (click)="includeService()">Incluir Item</button>
      </div>


      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <h4>Itens</h4>
        <hr>
        <div *ngFor="let service of selectedServices;">
          <div class="content-service">
            <div class="item-service">
              <p><b>Nome do Item:</b> {{service?.serviceName}}</p>
              <p><b>Quantidade:</b> {{service?.serviceQuantity}}</p>
              <p><b>Total:</b> {{service?.total | currency}}</p>
            </div>
            <div class="item-service">
              <button pButton pRipple type="button" icon="fi fi-rr-trash"
                class="p-button-rounded p-button-danger p-button-text" (click)="removeService(service)"></button>
            </div>
          </div>

          <hr>
        </div>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <label for="currency-us"><b>Valor</b></label>
        <p-inputNumber formControlName="totalValue" mode="decimal" currency="BRL" locale="pt-BR" [minFractionDigits]="2"
          [maxFractionDigits]="2" [disabled]="selectedServices.length > 0">
        </p-inputNumber>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <label for="currency-us"><b>Desconto</b></label>
        <p-inputNumber formControlName="discount" mode="decimal" currency="BRL" locale="pt-BR" [minFractionDigits]="2"
          [maxFractionDigits]="2">
        </p-inputNumber>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <h4>Total: {{(salesForm.get('totalValue')?.value - salesForm.get('discount')?.value) | currency: 'BRL': true }}
        </h4>
        <hr>
      </div>

<!--       <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin contentCheckbox">-->
<!--        <p-checkbox formControlName="isPaid" [binary]="true" inputId="binary"></p-checkbox>-->
<!--        <label for="binary">Marcar como pago</label>-->
<!--      </div>-->

<!--      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin" *ngIf="salesForm.get('isPaid')?.value == true">-->
<!--        <p-inputMask placeholder="Data de Pagamento" type="text" formControlName="paymentDate" mask="99/99/9999">-->
<!--        </p-inputMask>-->
<!--      </div>-->

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <button class="button" type="button" (click)="create()">Salvar</button>
      </div>
    </div>
  </form>
</p-dialog>

<p-confirmDialog header="Confirme a exclusão" appendTo="body" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog header="Incluir recebimento" [(visible)]="displayPayment" [modal]="true" appendTo="body" [baseZIndex]="9999"
  [autoZIndex]="true" [style]="{width: '80vw'}" [draggable]="false" [resizable]="false">
  <form class="content-form" [formGroup]="paymentForm">
    <div class="grid">
      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask placeholder="Data do recebimento" type="text" formControlName="receiptDate" mask="99/99/9999">
        </p-inputMask>
      </div>
      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <label for="currency-us"><b>Valor</b></label>
        <p-inputNumber formControlName="value" mode="decimal" currency="BRL" locale="pt-BR" [minFractionDigits]="2"
                       [maxFractionDigits]="2">
        </p-inputNumber>
      </div>
      <h5>Valor pendente: {{ (this.sale ? this.sale.toReceive : 0.0)  | currency: 'BRL': true }}</h5>
      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <button class="button" type="button" (click)="checkPay()">Salvar</button>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog header="Editar venda" (onHide)="close()" [(visible)]="appComponent.displayEditSale" [modal]="true"
  appendTo="body" [baseZIndex]="9999" [autoZIndex]="true" [style]="{width: '80vw'}" [draggable]="false"
  [resizable]="false">
  <form class="content-form" [formGroup]="salesForm">
    <div class="grid">

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask placeholder="Data de entrada" type="text" formControlName="entryDate" mask="99/99/9999">
        </p-inputMask>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-inputMask placeholder="Data de saída" type="text" formControlName="finishDate" mask="99/99/9999">
        </p-inputMask>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <!-- <input pInputText formControlName="notes" type="text" placeholder="Observações" /> -->
        <textarea rows="8" style="width: 100%; max-width: 100%;" pInputTextarea formControlName="notes" type="text"
          placeholder="Observações"></textarea>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-autoComplete formControlName="customer" [showEmptyMessage]="true" [suggestions]="customers"
          placeholder="Selecionar Cliente (Opcional)" (completeMethod)="filterCustomer($event)" [showClear]="true"
          (onSelect)="changeCustomer($event)" field="name" [minLength]="1"></p-autoComplete>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <p-autoComplete formControlName="receiver" [showEmptyMessage]="true" [suggestions]="receivers"
          [showClear]="true" placeholder="Selecionar Destinatario (Opcional)" (completeMethod)="filterReceiver($event)"
          (onSelect)="changeReceiver($event)" field="name" [minLength]="1"></p-autoComplete>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <label for="currency-us"><b>Valor</b></label>
        <p-inputNumber formControlName="totalValue" mode="decimal" currency="BRL" [readonly]="true" locale="pt-BR"
          [minFractionDigits]="2" [maxFractionDigits]="2" [disabled]="selectedServices.length > 0">
        </p-inputNumber>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <label for="currency-us"><b>Desconto</b></label>
        <p-inputNumber formControlName="discount" mode="decimal" currency="BRL" locale="pt-BR" [minFractionDigits]="2"
          [maxFractionDigits]="2">
        </p-inputNumber>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <h4>Total: {{(salesForm.get('totalValue')?.value - salesForm.get('discount')?.value) | currency: 'BRL': true }}
        </h4>
        <hr>
      </div>

<!--      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin contentCheckbox">-->
<!--        <p-checkbox formControlName="isPaid" [binary]="true" inputId="binary"></p-checkbox>-->
<!--        <label for="binary">Marcar como pago</label>-->
<!--      </div>-->

<!--      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin" *ngIf="salesForm.get('isPaid')?.value == true">-->
<!--        <p-inputMask placeholder="Data de Pagamento" type="text" formControlName="paymentDate" mask="99/99/9999">-->
<!--        </p-inputMask>-->
<!--      </div>-->

      <div class="col-12 md:col-12 lg:col-12 p-fluid input-margin">
        <button class="button" type="button" (click)="onSaveEdit()">Salvar</button>
      </div>
    </div>
  </form>
</p-dialog>
