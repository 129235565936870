import { Directive } from '@angular/core';

@Directive({
  selector: '[appCurrencyBrl]'
})
export class CurrencyBrlDirective {

  constructor() { }
  
  directive(value: any) {
    const currency = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(value / 100);

    return currency
  }

}
