<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav">

    <li class="nav-item" [ngClass]="{ 'active': financial.isActive }">
      <a class="nav-link" routerLink="/app/financial" routerLinkActive #financial="routerLinkActive"
         (click)="toggleMenu()">
        <span class="menu-title">Financeiro</span>
        <!-- <i class="fi fi-rr-chart-histogram menu-icon"></i> -->
        <i class="fi fi-rr-chart-pie-alt menu-icon" *ngIf="!financial.isActive"></i>
        <i class="fi fi-sr-chart-pie-alt menu-icon" *ngIf="financial.isActive"></i>
      </a>
    </li>

    <li class="nav-item" [ngClass]="{ 'active': sales.isActive }">
      <a class="nav-link" routerLink="/app/sales" routerLinkActive #sales="routerLinkActive" (click)="toggleMenu()">
        <span class="menu-title">Vendas</span>
        <!-- <i class="fi fi-rr-sack-dollar menu-icon"></i> -->
        <i class="fi fi-rr-coins menu-icon" *ngIf="!sales.isActive"></i>
        <i class="fi fi-sr-coins menu-icon" *ngIf="sales.isActive"></i>
      </a>
    </li>

    <li class="nav-item" [ngClass]="{ 'active': expenses.isActive }">
      <a class="nav-link" routerLink="/app/expenses" routerLinkActive #expenses="routerLinkActive"
         (click)="toggleMenu()">
        <span class="menu-title">Despesas</span>
        <!-- <i class="fi fi-rr-file-invoice-dollar menu-icon"></i> -->
        <!--<i class="fi fi-rr-chat-arrow-down menu-icon"></i>-->
        <i class="fi fi-rr-piggy-bank menu-icon" *ngIf="!expenses.isActive"></i>
        <i class="fi fi-sr-piggy-bank menu-icon" *ngIf="expenses.isActive"></i>
      </a>
    </li>

    <li class="nav-item" [ngClass]="{ 'active': services.isActive }">
      <a class="nav-link" routerLink="/app/services" routerLinkActive #services="routerLinkActive"
         (click)="toggleMenu()">
        <span class="menu-title">Serviços</span>
        <!--<i class="fi fi-rr-money-check-edit menu-icon"></i>-->
        <i class="fi fi-rr-store-alt menu-icon" *ngIf="!services.isActive"></i>
        <i class="fi fi-sr-store-alt menu-icon" *ngIf="services.isActive"></i>
      </a>
    </li>

    <li class="nav-item" [ngClass]="{ 'active': customers.isActive }">
      <a class="nav-link" routerLink="/app/customers" routerLinkActive #customers="routerLinkActive"
         (click)="toggleMenu()">
        <span class="menu-title">Clientes</span>
        <!-- <i class="fi fi-rr-users-alt menu-icon"></i> -->
      <i class="fi fi-rr-user menu-icon" *ngIf="!customers.isActive"></i>
      <i class="fi fi-sr-user menu-icon" *ngIf="customers.isActive"></i>
      </a>
    </li>

    <li class="nav-item" [ngClass]="{ 'active': receivers.isActive }">
      <a class="nav-link" routerLink="/app/receivers" routerLinkActive #receivers="routerLinkActive"
         (click)="toggleMenu()">
        <span class="menu-title">Destinatários</span>
        <!-- <i class="fi fi-rr-users menu-icon"></i> -->
      <i class="fi fi-rr-users menu-icon" *ngIf="!receivers.isActive"></i>
      <i class="fi fi-sr-users menu-icon" *ngIf="receivers.isActive"></i>
      </a>
    </li>

    <li class="nav-item" [ngClass]="{ 'active': providers.isActive }">
      <a class="nav-link" routerLink="/app/providers" routerLinkActive #providers="routerLinkActive"
         (click)="toggleMenu()">
        <span class="menu-title">Fornecedores</span>
        <i class="fi fi-rr-truck-side menu-icon" *ngIf="!providers.isActive"></i>
        <i class="fi fi-sr-truck-side menu-icon" *ngIf="providers.isActive"></i>
      </a>
    </li>

    <!-- <li class="nav-item" [ngClass]="{ 'active': products.isActive }">
      <a class="nav-link" routerLink="/app/products" routerLinkActive #products="routerLinkActive">
        <span class="menu-title">Produtos</span>
        <i class="mdi mdi-home menu-icon"></i>
      </a>
    </li> -->


    <!-- <li class="nav-item" [ngClass]="{ 'active': forms.isActive }">
      <a class="nav-link" routerLink="forms/basic-elements" routerLinkActive #forms="routerLinkActive">
        <span class="menu-title">Form elements</span>
        <i class="mdi mdi-format-list-bulleted menu-icon"></i>
      </a>
    </li> -->

    <!-- <li class="nav-item" [ngClass]="{'active' : customer.isActive || recipient.isActive || service.isActive || provider.isActive }">
      <a class="nav-link" (click)="uiBasicCollapsed = !uiBasicCollapsed" [attr.aria-expanded]="uiBasicCollapsed" aria-controls="ui-basic">
        <span class="menu-title">Perfil</span>
        <i class="menu-arrow"></i>
        <i class="mdi mdi-crosshairs-gps menu-icon"></i>
      </a>
      <div class="collapse" id="ui-basic" [ngbCollapse]="!uiBasicCollapsed">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item"> <a class="nav-link" routerLink="basic-ui/pagination" routerLinkActive="active" #customer="routerLinkActive">Clientes</a></li>
          <li class="nav-item"> <a class="nav-link" routerLink="basic-ui/tabs" routerLinkActive="active" #recipient="routerLinkActive">Destinatários</a></li>
          <li class="nav-item"> <a class="nav-link" routerLink="basic-ui/typography" routerLinkActive="active" #service="routerLinkActive">Serviços</a></li>
          <li class="nav-item"> <a class="nav-link" routerLink="basic-ui/tooltips" routerLinkActive="active" #provider="routerLinkActive">Fornecedores</a></li>
        </ul>
      </div>
    </li> -->
    <!-- <li class="nav-item" [ngClass]="{ 'active': forms.isActive }">
      <a class="nav-link" routerLink="forms/basic-elements" routerLinkActive #forms="routerLinkActive">
        <span class="menu-title">Form elements</span>
        <i class="mdi mdi-format-list-bulleted menu-icon"></i>
      </a>
    </li>
    <li class="nav-item" [ngClass]="{ 'active': charts.isActive }">
      <a class="nav-link" routerLink="charts/chartjs" routerLinkActive #charts="routerLinkActive">
        <span class="menu-title">Charts</span>
        <i class="mdi mdi-chart-bar menu-icon"></i>
      </a>
    </li>
    <li class="nav-item" [ngClass]="{ 'active': tables.isActive }">
      <a class="nav-link" routerLink="tables/basic-table" routerLinkActive #tables="routerLinkActive">
        <span class="menu-title">Tables</span>
        <i class="mdi mdi-table-large menu-icon"></i>
      </a>
    </li>
    <li class="nav-item" [ngClass]="{ 'active': icons.isActive }">
      <a class="nav-link" routerLink="icons/mdi" routerLinkActive #icons="routerLinkActive">
        <span class="menu-title">Icons</span>
        <i class="mdi mdi-contacts menu-icon"></i>
      </a>
    </li> -->

  </ul>
</nav>
