import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from "../../context/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  api = environment.BASE_URL;

  constructor(
    private http: HttpClient,
    public jwtHelper: JwtHelperService,
    private userInfoService: LocalStorageService
  ) {
  }

  async login(request: any): Promise<any> {
    return await firstValueFrom(
      this.http.post<any>(
        `${this.api}/auth/sign-in`,
        request
      )
    );
  }

  async createAccount(request: any): Promise<any> {
    return await lastValueFrom(
      this.http.post(`${this.api}/auth/sign-up`, request, {})
    )
  }

  async getCompanyUsers(): Promise<any> {
    const companyId: string = this.userInfoService.getCompanyId();
    return firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/users`, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
    }))
  }

  createUser(request: any): Promise<any> {
    const currentRequest = {
      name: request.name,
      email: request.email,
      cpf: request.cpf,
      phone: request.phone,
    }
    return firstValueFrom(this.http.post(`${this.api}/users`, currentRequest, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
      params: {
        companyId: request.companyId,
        relationType: request.relationType,
      }
    }))
  }

  async getUserCompanies(): Promise<any> {
    console.log(this.userInfoService.getToken())
    const userId: string = this.userInfoService.getUserId();
    return firstValueFrom(this.http.get(`${this.api}/users/${userId}/companies`, {
      headers: {
        Authorization: `Bearer ${this.userInfoService.getToken()}`,
      },
    }))
  }

  public isUnauthorized() {
    const token = this.userInfoService.getToken();
    if (token == null) {
      return true
    } else {
      return this.jwtHelper.isTokenExpired(token);
    }
  }

  public isAuthenticated() {
    const token = this.userInfoService.getToken();
    if (token == null) {
      return false
    } else {
      return !this.jwtHelper.isTokenExpired(token);
    }
  }
}
