import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReceiverService } from 'src/app/services/receivers/receivers.service';
import { LocalStorageService } from "../../context/local-storage.service";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { SpinnerService } from 'src/app/navigation/spinner/spinner.service';
import { DeviceDetectorService } from "ngx-device-detector";
import { AlertManagerService } from 'src/app/context/alert-manager.service';
import { PersonTypeEnum } from 'src/app/enums/person-type.enum';
import { PhoneTypeEnum } from 'src/app/enums/phone-type.enum';

@Component({
  selector: 'app-receivers',
  templateUrl: './receivers.component.html',
  styleUrls: ['./receivers.component.scss']
})
export class ReceiversComponent implements OnInit {

  loadingReceivers: boolean = false;
  receivers: any[];
  receiverForm: FormGroup;
  menuItems: MenuItem[] = [];

  addressForm = new FormGroup({
    postalCode: new FormControl(),
    state: new FormControl(),
    city: new FormControl(),
    street: new FormControl(),
    neighborhood: new FormControl(),
    complement: new FormControl(),
    number: new FormControl(),
  });

  onlyInactives: boolean = false

  searchFilter: string = null;
  optionSearch: SelectItem[] = [];
  searchType: SelectItem = null;
  typePersonOptions: SelectItem[] = [];
  typePhoneOptions: SelectItem[] = [];
  selectedTypePerson: string = PersonTypeEnum.COMPANY;
  selectedTypePhone: string = PhoneTypeEnum.CELLPHONE;
  selectedEditDocument: string = null;
  regexDocumentPJ: RegExp = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;
  regexDocumentPF: RegExp = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
  regexCellPhone: RegExp = /\(\d{2}\)\s\d{5}\-\d{4}/;
  regexPhone: RegExp = /\(\d{2}\)\s\d{4}\-\d{4}/;

  public personTypeEnum = PersonTypeEnum;
  noMoreItems: boolean = false;

  constructor(
    private receiverService: ReceiverService,
    private formbuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private confirmationService: ConfirmationService,
    public appComponent: AppComponent,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private deviceService: DeviceDetectorService,
    private alertService: AlertManagerService
  ) {
    this.receiverForm = this.formbuilder.group({
      id: this.formbuilder.control(null),
      name: this.formbuilder.control('', [Validators.required]),
      cnpj: this.formbuilder.control(null),
      email: this.formbuilder.control(null),
      phone: this.formbuilder.control(null),
      address: this.formbuilder.control(null),
    })
  }

  async ngOnInit() {
    this.appComponent.showPlusButton = true;
    this.resetForm();
    this.setSearchOptions();
    this.setCustomerTypeOptions();
    await this.search(true);
  }

  isMobile(): boolean {
    return this.deviceService.isMobile()
  }

  resetForm() {
    this.receiverForm.reset();
    this.addressForm.reset();
  }

  setSearchOptions() {
    this.optionSearch = [
      { label: 'Sem Filtro', value: 'NONE' },
      { label: 'Nome', value: 'NAME' },
      { label: 'Telefone', value: 'PHONE' },
    ];
    this.searchType = this.optionSearch[0];
  }

  setCustomerTypeOptions() {
    this.typePersonOptions = [
      { label: PersonTypeEnum.COMPANY, value: PersonTypeEnum.COMPANY },
      { label: PersonTypeEnum.PERSON, value: PersonTypeEnum.PERSON },
    ];

    this.typePhoneOptions = [
      { label: PhoneTypeEnum.CELLPHONE, value: PhoneTypeEnum.CELLPHONE },
      { label: PhoneTypeEnum.PHONE, value: PhoneTypeEnum.PHONE },
    ];
  }

  maskTypePerson(): string {
    if (this.selectedTypePerson == PersonTypeEnum.COMPANY) {
      return "99.999.999/9999-99"
    } else {
      return "999.999.999-99"
    }
  }

  maskTypePhone() {
    if (this.selectedTypePhone == PhoneTypeEnum.CELLPHONE) {
      return "(99) 99999-9999"
    } else {
      return "(99) 9999-9999"
    }
  }

  placeholderTypePhone() {
    if (this.selectedTypePhone == PhoneTypeEnum.CELLPHONE) {
      return "Celular (Opcional)"
    } else {
      return "Telefone (Opcional)"
    }
  }

  placeholderTypePerson() {
    if (this.selectedTypePerson == PersonTypeEnum.COMPANY) {
      return "CNPJ (Opcional)"
    } else {
      return "CPF (Opcional)"
    }
  }

  async handleSearch(event: any) {
    console.log(event)
    if (!event) {
      await this.search(true);
    }
  }

  async search(refresh: boolean) {
    if (this.searchType == null || !this.searchType) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção!', detail: `Selecione o tipo de busca`, life: 5000 });
      return;
    }
    try {

      if (refresh) {
        this.receivers = [];
        this.noMoreItems = false;
      }

      this.loadingReceivers = true;
      let response = await this.receiverService.searchReceivers(!this.onlyInactives, this.searchFilter, this.searchType.value, this.receivers.length);

      if (response.length == 0) {
        this.alertService.showAlert('Nada encontrado');
        this.noMoreItems = true;
      }

      if (this.receivers.length == 0) {
        this.receivers = response;
      } else {
        this.receivers = this.receivers.concat(response);
      }

    } catch (e: any) {
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: `${e.error?.message}`, life: 5000 });
    } finally {
      this.loadingReceivers = false;
    }
  }

  // async getReceivers() {
  //   try {
  //     this.loadingReceivers = true;
  //     this.receivers = await this.receiverService.getReceivers(!this.onlyInactives);
  //   } catch (e: any) {
  //     this.messageService.add({ severity: 'error', summary: 'Erro!', detail: `${e.error?.message}`, life: 5000 });
  //   } finally {
  //     this.loadingReceivers = false;
  //   }
  // }

  async create() {
    const form = this.receiverForm.value;
    const address = this.addressForm.value;

    if (form.name == "") {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: `Nome do destinatário é obrigatório!`,
        life: 5000
      });
      return
    }

    let addressValue = null;
    if (address.street != null && address.number != null && address.neighborhood != null && address.city != null && address.state != null && address.postalCode != null) {
      addressValue = `${address.street}, ${address.number}, ${address.neighborhood} - ${address.city}/${address.state} - ${address.postalCode}`
    }

    const request = {
      companyId: this.localStorageService.getCompanyId(),
      name: form.name,
      cnpj: form.cnpj,
      email: form.email,
      phone: form.phone,
      address: addressValue,
    }
    try {
      this.spinnerService.show();
      this.appComponent.displayReceiver = false;
      await this.receiverService.createReceiver(request);
      this.spinnerService.hide();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso!',
        detail: `Dados atualizados com sucesso.`,
        life: 5000
      });
    } catch (e: any) {
      this.spinnerService.hide();
      this.messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: `${e.error?.message}`, life: 5000 });
    } finally {
      this.resetForm();
      await this.search(true);
    }
  }

  openEdit(row: any) {
    const regexDocument = new RegExp(this.regexDocumentPF);
    const regexPhone = new RegExp(this.regexCellPhone);
    if (regexDocument.test(row.cnpj)) {
      this.selectedTypePerson = PersonTypeEnum.PERSON;
    } else {
      this.selectedTypePerson = PersonTypeEnum.COMPANY;
    }
    if (regexPhone.test(row.phone)) {
      this.selectedTypePhone = PhoneTypeEnum.CELLPHONE;
    } else {
      this.selectedTypePhone = PhoneTypeEnum.PHONE;
    }
    setTimeout(() => {
      this.receiverForm.patchValue({
        id: row.id,
        name: row.name,
        cnpj: row.cnpj,
        email: row.email,
        phone: row.phone,
      });
    }, 0);
    this.appComponent.displayEditReceiver = true;
  }

  async edit(receiverForm: FormGroup) {
    const receiverId: number = receiverForm.value.id;
    const request = {
      name: receiverForm.value.name,
      cnpj: receiverForm.value.cnpj ? receiverForm.value.cnpj.replace(/\D/g, '') : null,
      email: receiverForm.value.email ? receiverForm.value.email : null,
      phone: receiverForm.value.phone ? receiverForm.value.phone.replace(/\D/g, '') : null,
    };
    // console.log(request);
    try {
      this.spinnerService.show();
      this.appComponent.displayEditReceiver = false;
      await this.receiverService.editReceiver(receiverId, request);
      this.spinnerService.hide();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso!',
        detail: `Dados atualizados com sucesso.`,
        life: 5000
      });
    } catch (e: any) {
      this.spinnerService.hide();
      this.messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: `${e.error?.message}`, life: 5000 });
    } finally {
      this.resetForm();
      await this.search(true);
    }
  }

  async delete(receiver: any) {
    try {
      this.spinnerService.show();
      await this.receiverService.deleteReceiver(receiver.id);
      this.spinnerService.hide();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso!',
        detail: 'Informação atualizada com sucesso.',
        life: 5000
      });
    } catch (e: any) {
      this.spinnerService.hide();
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: `${e.message}`, life: 5000 });
    } finally {
      this.resetForm();
      await this.search(true);
    }
  }

  getMenuItems(provider: any): MenuItem[] {

    if (provider.isActive) {
      this.menuItems = [
        {
          label: 'Editar', command: () => this.openEdit(provider),
        },
        {
          label: 'Inativar', command: () => this.updateActiveStatus(provider),
        },
        // {
        //   label: 'Excluir', command: () => this.confirm(provider),
        // },
      ];
    } else {
      this.menuItems = [
        {
          label: 'Editar', command: () => this.openEdit(provider),
        },
        {
          label: 'Ativar', command: () => this.updateActiveStatus(provider),
        },
        // {
        //   label: 'Excluir', command: () => this.confirm(provider),
        // },
      ];
    }

    return this.menuItems;
  }

  async updateActiveStatus(receiver: any) {
    try {
      this.spinnerService.show();
      this.receivers = await this.receiverService.updateActiveStatus(receiver.id, receiver.isActive);
      this.spinnerService.hide();
      this.alertService.showSuccess('Status de atividade atualizado com sucesso!');
      await this.search(true);
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    }
  }

  confirm(receiver: any) {
    this.confirmationService.confirm({
      header: `Destinatário: ${receiver.name}`,
      message: `Tem certeza que deseja excluir?`,
      acceptIcon: '',
      rejectIcon: '',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.delete(receiver);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
}
