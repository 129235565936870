import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersComponent } from './customers/customers.component';
import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../context/auth.guard';
import { ProductsComponent } from './products/products.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { SalesComponent } from './sales/sales.component';
import { ServicesComponent } from './services/services.component';
import { ReceiversComponent } from './receivers/receivers.component';
import { ProvidersComponent } from './providers/providers.component';
import { CompaniesComponent } from './companies/companies.component';
import { ProfileComponent } from './profile/profile.component';
import {UsersComponent} from "./users/users.component";
import { AdminCompaniesComponent } from './admin-companies/admin-companies.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'app/financial',
    pathMatch: 'prefix',
  },
  {
    path: 'financial',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sales',
    component: SalesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'expenses',
    component: ExpensesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'services',
    component: ServicesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'receivers',
    component: ReceiversComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'providers',
    component: ProvidersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'companies',
    component: CompaniesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-companies',
    component: AdminCompaniesComponent,
    canActivate: [AuthGuard]
  },

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
exports: [RouterModule]
})
export class CoreRoutingModule { }
