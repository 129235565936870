import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import { Token } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  api = environment.BASE_URL;
  zipcode = environment.VIACEP;

  constructor(private http: HttpClient) { }


  async getCompanyCnpj(cnpj: string): Promise<any> {
    return await lastValueFrom(this.http.get(`${this.api}/consult-cnpj/${cnpj}`));
  }

  async recoveryPassword(userEmail: string) : Promise<any> {
    return await lastValueFrom(
      this.http.put(`${this.api}/auth/users/${userEmail}/recovery-password`, {},));
  }


  async resetPassword(userId: string, password: string, token: string) : Promise<any> {
    return await lastValueFrom(
      this.http.put(`${this.api}/auth/users/${userId}/update-password`, {
        password: password
      }, {
        headers: {
          authorization: `Bearer ${token}`,
        }
      }));
  }

  async getZipCode(zipcode: string) : Promise<any> {
    return await lastValueFrom(
      this.http.get(`${this.zipcode}/${zipcode}/json`)
    )
  }


}
