<div class="d-flex align-items-center auth" style="overflow-y: hidden; height: 100vh">
  <div class="row w-100 mx-0">
    <div class="col-lg-4 mx-auto">
      <div class="auth-form-light text-left py-5 px-4 px-sm-5" style="border-radius: 14px 14px 0 0; ">
        <div class="brand-logo">
          <img src="assets/images/ebalance-logo.svg" alt="logo" height="100px" width="auto">
        </div>
        <form class="content-form" [formGroup]="registerForm">
          <div class="grid p-fluid" style="width: 100% !important;">
            <div class="col-12 md:col-12 lg:col-12">
            </div>

            <div *ngIf="value == 25" style="width: 100% !important;">

              <div class="col-12 md:col-12 lg:col-12 p-fluid">
                <h5>Dados de contato</h5>
              </div>

              <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="company">
                <input pInputText formControlName="name" type="text" placeholder="Nome da Empresa" />
                <app-form-message *ngIf="stepOne == false" [message]="'Campo obrigatório'">
                </app-form-message>
              </div>

              <div formGroupName="company" style="width:100%">
                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="data">
                  <input pInputText formControlName="email" type="email" placeholder="Email da Empresa" />
                  <app-form-message *ngIf="stepOne == false" [message]="'Campo obrigatório'">
                  </app-form-message>
                </div>

                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="data">
                  <p-inputMask placeholder="Telefone da empresa" formControlName="phone" mask="(99) 99999-9999"
                    [unmask]="true"></p-inputMask>
                  <app-form-message *ngIf="stepOne == false" [message]="'Campo obrigatório'">
                  </app-form-message>
                </div>
              </div>


            </div>

            <div *ngIf="value == 50" style="width: 100% !important;">
              <div class="col-12 md:col-12 lg:col-12 p-fluid">
                <h5>Dados da Empresa</h5>
              </div>
              <div formGroupName="company" style="width:100%">
                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="data">
                  <p-inputMask formControlName="cnpj" id="cnpj" placeholder="CNPJ" mask="99.999.999/9999-99"
                    (onComplete)="getInfoCompany()" [unmask]="true"></p-inputMask>
                  <app-form-message *ngIf="stepTwo == false" [message]="'Campo obrigatório'">
                  </app-form-message>
                </div>
                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="data">
                  <input pInputText formControlName="companyName" type="text" placeholder="Razão Social" />
                  <app-form-message *ngIf="stepTwo == false" [message]="'Campo obrigatório'">
                  </app-form-message>
                </div>
                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="data">
                  <input pInputText formControlName="municipalRegistration"
                    placeholder="Inscrição municipal (Opcional)" />
                </div>
                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="data">
                  <input pInputText formControlName="stateRegistration" placeholder="Inscrição estadual (Opcional)" />
                </div>

                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="cnae">
                  <input pInputText formControlName="description" placeholder="CNAE" />
                  <app-form-message *ngIf="stepTwo == false" [message]="'Campo obrigatório'">
                  </app-form-message>
                </div>

                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="cnae">
                  <input pInputText formControlName="code" placeholder="Código Cnae" />
                  <app-form-message *ngIf="stepTwo == false" [message]="'Campo obrigatório'">
                  </app-form-message>
                </div>
              </div>
            </div>

            <div *ngIf="value == 75" style="width: 100% !important;">
              <div class="col-12 md:col-12 lg:col-12 p-fluid">
                <h5>Endereço</h5>
              </div>
              <div formGroupName="company" style="width:100%">
                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="address">
                  <input pInputText formControlName="postalCode" placeholder="CEP" (blur)="handleCEP()" />
                  <app-form-message *ngIf="stepThree == false" [message]="'Campo obrigatório'">
                  </app-form-message>
                </div>
                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="address">
                  <input pInputText formControlName="state" placeholder="Estado" />
                  <app-form-message *ngIf="stepThree == false" [message]="'Campo obrigatório'">
                  </app-form-message>
                </div>
                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="address">
                  <input pInputText formControlName="city" placeholder="Cidade" />
                  <app-form-message *ngIf="stepThree == false" [message]="'Campo obrigatório'">
                  </app-form-message>
                </div>
                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="address">
                  <input pInputText formControlName="street" placeholder="Rua" />
                  <app-form-message *ngIf="stepThree == false" [message]="'Campo obrigatório'">
                  </app-form-message>
                </div>
                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="address">
                  <input pInputText formControlName="neighborhood" placeholder="Bairro" />
                  <app-form-message *ngIf="stepThree == false" [message]="'Campo obrigatório'">
                  </app-form-message>
                </div>
                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="address">
                  <input pInputText formControlName="complement" placeholder="Complemento" />
                </div>
                <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="address">
                  <input pInputText formControlName="number" placeholder="Número" />
                  <app-form-message *ngIf="stepThree == false" [message]="'Campo obrigatório'">
                  </app-form-message>
                </div>
              </div>
            </div>

            <div *ngIf="value == 100" style="width: 100% !important;">
              <div class="col-12 md:col-12 lg:col-12 p-fluid" style="width: 100% !important;">
                <h4>Dados pessoais</h4>
              </div>
              <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="user">
                <input id="name" pInputText formControlName="name" type="text" placeholder="Nome Completo" />
                <app-form-message *ngIf="stepFour == false" [message]="'Campo obrigatório'">
                </app-form-message>
              </div>
              <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="user">
                <p-inputMask id="cpf" placeholder="CPF" formControlName="cpf" mask="999.999.999-99"
                  [unmask]="true"></p-inputMask>
                <app-form-message *ngIf="stepFour == false" [message]="'Campo obrigatório'">
                </app-form-message>
              </div>
              <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="user">
                <p-inputMask placeholder="Celular" formControlName="phone" mask="(99) 99999-9999"
                  [unmask]="true"></p-inputMask>
                <app-form-message *ngIf="stepFour == false" [message]="'Campo obrigatório'">
                </app-form-message>
              </div>
              <div class="col-12 md:col-12 lg:col-12 p-fluid input" formGroupName="user">
                <input pInputText formControlName="email" type="email" placeholder="Email" />
                <app-form-message *ngIf="stepFour == false" [message]="'Campo obrigatório'">
                </app-form-message>
              </div>
              <div class="col-12 md:col-12 lg:col-12 p-fluid input">
                <input pInputText formControlName="password" type="password" placeholder="Senha" />
                <app-form-message *ngIf="stepFour == false" [message]="'Campo obrigatório'">
                </app-form-message>
              </div>
            </div>

            <div class="col-12 md:col-12 lg:col-12 p-fluid input">
              <div class="contentRegisterActions">
                <button class="button-outlined-login" type="button" (click)="backStep()">Voltar</button>
                <button class="buttonLogin" type="button" (click)="register()">{{value < 100 ? 'Avançar' : 'Cadastrar'
                    }}</button>
              </div>
            </div>

          </div>
        </form>
      </div>
      <p-progressBar class="customProgress" [value]="value" [showValue]="false"></p-progressBar>
    </div>
  </div>
</div>