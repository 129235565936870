import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UtilService } from '../../services/utils/util.service';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss']
})
export class RecoveryPasswordComponent implements OnInit {

  recoveryForm: FormGroup;
  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    private messageService: MessageService,
  ) {
    this.recoveryForm = this.fb.group({
      userEmail: this.fb.control(''),
    });
  }

  ngOnInit(): void {
  }

  async recovery() {
    const form = this.recoveryForm.getRawValue();
    try {
      await this.utilService.recoveryPassword(form.userEmail);
      this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: `Verifique seu E-mail`, life: 5000 });
      this.router.navigateByUrl('login');
    } catch (error: any) {
      this.messageService.add({ severity: 'error', summary: 'Houve um erro!', detail: `${error.message}`, life: 5000 });
    } finally {
      this.recoveryForm.reset();
    }
  }

  backToLogin() {
    this.router.navigateByUrl('login');
  }
}
