import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/context/local-storage.service';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  api = environment.BASE_URL;

  constructor(private http: HttpClient, public localStorageService: LocalStorageService) {
  }

  async cancelExpense(expenseId: number): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.put(`${this.api}/companies/${companyId}/expenses/${expenseId}/cancel`, {}, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async addCashOut(request: any): Promise<any> {
    return await firstValueFrom(this.http.post(`${this.api}/cash-out`, request, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async createExpense(request: any): Promise<any> {
    return firstValueFrom(this.http.post(`${this.api}/expenses`, request, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async editExpense(expenseId: number, request: any): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.put(`${this.api}/companies/${companyId}/expenses/${expenseId}`, request, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async deleteExpense(expenseId: number): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.delete(`${this.api}/companies/${companyId}/expenses/${expenseId}`, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
    }))
  }

  async getExpenses(isCancelled: boolean): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/expenses`, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
      params: {
        isCancelled: isCancelled
      }
    }))
  }

  async searchExpenses(isCancelled: boolean, searchData: string, searchType: string, offset: number): Promise<any> {
    const companyId: string = this.localStorageService.getCompanyId();
    return firstValueFrom(this.http.get(`${this.api}/companies/${companyId}/expenses`, {
      headers: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`,
      },
      params: {
        searchData: searchData,
        searchType: searchType,
        isCancelled: isCancelled,
        offset: offset,
      }
    }))
  }
}
