import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersComponent } from './customers/customers.component';
import { HomeComponent } from './home/home.component';
import { CoreRoutingModule } from './core-routing.module';
import { TableModule } from 'primeng/table';
import { CustomerService } from '../services/customer/customer.service';
import { ProductsComponent } from './products/products.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { SalesComponent } from './sales/sales.component';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ServicesComponent } from './services/services.component';
import { ReceiversComponent } from './receivers/receivers.component';
import { ProvidersComponent } from './providers/providers.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChartModule } from 'primeng/chart';
import { CompaniesComponent } from './companies/companies.component';
import { ProfileComponent } from './profile/profile.component';
import { RippleModule } from "primeng/ripple";
import { UsersComponent } from './users/users.component';
import { MenuModule } from 'primeng/menu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SharedModule } from '../shareds/shared.module';
import { ToastModule } from 'primeng/toast';
import { AdminCompaniesComponent } from './admin-companies/admin-companies.component';
import { PipesModule } from '../pipes/pipes.module';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import {FileUploadModule} from 'primeng/fileupload';
import {SkeletonModule} from 'primeng/skeleton';
import {InputTextareaModule} from "primeng/inputtextarea";
import {TabViewModule} from 'primeng/tabview';
import {DataViewModule} from 'primeng/dataview';
import {SelectButtonModule} from 'primeng/selectbutton';


@NgModule({
  declarations: [
    CustomersComponent,
    HomeComponent,
    ProductsComponent,
    ExpensesComponent,
    SalesComponent,
    ServicesComponent,
    ReceiversComponent,
    ProvidersComponent,
    CompaniesComponent,
    ProfileComponent,
    UsersComponent,
    AdminCompaniesComponent
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    TableModule,
    DialogModule,
    ReactiveFormsModule,
    InputMaskModule,
    InputTextModule,
    ButtonModule,
    InputNumberModule,
    CheckboxModule,
    DropdownModule,
    FormsModule,
    AutoCompleteModule,
    ChartModule,
    PipesModule,
    RippleModule,
    MenuModule,
    RadioButtonModule,
    SharedModule,
    ToastModule,
    ConfirmDialogModule,
    FileUploadModule,
    SkeletonModule,
    InputTextareaModule,
    TabViewModule,
    DataViewModule,
    SelectButtonModule,
  ],
  exports: [
    CustomersComponent,
    HomeComponent,
    ProductsComponent
  ],
  providers: [CustomerService, ConfirmationService]
})

export class CoreModule { }
