import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from './../../services/customer/customer.service';
import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { SpinnerService } from 'src/app/navigation/spinner/spinner.service';
import { DeviceDetectorService } from "ngx-device-detector";
import { CompaniesService } from "../../services/companies/companies.service";
import { LocalStorageService } from "../../context/local-storage.service";
import { AlertManagerService } from 'src/app/context/alert-manager.service';
import { PersonTypeEnum } from 'src/app/enums/person-type.enum';
import { PhoneTypeEnum } from 'src/app/enums/phone-type.enum';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  onlyInactives: boolean = false;

  loadingCustomers: boolean = false;
  customers: any[];
  // displayCustomer: boolean = false;
  menuItems: MenuItem[] = [];

  customerForm: FormGroup;

  addressForm: FormGroup;

  searchFilter: string = "";
  optionSearch: SelectItem[] = [];
  searchType: SelectItem = null;
  typePersonOptions: SelectItem[] = [];
  typePhoneOptions: SelectItem[] = [];
  selectedTypePerson: string = PersonTypeEnum.COMPANY;
  selectedTypePhone: string = PhoneTypeEnum.CELLPHONE;
  selectedEditDocument: string = null;
  regexDocumentPJ: RegExp = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;
  regexDocumentPF: RegExp = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
  regexCellPhone: RegExp = /\(\d{2}\)\s\d{5}\-\d{4}/;
  regexPhone: RegExp = /\(\d{2}\)\s\d{4}\-\d{4}/;
  noMoreItems: boolean = false;

  public personTypeEnum = PersonTypeEnum;

  constructor(
    private companiesService: CompaniesService,
    private customerService: CustomerService,
    private formbuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    public appComponent: AppComponent,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private deviceService: DeviceDetectorService,
    private localStorageService: LocalStorageService,
    private alertService: AlertManagerService
  ) {

  }

  async ngOnInit() {
    this.setForms();
    this.setCustomerTypeOptions();
    this.appComponent.showPlusButton = true;
    this.setSearchOptions();
    await this.search(true);
  }

  setCustomerTypeOptions() {
    this.typePersonOptions = [
      { label: PersonTypeEnum.COMPANY, value: PersonTypeEnum.COMPANY },
      { label: PersonTypeEnum.PERSON, value: PersonTypeEnum.PERSON },
    ];

    this.typePhoneOptions = [
      { label: PhoneTypeEnum.CELLPHONE, value: PhoneTypeEnum.CELLPHONE },
      { label: PhoneTypeEnum.PHONE, value: PhoneTypeEnum.PHONE },
    ];
  }

  setSearchOptions() {
    this.optionSearch = [
      { label: 'Sem Filtro', value: 'NONE' },
      { label: 'Nome', value: 'NAME' },
      { label: 'Telefone', value: 'PHONE' },
      { label: 'CNPJ', value: 'CNPJ' },
    ];
    this.searchType = this.optionSearch[0];
  }

  maskTypePerson(): string {
    if (this.selectedTypePerson == PersonTypeEnum.COMPANY) {
      return "99.999.999/9999-99"
    } else {
      return "999.999.999-99"
    }
  }

  maskTypePhone() {
    if (this.selectedTypePhone == PhoneTypeEnum.CELLPHONE) {
      return "(99) 99999-9999"
    } else {
      return "(99) 9999-9999"
    }
  }

  placeholderTypePhone() {
    if (this.selectedTypePhone == PhoneTypeEnum.CELLPHONE) {
      return "Celular (Opcional)"
    } else {
      return "Telefone (Opcional)"
    }
  }

  placeholderTypePerson() {
    if (this.selectedTypePerson == PersonTypeEnum.COMPANY) {
      return "CNPJ (Opcional)"
    } else {
      return "CPF (Opcional)"
    }
  }

  isAdmin(): boolean {
    return this.localStorageService.isAdminUser()
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  setForms() {
    this.customerForm = this.formbuilder.group({
      id: this.formbuilder.control(null),
      name: this.formbuilder.control('', [Validators.required]),
      cnpj: this.formbuilder.control(null),
      email: this.formbuilder.control(null),
      phone: this.formbuilder.control(null),
      notes: this.formbuilder.control(null),
      address: this.formbuilder.control(null),
    });

    this.addressForm = this.formbuilder.group({
      postalCode: new FormControl(),
      state: new FormControl(),
      city: new FormControl(),
      street: new FormControl(),
      neighborhood: new FormControl(),
      complement: new FormControl(),
      number: new FormControl(),
    });
    // console.log(this.customerForm)
  }

  resetForm() {
    this.customerForm.reset();
    this.addressForm.reset();
  }



  // async importFile(file: File) {
  //   try {
  //     this.spinnerService.show();
  //     await this.companiesService.importCustomers(file);
  //     this.spinnerService.hide();
  //     this.messageService.add({
  //       severity: 'success',
  //       summary: 'Sucesso!',
  //       detail: 'Dados importados com sucesso.',
  //       life: 5000
  //     });
  //   } catch (e: any) {
  //     this.spinnerService.hide();
  //     this.messageService.add({severity: 'error', summary: 'Erro!', detail: `${e.error?.message}`, life: 5000});
  //   } finally {
  //     this.getCustomers();
  //   }
  // }

  // async getCustomers() {
  //   try {
  //     this.customers = [];
  //     this.loadingCustomers = true;
  //     let response = await this.customerService.getCustomers(!this.onlyInactives, this.searchFilter, this.searchType?.value);
  //     if (response.length == 0) {
  //       this.alertService.showAlert('Nada encontrado');
  //     }
  //     this.customers = response;
  //   } catch (e: any) {
  //     this.messageService.add({ severity: 'error', summary: 'Erro!', detail: `${e.error?.message}`, life: 5000 });
  //   } finally {
  //     this.loadingCustomers = false;
  //   }
  // }

  async handleSearch(event: any) {
    if (!event) {
      await this.search(true);
    }
  }

  async clearSearchData(event: any) {
    if (event) {
      this.searchFilter = "";
    }
  }

  async search(refresh: boolean) {


    if (this.searchType == null || !this.searchType) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção!', detail: `Selecione o tipo de busca`, life: 5000 });
      return;
    }

    try {

      if (refresh) {
        this.customers = [];
        this.noMoreItems = false;
      }

      this.loadingCustomers = true;

      let response = await this.customerService.searchCustomers(!this.onlyInactives, this.searchFilter, this.searchType.value, this.customers.length);

      if (response.length == 0) {
        this.alertService.showAlert('Nada encontrado');
        this.noMoreItems = true;
      }

      if (this.customers.length == 0) {
        this.customers = response;
      } else {
        this.customers = this.customers.concat(response);
      }

    } catch (e: any) {
      this.alertService.showError(e.error?.message);
    } finally {
      this.loadingCustomers = false;
    }
  }

  async create() {
    const form = this.customerForm.value;
    const address = this.addressForm.value;

    if (form.name == "") {
      this.alertService.showAlert('Nome do cliente é obrigatório!');
      return
    }

    var addressValue = null
    if (address.street != null && address.number != null && address.neighborhood != null && address.city != null && address.state != null && address.postalCode != null) {
      addressValue = `${address.street}, ${address.number}, ${address.neighborhood} - ${address.city}/${address.state} - ${address.postalCode}`
    }

    const request = {
      companyId: this.localStorageService.getCompanyId(),
      cnpj: form.cnpj,
      name: form.name,
      email: form.email,
      phone: form.phone,
      notes: form.notes,
      address: addressValue,
    }

    try {
      this.appComponent.displayCustomer = false;
      this.spinnerService.show();
      await this.customerService.createCustomer(request);
      this.spinnerService.hide();
      this.alertService.showSuccess('Dados atualizados com sucesso!');
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    } finally {
      this.resetForm();
      await this.search(true);
    }
  }

  openEdit(row: any) {
    const regexDocument = new RegExp(this.regexDocumentPF);
    const regexPhone = new RegExp(this.regexCellPhone);
    if (regexDocument.test(row.cnpj)) {
      this.selectedTypePerson = PersonTypeEnum.PERSON;
    } else {
      this.selectedTypePerson = PersonTypeEnum.COMPANY;
    }
    if (regexPhone.test(row.phone)) {
      this.selectedTypePhone = PhoneTypeEnum.CELLPHONE;
    } else {
      this.selectedTypePhone = PhoneTypeEnum.PHONE;
    }
    setTimeout(() => {
      this.customerForm.patchValue({
        id: row.id,
        name: row.name,
        cnpj: row.cnpj,
        email: row.email,
        phone: row.phone,
        notes: row.notes,
      });
    }, 0);
    this.appComponent.displayEditCustomer = true;
  }

  async edit(form: FormGroup) {
    console.log(form.value);
    const customerId: number = form.value.id;
    const request = {
      cnpj: form.value.cnpj ? form.value.cnpj.replace(/\D/g, '') : null,
      name: form.value.name ? form.value.name : null,
      email: form.value.email ? form.value.email : null,
      phone: form.value.phone ? form.value.phone.replace(/\D/g, '') : null,
      notes: form.value.notes ? form.value.notes : null,
    };
    console.log(request);
    try {
      this.appComponent.displayEditCustomer = false;
      this.spinnerService.show();
      await this.customerService.editCustomer(customerId, request);
      this.spinnerService.hide();
      this.alertService.showSuccess('Dados atualizados com sucesso!');
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    } finally {
      this.resetForm();
      await this.search(true);
    }
  }

  async delete(customer: any) {
    try {
      this.spinnerService.show();
      await this.customerService.deleteCustomer(customer.id);
      this.spinnerService.hide();
      this.alertService.showSuccess('Informação atualizada com sucesso.');
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    } finally {
      this.resetForm();
      await this.search(true);
    }
  }

  getMenuItems(customer: any): MenuItem[] {

    if (customer.isActive) {
      this.menuItems = [
        {
          label: 'Editar', command: () => this.openEdit(customer),
        },
        {
          label: 'Inativar', command: () => this.updateActiveStatus(customer),
        },
        // {
        //   label: 'Excluir', command: () => this.confirm(customer),
        // },
      ];
    } else {
      this.menuItems = [
        {
          label: 'Editar', command: () => this.openEdit(customer),
        },
        {
          label: 'Ativar', command: () => this.updateActiveStatus(customer),
        },
        // {
        //   label: 'Excluir', command: () => this.confirm(customer),
        // },
      ];
    }

    return this.menuItems;
  }

  async updateActiveStatus(customer: any) {
    try {
      this.spinnerService.show();
      this.customers = await this.customerService.updateActiveStatus(customer.id, customer.isActive);
      this.spinnerService.hide();
      this.alertService.showSuccess('Status de atividade atualizado com sucesso!');
      await this.search(true);
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    }
  }

  confirm(customer: any) {
    this.confirmationService.confirm({
      header: `Cliente: ${customer.name}`,
      message: `Tem certeza que deseja excluir?`,
      acceptIcon: '',
      rejectIcon: '',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.delete(customer);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
}
