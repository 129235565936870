import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService, MenuItem, SelectItem} from 'primeng/api';
import {SaleService} from 'src/app/services/sales/sales.service';
import {LocalStorageService} from "../../context/local-storage.service";
import {CustomerService} from 'src/app/services/customer/customer.service';
import {ReceiverService} from 'src/app/services/receivers/receivers.service';
import {ServiceService} from '../../services/services/services.service';
import {AppComponent} from '../../app.component';
import {SpinnerService} from '../../navigation/spinner/spinner.service';
import {DeviceDetectorService} from "ngx-device-detector";
import {jsPDF} from "jspdf";
import autoTable from 'jspdf-autotable';
import {AlertManagerService} from 'src/app/context/alert-manager.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit, OnDestroy {

  onlyCancelled: boolean = false;

  loadingSales: boolean = false;

  sales: any[] = [];
  salesForm: FormGroup;
  paymentForm: FormGroup;
  customers: any[] = [];
  selectedCustomer: any = null;

  receivers: any[] = [];
  selectedReceiver: any = null;

  services: any[] = [];
  selectedService: any = null;
  selectedServices: any[] = [];
  menuItems: MenuItem[] = [];
  displayFilter: boolean = false;

  salesFilter: SelectItem[] = [];
  selectedSaleFilter: SelectItem = null;

  searchTypes: SelectItem[] = [];
  selectedSearchType: SelectItem = null;

  searchData: string = null;
  total: number = 0;

  displayPayment: boolean = false;

  sale?: any = null;
  noMoreItems: boolean = false;

  saleId: number = null;

  constructor(
    private saleService: SaleService,
    private formbuilder: FormBuilder,
    private alertService: AlertManagerService,
    private confirmationService: ConfirmationService,
    private customerService: CustomerService,
    private receiverService: ReceiverService,
    private serviceService: ServiceService,
    public appComponent: AppComponent,
    private spinnerService: SpinnerService,
    private deviceService: DeviceDetectorService,
    private localStorageService: LocalStorageService,
  ) {
  }

  async ngOnInit() {
    this.appComponent.showPlusButton = true;
    this.menuItems = [
      {label: 'New', icon: 'pi pi-fw pi-plus'},
      {label: 'Open', icon: 'pi pi-fw pi-download'},
      {label: 'Undo', icon: 'pi pi-fw pi-refresh'}
    ];
    this.setForms();
    this.setSearchOptions();
    await this.search(true);
  }

  async ngOnDestroy() {
    await this.search(true);
  }

  isAdmin(): boolean {
    return this.localStorageService.isAdminUser()
  }

  isMobile(): boolean {
    return this.deviceService.isMobile()
  }

  setSearchOptions() {
    this.searchTypes = [
      {label: 'Sem Filtro', value: 'NONE'},
      {label: 'Cliente', value: 'CUSTOMER'},
      {label: 'Destinatário', value: 'RECEIVER'},
      {label: 'Data de entrada', value: 'ENTRY_DATE'},
      {label: 'Data vencimento', value: 'FINISH_DATE'},
      {label: 'Observações', value: 'NOTES'},
      {label: 'Total', value: 'TOTAL'},
    ];
    this.selectedSearchType = this.searchTypes[0];

    this.salesFilter = [
      {label: 'Padrão', value: 'DEFAULT'},
      {label: 'Pagas', value: 'PAID'},
      {label: 'Não pagas', value: 'UNPAID'},
      {label: 'Canceladas', value: 'CANCELLED'},
      {label: 'Todas', value: 'ALL'},
    ]
  }

  async handleSearch(search: any) {
    if (!search) {
      await this.search(true);
    }
  }

  async search(refresh: boolean) {

    try {

      if (this.selectedSearchType == null || !this.selectedSearchType) {
        this.alertService.showAlert(`Selecione o tipo de busca`);
        return;
      }

      if (refresh) {
        this.sales = [];
        this.noMoreItems = false;
      }

      this.loadingSales = true;

      let response = await this.saleService.filter(this.onlyCancelled, this.searchData, this.selectedSearchType.value, this.sales.length);
      if (response.length == 0) {
        this.alertService.showAlert(`Nenhuma venda encontrada`);
      }

      if (response.length == 0) {
        this.alertService.showAlert('Nada encontrado');
        this.noMoreItems = true;
      }

      if (this.sales.length == 0) {
        this.sales = response;
      } else {
        this.sales = this.sales.concat(response);
      }

    } catch (e: any) {
      this.alertService.showError(e.error?.message);
    } finally {
      this.displayFilter = false;
      this.loadingSales = false;
    }
  }

  setForms() {
    this.salesForm = this.formbuilder.group({
      entryDate: this.formbuilder.control(null, [Validators.required]),
      type: this.formbuilder.control('PRODUCT', [Validators.required]),
      finishDate: this.formbuilder.control(null),
      notes: this.formbuilder.control(null),
      customer: this.formbuilder.control(this.selectedCustomer),
      receiver: this.formbuilder.control(this.selectedReceiver),
      services: this.formbuilder.control(this.selectedService),
      serviceQuantity: this.formbuilder.control(null),
      totalValue: this.formbuilder.control(0),
      discount: this.formbuilder.control(0),
      // isPaid: this.formbuilder.control(false, [Validators.required]),
      // paymentDate: this.formbuilder.control(null),
    });

    this.paymentForm = this.formbuilder.group({
      receiptDate: this.formbuilder.control(null),
      value: this.formbuilder.control(0),
    })
  }

  // async getSales() {
  //   try {
  //     this.sales = [];
  //     this.loadingSales = true;
  //     let response = await this.saleService.getSales(this.onlyCancelled);
  //     if (response.length == 0) {
  //       this.alertService.showAlert(`Nenhuma venda encontrada`);
  //     }
  //     this.sales = response;
  //   } catch (e: any) {
  //     this.alertService.showError(e.error?.message);
  //   } finally {
  //     this.loadingSales = false;
  //   }
  // }

  async create() {
    if (this.salesForm?.value?.entryDate?.length !== 10) {
      this.alertService.showAlert('Data de entrada com tamanho inválido.');
      return;
    }
    if (this.salesForm?.value?.finishDate?.length !== 10) {
      this.alertService.showAlert('Data de saída com tamanho inválido.');
      return;
    }
    let millisecondsPaymentDate = null;
    if (this.salesForm?.value?.isPaid == true) {
      let splitPaymentDate = this.salesForm.value.paymentDate?.split('/');
      millisecondsPaymentDate = new Date(splitPaymentDate[2], splitPaymentDate[1] - 1, splitPaymentDate[0]);
    }
    let splitEntryDate = this.salesForm.value.entryDate?.split('/');
    let splitFinishDate = this.salesForm.value.finishDate?.split('/');

    let millisecondsEntryDate = new Date(splitEntryDate[2], splitEntryDate[1] - 1, splitEntryDate[0]);
    let millisecondsFinishDate = new Date(splitFinishDate[2], splitFinishDate[1] - 1, splitFinishDate[0]);

    const request: any = {
      companyId: this.localStorageService.getCompanyId(),
      notes: this.salesForm.value.notes,
      customerId: this.selectedCustomer ? this.selectedCustomer.id : null,
      receiverId: this.selectedReceiver ? this.selectedReceiver.id : null,
      items: this.selectedServices,
      entryDate: millisecondsEntryDate.getTime(),
      finishDate: millisecondsFinishDate.getTime(),
      receiptImage: null,
      //isPaid: this.salesForm.value.isPaid,
      //paymentDate: (this.salesForm.value.isPaid == true) ? (millisecondsPaymentDate ? millisecondsPaymentDate.getTime() : null) : null,
      total: this.salesForm.value.totalValue - this.salesForm.value.discount,
      subtotal: this.salesForm.value.totalValue,
      discount: this.salesForm.value.discount,
      type: 'SERVICE',
    }

    if (this.selectedServices.length == 0 && this.salesForm.value.totalValue == 0) {
      this.alertService.showAlert('É necessário inserir um valor quando não existir serviço');
      return;
    }
    try {
      this.appComponent.displaySale = false;
      this.spinnerService.show();
      await this.saleService.createSale(request);
      this.spinnerService.hide();
      this.alertService.showSuccess(`Dados atualizados com sucesso`);
      await this.search(true);
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    } finally {
      this.setForms();
    }
  }

  async onSaveEdit() {

    let splitEntryDate = this.salesForm.value.entryDate?.split('/');
    let splitFinishDate = this.salesForm.value.finishDate?.split('/');

    let millisecondsEntryDate = new Date(splitEntryDate[2], splitEntryDate[1] - 1, splitEntryDate[0]);
    let millisecondsFinishDate = new Date(splitFinishDate[2], splitFinishDate[1] - 1, splitFinishDate[0]);

    const saleId = this.saleId;

    const request: any = {
      notes: this.salesForm.value.notes,
      customerId: this.selectedCustomer ? this.selectedCustomer.id : null,
      receiverId: this.selectedReceiver ? this.selectedReceiver.id : null,
      entryDate: millisecondsEntryDate.getTime(),
      finishDate: millisecondsFinishDate.getTime(),
      discount: this.salesForm.value.discount,
      // isPaid: this.salesForm.value.isPaid,
      // paymentDate: this.salesForm.value.paymentDate,
    }

    try {
      this.appComponent.displayEditSale = false;
      this.spinnerService.show();
      await this.saleService.updateSale(saleId, request);
      this.spinnerService.hide();
      this.alertService.showSuccess(`Dados atualizados com sucesso`);
      await this.search(true);
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    } finally {
      // this.setForms();
    }
  }

  openEdit(sale: any) {
    console.log(sale);
    this.selectedCustomer = sale.customer;
    this.selectedReceiver = sale.receiver;
    this.saleId = sale.id;
    this.salesForm.patchValue({
      entryDate: sale.entryDate,
      finishDate: sale.finishDate,
      notes: sale.notes,
      customer: sale.customer ? this.selectedCustomer : null,
      receiver: sale.receiver ? this.selectedReceiver : null,
      // isPaid: sale.isPaid,
      // paymentDate: sale.paymentDate ? sale.paymentDate : null,
      total: sale.totalValue - sale.discount,
      totalValue: sale.subtotal,
      subtotal: sale.subtotal,
      discount: sale.discount,
    })
    this.appComponent.displayEditSale = true;
  }

  changeCustomer(event: any) {
    this.selectedCustomer = event;
  }

  changeReceiver(event: any) {
    this.selectedReceiver = event;
  }

  async filterCustomer(event: any) {
    this.selectedCustomer = null;
    if (event.query.length == 0) {
      return;
    }
    this.customers = await this.customerService.searchCustomers(true, event.query, "NAME", 0, 5);
  }

  async filterReceiver(event: any) {
    this.selectedReceiver = null;
    if (event.query.length == 0) {
      return;
    }
    this.receivers = await this.receiverService.searchReceivers(true, event.query, "NAME", 0, 5);
  }

  async filterService(event: any) {
    this.selectedService = null;
    if (event.query.length == 0) {
      return;
    }
    this.services = await this.serviceService.filter(true, event.query, "NAME", 0, 5);
  }

  changeService(event: any) {
    this.selectedService = event;
  }

  includeService() {
    if (this.selectedService == null) {
      this.alertService.showAlert(`Necessário selecionar um serviço!`);
      return;
    }

    if (this.salesForm.value.serviceQuantity <= 0) {
      this.alertService.showAlert(`Necessário informar a quantidade!`);
      return;
    }

    const service = {
      type: this.selectedService.type,
      id: this.selectedService.id,
      name: this.selectedService.name,
      price: this.selectedService.price,
      quantity: this.salesForm.value.serviceQuantity,
      total: this.selectedService.price * this.salesForm.value.serviceQuantity,
    }

    this.selectedServices.push(service);
    this.selectedService = null;
    this.salesForm.value.serviceQuantity = 0;

    this.salesForm.get('services').reset();
    this.salesForm.get('serviceQuantity').reset();
    this.calculateService();
  }

  calculateService() {
    let total = 0
    this.selectedServices.forEach(item => {
      total = item.total + total
    })
    this.salesForm.patchValue({
      totalValue: total
    });
  }

  async cancelSale(sale: any) {
    try {
      this.spinnerService.show();
      await this.saleService.cancelSale(sale.id);
      this.spinnerService.hide();
      this.alertService.showSuccess('Informação atualizada com sucesso.');
      await this.search(true);
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    }
  }

  async removeSale(sale: any) {
    try {
      this.spinnerService.show();
      await this.saleService.removeSale(sale.id);
      this.spinnerService.hide();
      this.alertService.showSuccess('Informação atualizada com sucesso.');
      await this.search(true);
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    }
  }

  async checkPay() {
    const companyId = this.localStorageService.getCompanyId()
    const receiptDate = this.paymentForm.get(`receiptDate`)?.value;
    const value = this.paymentForm.get(`value`)?.value;
    if (!receiptDate) {
      this.alertService.showAlert(`Informe a data do Pagamento`);
      return;
    }

    let millisecondsPaymentDate = null;
    let partsPaymentDate = receiptDate.split('/');
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    const seconds = new Date().getSeconds();
    const milliseconds = new Date().getMilliseconds();
    millisecondsPaymentDate = new Date(partsPaymentDate[2], partsPaymentDate[1] - 1, partsPaymentDate[0], hours, minutes, seconds, milliseconds).getTime();

    let request: any = {
      receiptDate: millisecondsPaymentDate,
      value: value,
      saleId: this.sale.id,
      companyId: companyId,
      receiptImage: null,
    }

    try {
      this.displayPayment = false;
      this.spinnerService.show();
      await this.saleService.addCashIn(request);
      this.spinnerService.hide();
      this.alertService.showSuccess('Informação atualizada com sucesso.');
      await this.search(true);
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    }
  }

  openAddCashIn(sale: any) {
    this.paymentForm.reset();
    this.paymentForm.patchValue({
      value: 0,
    })
    this.sale = sale
    this.displayPayment = true;
  }

  getMenuItems(sale: any): MenuItem[] {
    this.menuItems = [];
    this.menuItems.push({label: 'Emitir Recibo', command: (e) => this.generateReceiptPDF(sale)});

    if (sale.isLate) {
      if (sale.customer.phone != null)
        this.menuItems.push({label: 'Enviar cobrança', command: (e) => this.sendWhatsappMessage(sale)});
    }

    if (!sale.isPaid) {
      this.menuItems.push({label: 'Editar', command: (e) => this.openEdit(sale)});
      this.menuItems.push({label: 'Incluir Recebimento', command: (e) => this.openAddCashIn(sale)});
    }
    this.menuItems.push({label: 'Cancelar venda', command: (e) => this.confirmCancel(sale)});
    if (this.localStorageService.isAdminUser()) {
      this.menuItems.push({label: 'Excluir venda', command: (e) => this.confirmDelete(sale)});
    }

    return this.menuItems;
  }

  async openFilter() {
    this.displayFilter = true;
  }

  async confirmDelete(sale: any) {
    this.confirmationService.confirm({
      header: `Excluir venda: ${sale.id}`,
      message: `Tem certeza que deseja excluir?`,
      acceptIcon: '',
      rejectIcon: '',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: async () => {
        await this.removeSale(sale);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  async confirmCancel(sale: any) {
    this.confirmationService.confirm({
      header: `Cancelar venda: ${sale.id}`,
      message: `Tem certeza que deseja cancelar?`,
      acceptIcon: '',
      rejectIcon: '',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: async () => {
        await this.cancelSale(sale);
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  removeService(service: any) {
    let requestIndex = this.selectedServices.indexOf(service);
    this.selectedServices.splice(requestIndex, 1);
    this.calculateService();
  }

  async generateReceiptPDF(sale: any) {
    let result: any;
    try {
      this.spinnerService.show();
      result = await this.saleService.getById(sale.id);
    } catch (e: any) {
      this.alertService.showError(e.error?.message);
      return;
    } finally {
      this.spinnerService.hide();
    }

    const company = this.localStorageService.getCompany();
    const user = this.localStorageService.getUser();

    const doc = new jsPDF();

    let img = new Image()
    img.src = 'assets/images/logo-pdf.png'
    doc.addImage(img, "png", 175, 20, 15, 15);

    doc.setFontSize(14);
    doc.setFont(undefined, 'bold');
    doc.text(`${company.data.companyName.toUpperCase()} - ${company.data.cnpj}`, 20, 50, null);

    doc.setFontSize(10);
    doc.setFont(undefined, 'normal');
    doc.text(`${company.address.street}, ${company.address.number}, ${company.address.neighborhood}`, 20, 60, null);
    doc.text(`${company.address.city}/${company.address.state} - ${company.address.postalCode}`, 20, 70, null);
    doc.text(`${company.data.email}`, 190, 60, null, "right");
    doc.text(`${company.data.phone}`, 190, 70, null, "right");

    doc.setDrawColor("#cecece");
    doc.line(20, 80, 190, 80);

    doc.setFontSize(12);
    doc.setFont(undefined, 'bold');
    doc.text(`Cliente:`, 20, 90, null);
    doc.setFontSize(10);
    doc.setFont(undefined, 'normal');
    doc.text(sale.customer?.name?.toUpperCase() ? `${sale.customer?.name?.toUpperCase()}` : "Não Informado", 20, 100, null);

    doc.setFontSize(12);
    doc.setFont(undefined, 'bold');
    doc.text(`Destinatário:`, 20, 110, null);
    doc.setFontSize(10);
    doc.setFont(undefined, 'normal');
    doc.text(sale.receiver?.name?.toUpperCase() ? `${sale.receiver?.name?.toUpperCase()}` : "Não Informado", 20, 120, null);

    doc.setFont(undefined, 'bold');
    doc.text(`Data:`, 170, 90, null, "right");
    doc.setFont(undefined, 'normal');
    doc.text(` ${new Date().toLocaleDateString('pt-BR')}`, 190, 90, null, "right");

    let items = [];
    for (let item of result.items) {
      items.push([
        item.itemName,
        item.itemQuantity,
        new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.itemPrice),
        new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.total),
      ]);
    }

    autoTable(doc, {
      headStyles: {fillColor: "#004054"},
      startY: 130,
      head: [["Serviço", "Quantidade", "Preço", "Total",]],
      body: items,
      margin: {left: 20, right: 20},
    });

    let last = (doc as any).lastAutoTable.finalY

    doc.setFontSize(10);
    doc.text(`Subtotal: ${new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(result.subtotal)}`, 20, last + 10, null);
    doc.text(`Desconto: ${new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(result.discount)}`, 20, last + 20, null);
    doc.text(`Total: ${new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(result.total)}`, 20, last + 30, null);
    doc.setFontSize(12);
    doc.setFont(undefined, 'bold');
    doc.text('Outras Informações', 20, last + 40, null);
    doc.setFontSize(10);
    doc.setFont(undefined, 'normal');
    doc.text(result.notes ? `${result.notes}` : "Nào Informado", 20, last + 50, null);

    doc.setFontSize(12);
    doc.setFont(undefined, 'bold');
    doc.text('Recebimentos', 20, last + 60, null);

    let cashIns = [];
    for (let item of result.cashIns) {
      cashIns.push([
        item.receiptDate,
        new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.value)
        // item.value,
        // new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.itemPrice),
        // new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.total),
      ]);
    }
    autoTable(doc, {
      headStyles: {fillColor: "#004054"},
      startY: last + 70,
      head: [["Data de Recebimento", "Valor"]],
      body: cashIns,
      margin: {left: 20, right: 20},
    });
    doc.save("recibo.pdf");
  }

  sendWhatsappMessage(sale: any) {
    let text = `Prezado (a) ${sale.customer.name},%0a%0a` +
      `Estamos passando para lembrá-lo(a) sobre o pagamento pendente em relação ao serviço solicitado em ${sale.entryDate} com vencimento em ${sale.finishDate} no valor total de ${sale.total}.` +
      '%0a%0a' +
      `O valor ainda pendente é de ${sale.toReceive}. Após efetuar o pagamento, por gentileza nos enviar o comprovante para que possamos atualizar nossos registros.` +
      '%0a%0a' +
      'Agradecemos a atenção.' +
      '%0a%0a' +
      'Atenciosamente,' +
      '%0a%0a' +
      `${this.localStorageService.getUser().name}` +
      '%0a' +
      `${this.localStorageService.getCompany().name}`;

    // 'Prezado [Nome do Cliente],\n' +
    // '\n' +
    // 'Espero que esta mensagem o encontre bem. Estamos entrando em contato para lembrá-lo sobre o pagamento pendente da fatura número [Número da Fatura], com vencimento em [Data de Vencimento].\n' +
    // '\n' +
    // 'É fundamental para nós manter uma relação transparente e eficiente com nossos clientes, e agradecemos muito pela sua parceria. No entanto, observamos que até o momento não recebemos o pagamento referente a esta fatura.\n' +
    // '\n' +
    // 'Por favor, verifique a situação e, se necessário, regularize o pagamento o mais breve possível. Estamos à disposição para ajudar em qualquer dúvida ou para discutir opções de pagamento.\n' +
    // '\n' +
    // 'Detalhes da Fatura:\n' +
    // '\n' +
    // 'Número da Fatura: [Número da Fatura]\n' +
    // 'Valor devido: [Valor em Reais]\n' +
    // 'Data de Vencimento: [Data de Vencimento]\n' +
    // 'Dados para Pagamento:\n' +
    // '[Inserir dados bancários ou informações relevantes]\n' +
    // '\n' +
    // 'Agradecemos pela sua atenção a este assunto e esperamos resolver essa questão de maneira colaborativa. Caso o pagamento já tenha sido efetuado, pedimos desculpas pelo lembrete.\n' +
    // '\n' +
    // 'Ficamos à disposição para esclarecer qualquer dúvida ou fornecer informações adicionais. Desde já, agradecemos pela sua compreensão e colaboração.\n' +
    // '\n' +
    // 'Atenciosamente,\n' +
    // '[Seu Nome]\n' +
    // '[Seu Cargo]\n' +
    // '[Seu Contato]'
    // let url = `https://wa.me/+55${sale.customer.phone}?text=${text}`
    let url = `https://wa.me/+5584998182982?text=${text}`
    window.open(url)
  }

  close() {
    this.salesForm.reset();
    this.paymentForm.reset();
    this.salesForm.patchValue({
      totalValue: 0,
      discount: 0,
    })
    // this.selectedCustomer = null;
    // this.selectedReceiver = null;
    this.selectedServices = [];
  }
}
