import { Component, OnInit } from '@angular/core';
import { UserService } from "../../services/user/user.service";
import { LocalStorageService } from "../../context/local-storage.service";
import { FormBuilder, FormGroup, Validator, Validators } from "@angular/forms";
import { MessageService, SelectItem } from "primeng/api";
import { UserTypeEnum } from "../../enums/user-type.enum";
import { AppComponent } from 'src/app/app.component';
import { DeviceDetectorService } from "ngx-device-detector";
import {SpinnerService} from "../../navigation/spinner/spinner.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  loadingUsers: boolean = false;
  users: any[] = [];

  selectedCompany: any = null;
  displayUsers: boolean = false;
  usersForm: FormGroup;
  optionTypeCompanies: SelectItem[] = [];
  selectedOptionCompany: any = null;

  constructor(
    private userService: UserService,
    private userInfoService: LocalStorageService,
    private fb: FormBuilder,
    private messageService: MessageService,
    public appComponent: AppComponent,
    private deviceService: DeviceDetectorService,
    private spinnerService: SpinnerService,
  ) {
  }

  async ngOnInit() {
    this.appComponent.showPlusButton = true;
    this.optionsTypeUser();
    this.setForms();
    this.getCompany();
    await this.getUsers();
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  optionsTypeUser() {
    this.optionTypeCompanies = [
      { label: "Proprietário", value: UserTypeEnum.OWNER },
      { label: "Administrador", value: UserTypeEnum.ADMIN },
      { label: "Empregado", value: UserTypeEnum.EMPLOYEE },
      { label: "Contador", value: UserTypeEnum.COUNTER },
    ]
  }

  setForms() {
    this.usersForm = this.fb.group({
      name: this.fb.control(null, [Validators.required]),
      email: this.fb.control(null, [Validators.required]),
      cpf: this.fb.control(null, [Validators.required]),
      phone: this.fb.control(null, [Validators.required]),
      companyId: this.fb.control(this.userInfoService.getCompany().id, [Validators.required]),
      relationType: this.fb.control(null, [Validators.required]),
    });
  }

  openCreateUser() {
    this.displayUsers = true;
  }

  getCompany() {
    this.selectedCompany = this.userInfoService.getCompany().data.companyName;
  }

  async getUsers() {
    try {
      this.loadingUsers = true;
      this.users = await this.userService.getCompanyUsers();
    } catch (e: any) {
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: `${e.error?.message}`, life: 5000 });
    } finally {
      this.loadingUsers = false;
    }
  }

  async create() {
    const form = this.usersForm.value;
    try {
      this.displayUsers = false;
      this.spinnerService.show();
      await this.userService.createUser(form);
      this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: `Dados atualizados com sucesso`, life: 5000 });
    } catch (e: any) {
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: `${e.error?.message}`, life: 5000 });
    } finally {
      this.displayUsers = false;
      this.setForms();
      this.spinnerService.hide();
    }
  }
}
