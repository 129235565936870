import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import jwt_decode from "jwt-decode";
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  tokenUser = 'ebalance-token';
  userInfo = 'ebalance-user';
  companyInfo = 'ebalance-company';
  companyPicture = 'ebalance-company-picture';
  iosWarning = 'ebalance-ios-warning';
  // tokenEncrypted: string = null;
  public selectedCompany$: Subject<any> = new Subject()

  constructor() {
  }

  public setUser(signInResponse: any) {
    let user = JSON.stringify(signInResponse.user);
    let tokenJWT = signInResponse.token;
    let encrypted = crypto.AES.encrypt(tokenJWT, this.tokenUser).toString();
    localStorage.setItem(this.userInfo, user);
    localStorage.setItem(this.tokenUser, encrypted);
  }

  public getUserInfo() {
    let decryptedToken = this.getToken()
    return {
      user: JSON.parse(localStorage.getItem(this.userInfo)),
      token: decryptedToken,
    }
  }

  public getUser() {
    return JSON.parse(localStorage.getItem(this.userInfo));
  }

  public getUserId(): string {
    return this.getUser().id;
  }

  public getToken(): string {
    let token = localStorage.getItem(this.tokenUser);
    if (!token) {
      return null;
    }
    return crypto.AES.decrypt(token, this.tokenUser).toString(crypto.enc.Utf8);
  }

  public isAdminUser(): boolean {
    const token = this.getToken();
    const decode: any = jwt_decode(token);
    return decode != null && decode?.type == "SYSTEM_ADMIN"
  }

  public setCompany(company: any) {
    return localStorage.setItem(this.companyInfo, JSON.stringify(company))
  }

  public setIOSWarning(ios: any) {
    return localStorage.setItem(this.iosWarning, JSON.stringify(ios))
  }

  public getCompany() {
    return JSON.parse(localStorage.getItem(this.companyInfo));
  }

  public getIOSWarning() {
    return JSON.parse(localStorage.getItem(this.iosWarning));
  }

  public getCompanyId(): string {
    return this.getCompany().id;
  }

  public observeCompany() {
    return this.selectedCompany$.asObservable();
  }

  public changeCompany(event: any) {
    this.selectedCompany$.next(event)
  }

  public setCompanyPicture(url: string) {
    return localStorage.setItem(this.companyPicture, url);
  }

  public getCompanyPicture() {
    return localStorage.getItem(this.companyPicture)
  }
}
