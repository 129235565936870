import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServiceService } from './../../services/services/services.service';
import { LocalStorageService } from "../../context/local-storage.service";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { AppComponent } from './../../app.component';
import { SpinnerService } from 'src/app/navigation/spinner/spinner.service';
import { DeviceDetectorService } from "ngx-device-detector";
import { AlertManagerService } from 'src/app/context/alert-manager.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  onlyInactives: boolean = false

  loadingServices: boolean = false;
  services: any[];

  serviceForm: FormGroup;

  menuItems: MenuItem[] = [];

  searchFilter: string = null;
  optionSearch: SelectItem[] = [];
  searchType: SelectItem = null;
  noMoreItems: boolean = false;

  constructor(
    private serviceService: ServiceService,
    private formbuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private confirmationService: ConfirmationService,
    private spinnerService: SpinnerService,
    public appComponent: AppComponent,
    private deviceService: DeviceDetectorService,
    private alertService: AlertManagerService
  ) {

  }

  async ngOnInit() {
    this.showAddButton();
    this.setSearchOptions();
    this.setForms();
    await this.search(true);
  }

  isMobile(): boolean {
    return this.deviceService.isMobile()
  }

  showAddButton() {
    this.appComponent.showPlusButton = true;
  }

  setSearchOptions() {
    this.optionSearch = [
      { label: 'Sem Filtro', value: 'NONE' },
      { label: 'Nome', value: 'NAME' }
    ];
    this.searchType = this.optionSearch[0];
  }

  async search(refresh: boolean) {

    if (this.searchType == null || !this.searchType) {
      this.alertService.showAlert('Selecione o tipo de busca');
      return;
    }

    try {

      if (refresh) {
        this.services = [];
        this.noMoreItems = false;
      }

      this.loadingServices = true;

      let response = await this.serviceService.filter(!this.onlyInactives, this.searchFilter, this.searchType.value, this.services.length);
      console.log(response);

      if (response.length == 0) {
        this.alertService.showAlert('Nada encontrado');
        this.noMoreItems = true;
      }

      if (this.services.length == 0) {
        this.services = response;
      } else {
        this.services = this.services.concat(response);
      }

    } catch (e: any) {
      this.alertService.showError(e.error?.message);
    } finally {
      this.loadingServices = false;
    }
  }

  async handleSearch(event: any) {
    if (!event) {
      await this.search(true);
    }
  }

  setForms() {
    this.serviceForm = this.formbuilder.group({
      id: this.formbuilder.control(null),
      name: this.formbuilder.control(null, [Validators.required]),
      price: this.formbuilder.control(null, [Validators.required]),
    })
  }

  // async getServices() {
  //   try {
  //     this.services = [];
  //     this.loadingServices = true;
  //     this.services = await this.serviceService.getServices(!this.onlyInactives);
  //   } catch (e: any) {
  //     this.alertService.showError(e.error?.message);
  //   } finally {
  //      this.loadingServices = false;
  //   }
  // }

  async updateActiveStatus(service: any) {
    try {
      this.spinnerService.show();
      await this.serviceService.updateActiveStatus(service.id, service.isActive);
      this.spinnerService.hide();
      this.alertService.showSuccess('Status de atividade atualizado com sucesso!');
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    } finally {
      this.setForms();
      await this.search(true);
    }
  }

  async delete(service: any) {
    try {
      this.spinnerService.show();
      await this.serviceService.delete(service.id);
      this.spinnerService.hide();
      this.alertService.showSuccess('Serviço excluído com sucesso.');
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    } finally {
      this.setForms();
      await this.search(true);
    }
  }

  async openEdit(service: any) {
    this.serviceForm.patchValue({
      id: service.id,
      name: service.name,
      price: service.price,
    });
    this.appComponent.displayEditService = true;
  }

  async edit(form: FormGroup) {
    const serviceId: number = form.value.id;
    const request = {
      name: form.value.name,
      price: form.value.price,
    };
    console.log(request);

    try {
      this.appComponent.displayEditService = false;
      this.spinnerService.show();
      await this.serviceService.editService(serviceId, request);
      this.spinnerService.hide();
      this.alertService.showSuccess(`Serviço atualizado com sucesso!`);
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    } finally {
      this.setForms();
      await this.search(true);
    }
  }

  async create() {
    const form = this.serviceForm.value;

    const request = {
      name: form.name,
      price: form.price,
      companyId: this.localStorageService.getCompany().id,
    }

    try {
      this.appComponent.displayService = false;
      this.spinnerService.show();
      await this.serviceService.createServices(request);
      this.spinnerService.hide();
      this.alertService.showSuccess(`Serviço criado com sucesso`);
    } catch (e: any) {
      this.spinnerService.hide();
      this.alertService.showError(e.error?.message);
    } finally {
      this.setForms();
      await this.search(true);
    }
  }

  getMenuItems(provider: any): MenuItem[] {

    if (provider.isActive) {
      this.menuItems = [
        {
          label: 'Editar', command: () => this.openEdit(provider),
        },
        {
          label: 'Inativar', command: () => this.updateActiveStatus(provider),
        },
        // {
        //   label: 'Excluir', command: () => this.confirm(provider),
        // },
      ];
    } else {
      this.menuItems = [
        {
          label: 'Editar', command: () => this.openEdit(provider),
        },
        {
          label: 'Ativar', command: () => this.updateActiveStatus(provider),
        },
        // {
        //   label: 'Excluir', command: () => this.confirm(provider),
        // },
      ];
    }

    return this.menuItems;
  }


  confirm(service: any) {
    this.confirmationService.confirm({
        header: `Serviço: ${service.name}`,
        message: `Tem certeza que deseja excluir?`,
        acceptIcon: '',
        rejectIcon: '',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
            this.delete(service);
        },
        reject: () => {
            this.confirmationService.close();
        }
    });
  }
}
