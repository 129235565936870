<div class="container-scroller">
  <app-navbar *ngIf="showNavbar" [selectedCompany]="selectedCompany"></app-navbar>
  <div class="container-fluid page-body-wrapper">
    <app-sidebar *ngIf="showSidebar"></app-sidebar>
    <div class="main-panel" [ngClass]="{ 'main-panel-only' : !showSidebar }">
      <div class="content-wrapper">
        <div class="h-100">
          <router-outlet></router-outlet>
        </div>
      </div>
      <app-footer *ngIf="showFooter"></app-footer>
      <!-- partial -->
    </div>
    <!-- main-panel ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>

<div class="float-action" *ngIf="appComponent?.showPlusButton">
  <button pButton pRipple type="button" icon="pi pi-plus" class="buttonRounded btn-lg"
    (click)="openDynamicModal()"></button>
</div>

<div class="ebalanceFeedback" (click)="openSupport()">
  <h5>Suporte</h5>
</div>


<p-dialog header="Solicitar Suporte" [(visible)]="displaysupport" [modal]="true" [style]="{width: '80vw'}"
  [draggable]="false" appendTo="body" [baseZIndex]="9999" [autoZIndex]="true" [resizable]="false">
  <div class="grid contentSupport">
    <div class="xl:col-12 lg:col-12 md:col-12 sm:col-12 p-fluid">
      <p-dropdown [options]="optionType" [(ngModel)]="selectedType" placeholder="Selecione o tipo de suporte"
        (onChange)="handleType($event.value)" optionLabel="label" appendTo="body"
        style="width: 100%; max-width: 100%;"></p-dropdown>
    </div>
    <div class="xl:col-12 lg:col-12 md:col-12 sm:col-12 p-fluid">
      <textarea rows="10" placeholder="Descreva.." pInputTextarea [(ngModel)]="contentMessage"
        style="width: 100%; max-width: 100%;"></textarea>
    </div>

    <div class="xl:col-12 lg:col-12 md:col-12 sm:col-12 p-fluid" [hidden]="selectedType == null">
      <button class="button" type="button" (click)="sendSupport()">Enviar Email</button>
    </div>
  </div>
</p-dialog>