import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ChartsModule, ThemeService } from 'ng2-charts';

import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './components/core.module';
import { MainComponent } from './navigation/main/main.component';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { NavbarComponent } from './navigation/navbar/navbar.component';
import { SidebarComponent } from './navigation/sidebar/sidebar.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { SpinnerComponent } from './navigation/spinner/spinner.component';
import { DirectivesModule } from './directives/directives.module';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { HttpClientModule } from '@angular/common/http';
import { UserService } from './services/user/user.service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AuthGuard } from './context/auth.guard';
import {DropdownModule} from 'primeng/dropdown';
import localePt from '@angular/common/locales/pt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RecoveryPasswordComponent } from './pages/recovery-password/recovery-password.component';
import { ConfirmPasswordComponent } from './pages/confirm-password/confirm-password.component';
import { AddressComponent } from './shareds/address/address.component';
import {ToastModule} from 'primeng/toast';
import { MessageService } from 'primeng/api';
import {environment} from "../environments/environment";
import { SharedModule } from './shareds/shared.module';
import { SpinnerModule } from './navigation/spinner/spinner.module';
import {ProgressBarModule} from 'primeng/progressbar';
import { DynamicDialogModule, DialogService } from 'primeng/dynamicdialog';
import {DialogModule} from 'primeng/dialog';
import {CheckboxModule} from 'primeng/checkbox';
import {RippleModule} from "primeng/ripple";
import { InputTextareaModule } from 'primeng/inputtextarea';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    MainComponent,
    LoginComponent,
    RegisterComponent,
    RecoveryPasswordComponent,
    ConfirmPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    // ChartsModule,
    ToastModule,
    CoreModule,
    DirectivesModule,
    ReactiveFormsModule,
    InputMaskModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    SharedModule,
    SpinnerModule,
    ProgressBarModule,
    DynamicDialogModule,
    DialogModule,
    CheckboxModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    RippleModule,
    InputTextareaModule
  ],
  providers: [
    // ThemeService,
    MessageService,
    UserService,
    JwtHelperService,
    DialogService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    {
      provide: LOCALE_ID,
      useValue: "pt-BR"
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
