import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/context/local-storage.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { UserService } from 'src/app/services/user/user.service';
import jwt_decode from "jwt-decode";
import { CompaniesService } from 'src/app/services/companies/companies.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {

  public iconOnlyToggled = false;
  public sidebarToggled = false;
  companies: any[] = [];
  @Input() selectedCompany: any = null;
  isAdmin: boolean = false;
  userName: string = null;
  urlProfile: string = null;

  constructor(
    config: NgbDropdownConfig,
    private router: Router,
    private customerService: CustomerService,
    private userService: UserService,
    private companiesService: CompaniesService,
    private userInfoService: LocalStorageService) {
    config.placement = 'bottom-right';
  }

  async ngOnInit() {
    const token = this.userInfoService.getToken();
    const decode: any = jwt_decode(token);
    this.userName = decode?.name
    if(decode != null && decode?.type == "SYSTEM_ADMIN") {
      this.isAdmin = true;
    }
    this.userInfoService.observeCompany().subscribe(async response => {
      this.selectedCompany = response;
      if(this.selectedCompany?.picture !== null) {
        await this.getUrlProfile();
      }
    })
    await this.getCompanies();
  }

  async getCompanies() {
    this.companies = await this.userService.getUserCompanies();
  }

  async changeCompany(event: any) {
    this.userInfoService.setCompany(event);
    // this.updateUrl();
  }

  updateUrl() {
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);
    });
  }


  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if ((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if (this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if (this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/login')
  }

  openCompanies() {
    this.router.navigateByUrl('app/companies');
  }

  openProfile() {
    this.router.navigateByUrl(`app/profile`);
  }

  // toggle right sidebar
  // toggleRightSidebar() {
  //   document.querySelector('#right-sidebar').classList.toggle('open');
  // }

  openUsers() {
    this.router.navigateByUrl(`app/users`);
  }

  openAdminCompanies() {
    this.router.navigateByUrl('app/admin-companies')
  }

  async getUrlProfile() {
    const response = await this.companiesService.getPicture(this.selectedCompany?.id);
    if(response) {
      this.userInfoService.setCompanyPicture(response.url);
      this.urlProfile = this.userInfoService.getCompanyPicture();
    }
  }
}
